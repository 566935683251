import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'
import Button, { ButtonIcon } from 'volcano/components/Button'

// Runic
import { Link, NavLink, Routes, Route, useParams, useNavigate } from 'runic-vera/router'

import useRunicListView from 'runic/hooks/useRunicListView'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import Entity from 'runic-vera/components/Entity'
import EntityOrderForm from 'runic-vera/components/EntityOrderForm'
import RfLongFormTextFieldPreview from 'runic-form/components/RfLongFormTextFieldPreview'

import Card from 'hayatinidegistir/components/Card'

import BoxLink from 'runic-vera/themes/v1/BoxLink'


const CourseItemMgmt = props => {
  const navigate = useNavigate()
  const source = useRunicListView('RxTag.RxTag.ListView', { filters: { 'RxTagKind.code_name': 'COURSES' }, opts: { 'sortBy': 'order', sortDirection: 'ASC' } })
  console.log(source)
  if (!source.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )

  return (
    <Flex>
      <Box sx={{
        // mt: -3,
        width: '200px',
        // p: 2,
        // px: 3,
        // mt: 2
      }}>
        {source?.list?.items?.map(id => {
          return (
            <BoxLink end to={`kategori/${id}`} key={id} id={id} icon='tag'>
              <Entity id={id} modelName={'RxTag'} />
            </BoxLink>
          )
        })}

        <Flex sx={{
          justifyContent: 'space-between'
        }}>
          <RfSingleAction onSuccess={source.refresh} upload header='Yeni Kategori' actionKey='RnxCourse.CreateCourseCategory' anchorText='Yeni Kategori' fields={[
            {
              label: 'İsim',
              name: 'name',
              kind: 'text',
              required: true,
            },
          ]} />

          <ButtonIcon iconName='sort-attributes' color='primary' onClick={() => navigate('editOrder')} />
        </Flex>
      </Box>

      <Box flex='1 1 0' ml={4}>
        <Routes>
          <Route path="editOrder" element={<EditOrder source={source} />} />
          <Route path="kategori/:categoryId" element={<CategoryDetail />} />
          <Route path="kategori/:categoryId/detay/:courseId/*" element={<CourseDetail />} />
          <Route path="kategori/:categoryId/detay/:courseId/ders_detay/:courseItemId" element={<CourseItemDetail onSuccess={source.refresh} />} />
        </Routes>
      </Box>
    </Flex>
  )
}

const EditOrder = ({
  source
}) => {
  const navigate = useNavigate()
  return (
    <EntityOrderForm elementName='RnxCourse' actionName='SetCategoryOrder' direction={'horizontal'} onSuccess={() => {
      source.refresh()
      navigate('../')
    }} onCancel={() => {
      navigate('../')
    }} items={source.list.items} renderer={(id, dx) => {
      return (
        <Flex sx={{
          border: '2px solid #FFF',
          borderColor: 'primary',
          px: 3,
          py: 2,
          mb: 2,
          mx: 2,
          transition: 'all 0.2s ease',
          alignItems: 'center',
        }}>
          <Icon name='file' color='primary6' sx={{
            mr: 2
          }} size={24} />
          <Box sx={{
            flex: ' 1 1 0'
          }}>
            <Entity key={id} id={id} modelName={'RxTag'} />
          </Box>
        </Flex>
      )
    }} />
  )
}

const CategoryDetail = props => {
  const { categoryId } = useParams()
  const source = useRunicListView('RnxCourse.RnxCourse.ListView', { filters: { 'categoryId': categoryId }, opts: { 'sortBy': 'order', sortDirection: 'ASC', kind: 'by_category' } })
  if (!source.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )

  return (
    <Box>
      <Flex sx={{
        mb: 3,
        alignItems: 'center'
      }}>
        <Entity id={categoryId} modelName={'RxTag'} sx={{
          variant: 'text.header',
        }} />

        <RfSingleAction initialValues={{
          categoryId
        }} onSuccess={source.refresh} upload header='Yeni Ders Ekle' actionKey='RnxCourse.CreateCourse' anchorText='Yeni Ders' fields={[
          {
            label: 'İsim',
            name: 'name',
            kind: 'text',
            required: true,
          },

          {
            label: 'Görsel',
            name: 'image',
            kind: 'contentImage',
            required: true,
          },
        ]} />
      </Flex>

      <Card sx={{
        p: 3
      }}>

        {source.empty && 'Ders yok.'}

        <Flex sx={{
          flexWrap: 'wrap',
          mx: -2
        }}>
          {source?.list?.items?.map(id => {
            return (
              <Link to={`detay/${id}`} sx={{
                mx: 2,
                border: '1px solid #FFF',
                borderColor: 'primary',
                flex: '0 0 150px'
              }} key={id}>
                <Entity id={id} modelName={'RnxCourse'}>
                  {entity => (
                    <Box>
                      <Box as='img' src={`${__ASSET_URL__}${entity.data.image}`} sx={{
                        width: '100%'
                      }} />
                      <Box p={2} sx={{
                        textAlign: 'center',
                        my: 2,
                        variant: 'text.header',
                      }}>
                        {entity.name}
                      </Box>
                    </Box>
                  )}
                </Entity>
              </Link>
            )
          })}
        </Flex>

      </Card>


    </Box>

  )
}

const CreateCourseItem = ({
  courseId,
  source
}) => {
  const navigate = useNavigate()
  return (
    <>
      <RfSingleAction
        formOnly
        sx={{
          maxWidth: 400
        }} initialValues={{
          courseId
        }} onSuccess={() => {
          source.refresh()
          navigate('../')
        }} upload header='Yeni Ders Öğesi Ekle' actionKey='RnxCourse.CreateCourseItem' anchorText='Yeni Ders Öğesi' fields={[
          {
            label: 'İsim',
            name: 'name',
            kind: 'text',
            required: true,
          },

          {
            label: 'Kısa Tanıtım',
            name: 'description',
            kind: 'textArea',
            required: true,
          },

          {
            label: 'Görsel',
            name: 'image',
            kind: 'contentImage',
            required: true,
          },

          {
            label: 'Video',
            name: 'video',
            kind: 'video',
            required: true,
          },

          {
            label: 'Metin',
            name: 'text',
            kind: 'longFormText',
            required: true,
          },
        ]}>
        <Link to='../'>
          <Button label='Vazgeç' fullWidth sx={{ mt: 2 }} />
        </Link>
      </RfSingleAction>
    </>
  )
}

import * as RV from 'runic-vera/components/RunicView'

const CourseDetail = props => {
  const { courseId } = useParams()
  const result = RV.useRunicGraphQuery({
    rnx_course: {
      filters: {
        id: courseId
      },
      fields: [
        'name',
        'data',
        ['rnx_course_item_list', {fields: ['name', 'data']}]
      ]
    }
  })
  const source = useRunicListView('RnxCourse.RnxCourseItem.ListView', { filters: { 'RnxCourse.id': courseId }, opts: { 'sortBy': 'order', sortDirection: 'ASC' } })
  if (!source.ready || !result.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )
  console.log('-', result)

  return (
    <Box>
      <Flex sx={{
        mb: 3,
        alignItems: 'center'
      }}>
        <Box sx={{
          variant: 'text.header',
          fontSize: 'm'
        }}>
          {result.graph.rnx_course.name}
        </Box>

        <Link to='create'>
          <ButtonIcon iconName='plus' color='primary' text='Yeni Ders Öğesi Ekle' />
        </Link>

        <RfSingleAction initialValues={{
          courseId,
          name: result.graph.rnx_course.name,
          image: {src: result.graph.rnx_course.data.image},
          text: result.graph.rnx_course.data.text
        }} onSuccess={source.refresh} iconName='pencil' upload header='Dersi Düzenle' actionKey='RnxCourse.UpdateCourse' anchorText='Dersi Düzenle' fields={[
          {
            label: 'İsim',
            name: 'name',
            kind: 'text',
            required: true,
          },

          {
            label: 'Görsel',
            name: 'image',
            kind: 'contentImage',
            required: true,
          },

          {
            label: 'Açıklama',
            name: 'text',
            kind: 'longFormText',
            required: true,
          },
        ]} />
      </Flex>
      <Card sx={{
        p: 2,
        mb: 3
      }}>
        <Box sx={{
          fontWeight: 'bold'
        }}>
          Açıklama
        </Box>
        <RfLongFormTextFieldPreview content={result.graph.rnx_course.data.text}/>
      </Card>

      <Card sx={{
        p: 2,
        mb: 3
      }}>
        {source.empty && 'Ders öğesi yok.'}

<Routes>
  <Route path="/" element={<Flex sx={{
    flexWrap: 'wrap',
    mx: -2
  }}>
    {source?.list?.items?.map(id => {
      return (
        <Link to={`ders_detay/${id}`} sx={{
          mx: 2,
          border: '1px solid #FFF',
          borderColor: 'primary',
          flex: '0 0 150px',
          display: 'block'
        }} key={id}>
          <Entity id={id} modelName={'RnxCourseItem'}>
            {entity => (
              <Box>
                <Box as='img' src={`${__ASSET_URL__}${entity.data.image}`} sx={{
                  width: '100%'
                }} />
                <Box p={2} sx={{
                  textAlign: 'center',
                  my: 2,
                  variant: 'text.header',
                }}>
                  {entity.name}
                </Box>
              </Box>
            )}
          </Entity>
        </Link>
      )
    })}
  </Flex>} />
  <Route path="create" element={<CreateCourseItem source={source} courseId={courseId} />} />
</Routes>
      </Card>

    </Box>

  )
}

const CourseItemDetail = ({
  onSuccess
}) => {
  const { courseItemId, courseId } = useParams()
  // const source = useRunicListView('RnxCourse.RnxCourseItem.ListView', { filters: { 'RnxCourse.id': courseId}, opts: { 'sortBy': 'order', sortDirection: 'ASC'}})
  // if (!source.ready) return (
  //   <Box>
  //     Yükleniyor
  //   </Box>
  // )

  return (
    <Box>
      <Flex sx={{
        mb: 3,
        alignItems: 'center'
      }}>
        <Link to='../../'>
          <Entity id={courseId} modelName={'RnxCourse'} sx={{
            variant: 'text.header',
          }} />
        </Link> >
        <Entity id={courseItemId} modelName={'RnxCourseItem'} sx={{
          variant: 'text.header',
        }} />

        <Entity id={courseItemId} modelName={'RnxCourseItem'}>
          {entity => {
            return (
              <RfSingleAction sx={{
                maxWidth: 400
              }} initialValues={{
                courseItemId,
                name: entity.name,
                description: entity.description,
                image: {
                  src: entity.data.image
                },
                video: {
                  src: entity.data.video
                },
                text: entity.data.text
              }} onSuccess={onSuccess} upload header='Düzenle' actionKey='RnxCourse.UpdateCourseItem' anchorText='Düzenle' buttonText='Düzenle' fields={[
                {
                  label: 'İsim',
                  name: 'name',
                  kind: 'text',
                  required: true,
                },

                {
                  label: 'Kısa Tanıtım',
                  name: 'description',
                  kind: 'textArea',
                  required: true,
                },

                {
                  label: 'Görsel',
                  name: 'image',
                  kind: 'contentImage',
                  required: true,
                },

                {
                  label: 'Video',
                  name: 'video',
                  kind: 'video',
                  required: true,
                },

                {
                  label: 'Metin',
                  name: 'text',
                  kind: 'longFormText',
                  required: true,
                },
              ]} />
            )
          }}
        </Entity>

      </Flex>

      <Entity id={courseItemId} modelName={'RnxCourseItem'}>
        {entity => {
          return (
            <Card sx={{
              p: 3,
              py: 0
            }}>

              <Box my={3} sx={{
                variant: 'text.header'
              }}>
                Açıklama
                </Box>

              <Box>
                {entity.description}
              </Box>

              <Flex sx={{
                justifyContent: 'space-between',
                mx: -2
              }}>
                <Box flex='1 1 0' mx={2}>
                  <Box my={3} sx={{
                    variant: 'text.header'
                  }}>
                    Görsel
                </Box>

                  <Box as='img' src={`${__ASSET_URL__}${entity.data.image}`} sx={{
                    width: '100%'
                  }} />
                </Box>

                <Box flex='1 1 0' mx={2}>
                  <Box my={3} sx={{
                    variant: 'text.header'
                  }}>
                    Video
                </Box>

                  <Box as='video' src={`${__ASSET_URL__}${entity.data.video}`} controls sx={{
                    width: '100%'
                  }}>
                    <source src={`${__ASSET_URL__}${entity.data.video}`}
                      type="video/mp4"></source>
                  </Box>
                </Box>
              </Flex>

              <Box my={3} sx={{
                variant: 'text.header'
              }}>
                Metin
                </Box>

              <Box>
                <RfLongFormTextFieldPreview content={entity.data.text} />
              </Box>
            </Card>
          )
        }}
      </Entity>
    </Box>

  )
}

export default CourseItemMgmt