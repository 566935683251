import React from 'react'

// Vendor
import { useNavigate } from "runic-vera/router"

// Runic
import useRunicState from 'runic/hooks/useRunicState'


const RunicTenantSelector = props => {
  const navigate = useNavigate()
  const { user } = useRunicState()

  React.useEffect(() => {
    if (user?.rcTenantId) navigate(`/${user.rcTenantId}/`)
  }, [user])

  return null
}

export default RunicTenantSelector
