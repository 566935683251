// Riva - Function
import { createReducer, setDraft } from 'runic/core/redux'

import rnxForumActions from './actions'

const defaultState = {
  composer: {},
  messageComposer: {}
}

export default createReducer(defaultState, {
  [rnxForumActions.toggleComposer]: (draft, { category, data }) => {
    draft.messageComposer = {
      isOpen: false,
    }
    if (draft.composer.isOpen) {
      draft.composer = {
        isOpen: false,
      }
    } else {
      draft.composer = {
        isOpen: true,
        category: category,
        data: data
      }
    }
  },

  [rnxForumActions.toggleMessageComposer]: (draft, { topic, data }) => {
    draft.composer = {
      isOpen: false,
    }
    if (draft.messageComposer.isOpen) {
      draft.messageComposer = {
        isOpen: false,
      }
    } else {
      draft.messageComposer = {
        isOpen: true,
        topic: topic,
        data: data
      }
    }
  },
})
