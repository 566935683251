import React from 'react'
import ReactDOM from 'react-dom'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'
import Content, { ContentHeader, ContentPrimaryItems } from 'volcano/components/Content'
import useRunicActionCreator from 'runic/hooks/useRunicActionCreator'


const portal = document.createElement('div');
portal.style.zIndex = '999999999'
// portal.classList.add('my-super-cool-portal');

if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const PortalAwareItem = ({
  provided,
  snapshot,
  item,
  renderer,
  index
}) => {
  const usePortal = snapshot.isDragging

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: 8 * 2,
    // margin: `0 ${8}px 0 0`,

    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  })
  console.log(provided, snapshot)

  const child = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(
        snapshot.isDragging,
        provided.draggableProps.style
      )}
    >
      {renderer(item, index)}
      {/* {item.name} */}
    </div>
  )


  if (!usePortal) {
    return child
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal)
}

const EntityOrderForm = ({
  items,
  renderer,
  direction = 'vertical',
  actionName,
  elementName,
  onSuccess,
  onCancel,
  data
}) => {

  const [orderItems, orderItemsSet] = React.useState(items)

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      orderItems,
      result.source.index,
      result.destination.index
    )

    orderItemsSet(newItems)
  }

  const action = useRunicActionCreator(actionName, elementName)
  const dispatch = useDispatch()
  const save = () => {
    dispatch(action({
      items: orderItems,
      ...data
    }, {
      success: () => onSuccess?.()
    }))
  }

  let style
  if (direction === 'horizontal') style = {
    display: 'flex',
                padding: 8,
                overflow: 'auto',
                marginLeft: -8,
                marginRight: -8
  }

  return (
    <Box sx={{

    }}>
      <ContentHeader>
        <Box sx={{
          // mb: 3,
          fontWeight: 'bold'
        }}>
          Sıralamayı Düzenle
            </Box>
        <ContentPrimaryItems>
          <Button label='İPTAL' sx={{
            mr: 2
          }} onClick={onCancel} />
          <Button label='KAYDET' onClick={save} />
        </ContentPrimaryItems>
      </ContentHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction={direction}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver ? 'lightblue' : 'inherit',
                ...style
              }}
              {...provided.droppableProps}
            >
              {orderItems.map((item, index) => (
                <Draggable key={item} draggableId={`${item}`} index={index}>
                  {(provided, snapshot) => (
                    <PortalAwareItem provided={provided} snapshot={snapshot} item={item} renderer={renderer} index={index} />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}

export default EntityOrderForm