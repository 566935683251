import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

import runicActions from 'runic/systems/runic/actions'

const useRunic = props => {
  const appStatus = useSelector(state => state.runic.appStatus)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (appStatus === 'READY') return
    dispatch(runicActions.source('RcApp.State.status'))
  }, [])
  return {
    isReady: appStatus === 'READY'
  }
}

export default useRunic

// const [data, status, refresh, error] = useRunicSource('RcApp.State.status')
//   React.useEffect(() => {
//     dispatch(runicActions.source('RcApp.State.status'))
//   }, [])
//   return null