import React from 'react'


// Runic
import { Route, Routes, Navigate } from 'runic-vera/router'
import AuthenticationBase from 'runic-vera/apps/authentication/pages/AuthenticationBase'
import useRunic from 'runic-vera/hooks/useRunic'

import RunicVeraErrorBoundary from './components/RunicVeraErrorBoundary'
import RunicVeraHome from './components//RunicVeraHome'


const RunicVeraIndex = ({
  uiConfig,
  runicConfig,
  homeUrl = 'home',
  children
}) => {

  const { isReady } = useRunic({ uiConfig, runicConfig })
  console.log('ssss', isReady)

  return (
    <RunicVeraErrorBoundary>
      {/* <RenoaVersionNotification /> */}
      {isReady && (
        <Routes>
          <Route path="auth/*" element={<AuthenticationBase />} />
          <Route path="/" element={<Navigate to={homeUrl} />} />
          <Route path="*" element={<>{children || <RunicVeraHome/>}</>} />
        </Routes>
      )}
    </RunicVeraErrorBoundary>
  )
}

export default RunicVeraIndex
