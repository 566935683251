import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import * as RV from 'runic-vera/components/RunicView'
import RouteTabs from 'runic-vera/components/RouteTabs'
import { useParams } from 'runic-vera/router'
import RfSingleAction from 'runic-form/components/RfSingleAction'

import SimpleTable from 'runic-vera/themes/v1/SimpleTable'


const AuthRoleDetail = props => {
  const params = useParams()
  const tabs = [
    {
      codeName: 'users',
      name: 'Kullanıcılar',
      element: <AuthRoleUsers/>
    },

    {
      codeName: 'permissions',
      name: 'Yetkiler',
      element: <AuthRolePermissions/>
    }
  ]

  return (
    <Box sx={{
      bg: '#FFF',
      borderRadius: 2,
      px: 3,
      py: 2,
      border: '1px solid #FFF',
      borderColor: 'primary2'
    }}>
      <RV.GraphEntity sx={{
        variant: 'text.header',
        color: 'primary',
        mb: 3
      }} id={params.rxAsRoleId} modelName={'RxAsRole'}/>
    <RouteTabs tabs={tabs} key={params.rxAsRoleId} wrapperSx={{
      // mt: 3
    }} forceIndexRoute/>
    </Box>
  )
}

const AuthRolePermissions = props => {
  const params = useParams()
  const result = RV.useRunicGraphQuery({
    rx_as_role_permission_list: {
      'order_by': 'permission_code_name|asc',
      'filters': {
        rx_as_role_id: params.rxAsRoleId
      },
      fields: [
        'permission_code_name',
        'permission_kind',
        'result'
      ]
    }
  })

  return (
    <Box>
      <SimpleTable maxHeight={0} items={result.graph?.rx_as_role_permission_list.items} columns={[
        {
          key: '@dx',
          label:'',
        },
        {
          key: 'permission_code_name',
          label: 'İsim'
        },
      ]}/>
    </Box>
  )
}

const AuthRoleUsers = props => {
  const params = useParams()
  const result = RV.useRunicGraphQuery('RxAuthSimple.RxAsActorRoleAssignment.user_list', { variables: { roleId: params.rxAsRoleId}})

  return (
    <Box>
      <RfSingleAction onSuccess={result.refresh} header='Yeni Rol Ataması' actionKey='RxAuthSimple.AssignRoleToActor' anchorText='Yeni Rol Ataması' fields={[
        {
          label: 'Kullanıcı',
          name: 'rcUserId',
          kind: 'graphEntity',
          modelName: 'RcUser',
          required: true,
        },

        {
          label: 'Rol',
          name: 'rxAsRoleId',
          kind: 'graphEntity',
          modelName: 'RxAsRole',
          required: true,
        },
      ]} />
      <SimpleTable maxHeight={0} alignLast items={result.graph?.rx_as_actor_role_assignment_list.items} columns={[
        {
          key: '@dx',
          label:'',
        },
        {
          key: 'rc_user.name',
          label: 'İsim'
        },
        {
          key: '@actions',
          label: 'İşlemler'
        }
      ]} actions={[
        (item, dx) => <RfSingleAction key={dx} initialValues={{rxAsActorRoleAssignmentId: item.id}} onSuccess={result.refresh} header='Kaldır' actionKey='RxAuthSimple.RemoveRoleFromActor' iconName='circle-remove' buttonText='Kaldır' />
      ]} />
    </Box>
  )
}

export default AuthRoleDetail