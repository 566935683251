import React from 'react'

// Vendor
import {BrowserRouter as Router} from 'react-router-dom'

// Volcano
import Volcano from 'volcano'

// Runic
import RunicVeraBase from './RunicVeraBase'

// import uiTreeApp from 'runic-aura/apps/uitree'
// import modelApp from 'runic-aura/apps/model'
// import standardDomainApp from 'runic-aura/apps/domain/standard-domain'

// import RunicAura from 'runic-aura/systems/runic-aura'

import RunicVeraIndex from 'runic-vera/RunicVeraIndex'

const extraApps = [
  // modelApp,
  // standardDomainApp,
  // uiTreeApp,
  // tenantApp,
  // modelDomainApp
]

const extraSystems = [
  // RunicAura,
]

import { RunicFormContext } from 'runic-form/components/RfForm'

const RunicVeraApp = ({
  theme,
  globalCss,
  uiConfig,
  apps = [],
  systems = [],
  storeCreator,
  config,
  rncuiConfig,
  homeComponent,
  children
}) => {
  const ctx = React.useMemo(() => ({
    lookupBaseUrl: '/_api/runic/source/',
    remoteLookupBaseUrl: '/_api/runic/remote/',
    mediaUploadUrl: '/_api/runic/upload/RxFile.UploadMedia',
    mediaGalleryUrl: '/_api/runic/source/RxFile.Media.image_list',
    entityMap: {},
    headers: { 'X-RUNIC-PLATFORM': 'RUNIC_MAIN'},
    theme: theme
  }), [])

  return (
    <RunicVeraBase apps={[...extraApps, ...apps]} systems={[...extraSystems, ...systems]} storeCreator={storeCreator} config={config}>
      <Router>
        <Volcano theme={theme} globalCss={globalCss}>
          <RunicFormContext.Provider value={ctx}>
          {children || <RunicVeraIndex uiConfig={uiConfig || rncuiConfig} runicConfig={config} homeComponent={homeComponent} />}
          </RunicFormContext.Provider>
        </Volcano>
      </Router>
    </RunicVeraBase>
  )
}

export default RunicVeraApp