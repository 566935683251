import React from 'react'

import { Link as RRLink, NavLink as RRNavLink, useLocation, useResolvedPath } from "react-router-dom"
export { Link as RRLink, NavLink as RRNavLink, Routes, Route, Outlet, Navigate, useNavigate, useParams, useMatch, useResolvedPath, useInRouterContext, useLocation, useHref, useSearchParams  } from "react-router-dom"

// Volcano
import { Box, Flex } from 'volcano'

export const Link = ({
  ...props
}) => <Box as={RRLink} {...props}/>

export const NavLink = ({
  end,
  ...props
}) => {
  let location = useLocation()
  let path = useResolvedPath(props.to)

  let locationPathname = location.pathname
  let toPathname = path.pathname
  if (!props.caseSensitive) {
    locationPathname = locationPathname.toLowerCase()
    toPathname = toPathname.toLowerCase()
  }

  let isActive = end
    ? locationPathname === toPathname
    : locationPathname.startsWith(toPathname)

  return (
    <Link variant={isActive && 'active'} {...props}/>
  )
}

export const useActiveLink = ({
  end,
  to,
  caseSensitive
}) => {
  let location = useLocation()
  let path = useResolvedPath(to)

  let locationPathname = location.pathname
  let toPathname = path.pathname
  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase()
    toPathname = toPathname.toLowerCase()
  }

  let isActive = end
    ? locationPathname === toPathname
    : locationPathname.startsWith(toPathname)

  return isActive
}