// Vendor
import { call, take, put, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'

// Riva
import { runicActionRequest, callCallbacks } from 'runic/core/sagaHelpers'

// Self
import authenticationActions from './actions'
// import coreActions from 'riva/systems/core/actions'

export function* login() {
  while (true) {
    const action = yield take((action) => action && action.type == authenticationActions.RCR_LOGIN)
    console.log('uu, action', action)
    const { payload } = action

    try {
      const apiResponse = yield call(runicActionRequest, 'RcApp.Login', payload)
      callCallbacks(action, 'success', apiResponse.data)

      const resultPayload = {...apiResponse.data}
      const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)
      const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
      delete resultPayload._rc_entity
      resultPayload['rcEntity'] = entityData

      yield put({
        type: authenticationActions.RCR_LOGIN,
        payload: resultPayload,
        meta: {
          status: 'SUCCESS'
        }
      })

      // if (!director.rConfig.multiTenant) {
      //   yield put(coreActions.rcrRivaSetup({rcTenantId: 1}))
      //   yield put(coreActions.rcrActivateRcTenant({id: 1}))
      // } else {
      //   yield put(coreActions.rcrRivaBootstrap({}))
      // }
    } catch (error) {
      callCallbacks(action, 'error', error.response ? error.response.data : error)
      yield put({
        type: authenticationActions.RCR_LOGIN,
        payload: error.response ? error.response.data : error,
        meta: {
          status: 'ERROR'
        }
      })
    }
  }
}

export function* logout() {
  while (true) {
    const action = yield take((action) => action && action.type == authenticationActions.RCR_LOGOUT)
    const { payload } = action
    try {
      const apiResponse = yield call(runicActionRequest, 'RcApp.Logout', payload)
      if (process.browser) {
        // localStorage.removeItem('token')
        window.location = '/'
      }
      callCallbacks(action, 'success', apiResponse.data)
      yield put({
        type: authenticationActions.RCR_LOGOUT,
        payload: apiResponse.data,
        meta: {
          status: 'SUCCESS'
        }
      })
    } catch (error) {
      yield put({
        type: authenticationActions.RCR_LOGOUT,
        payload: error.response,
        meta: {
          status: 'ERROR'
        }
      })
    }
  }
}

export default [login, logout]