import axios from 'axios'

import { select } from 'redux-saga/effects'


export function* runicSourceRequest(sourceName, data = {}, sourceUrlPath='source', action) {
  const dataToSend = {...data}

  const baseAPIUrl = yield select((state) => state.runic.config.baseAPIUrl || '')

  if (!dataToSend.rcTenantId) {
    dataToSend.rcTenantId = yield select((state) => (state.runic.config.isMultiTenant ? state.runic.activeRcTenantId : 1))
  } else if (dataToSend.rcTenantId == '__DISABLE') {
    dataToSend.rcTenantId = null
  }

  let baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.runic.config.runicPlatform)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  const url = action?.meta?.remoteName ? (
    `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/${sourceUrlPath}/${sourceName}`
  ) : (
    `${baseAPIUrl}/_api/runic/${sourceUrlPath}/${sourceName}`
  )

  if (!process.browser) {
    const req = yield select((state) => state.runic.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.get(`${url}`, {params: dataToSend, headers: {...baseHeaders, ...headers} })
  } else {
    return yield axios.get(`${url}`, {params: dataToSend, headers: baseHeaders})
  }

}

export function* runicSourceRequestv2(sourceName, data = {}) {
  const dataToSend = {...data}

  const baseAPIUrl = yield select((state) => state.runic?.config?.baseAPIUrl || '')

  let baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.runic.config.runicPlatform)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  const url = action?.meta?.remoteName ? (
    `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/${sourceUrlPath}/${sourceName}`
  ) : (
    `${baseAPIUrl}/_api/runic/${sourceUrlPath}/${sourceName}`
  )

  if (!process.browser) {
    const req = yield select((state) => state.runic.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.get(`${url}`, {params: dataToSend, headers: {...baseHeaders, ...headers} })
  } else {
    return yield axios.get(`${url}`, {params: dataToSend, headers: baseHeaders})
  }

}

export function* runicGraphQueryRequest(payload, action) {
  const dataToSend = {...payload}

  if (!dataToSend.rcTenantId) {
    dataToSend.rcTenantId = yield select((state) => (state.runic.config.isMultiTenant ? state.runic.activeRcTenantId : 1))
  }

  const baseAPIUrl = yield select((state) => state.runic?.config?.baseAPIUrl || '')

  let baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.runic.config.runicPlatform)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  const url = action?.meta?.remoteName ? (
    `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/graph_query`
  ) : (
    `${baseAPIUrl}/_api/runic/graph_query`
  )

  if (!process.browser) {
    const req = yield select((state) => state.runic.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.get(`${url}`, {params: dataToSend, headers: {...baseHeaders, ...headers} })
  } else {
    return yield axios.get(`${url}`, {params: dataToSend, headers: baseHeaders})
  }

}

export function* runicMultiSourceRequest(sourceName, queries) {
  const baseAPIUrl = yield select((state) => state.runic?.config?.baseAPIUrl || '')

  let baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.runic.config?.runicPlatform)
  }
  // if (action?.meta?.headers) {
  //   baseHeaders = {...baseHeaders, ...action.meta.headers}
  // }

  const url = `${baseAPIUrl}/_api/runic/multi_source/${sourceName}`
  // const url = action?.meta?.remoteName ? (
  //   `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/source/${sourceName}`
  // ) : (
  //   `${baseAPIUrl}/_api/runic/source/${sourceName}`
  // )

  if (!process.browser) {
    const req = yield select((state) => state.runic.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.get(`${url}`, {params: {args: queries}, headers: {...baseHeaders, ...headers} })
  } else {
    return yield axios.get(`${url}`, {params: {args: queries}, headers: baseHeaders})
  }

}

export function* runicActionRequest(actionName, data = {}, kind = 'action', action) {
  const dataToSend = {...data}
  const urlParams = {}

  const baseAPIUrl = yield select((state) => state.runic.config.baseAPIUrl || '')
  let baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.runic.config.runicPlatform)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  if (dataToSend._rcTenantId) {
    if (dataToSend._rcTenantId !== '__DISABLE') urlParams.rcTenantId = `${dataToSend._rcTenantId}`
    else delete dataToSend._rcTenantId
  } else if (dataToSend.rcTenantCodeName) {
    urlParams.rcTenantCodeName = `${data.rcTenantCodeName}`
    delete dataToSend.rcTenantCodeName
  } else {
    urlParams.rcTenantId = yield select((state) => (state.runic.config.isMultiTenant ? state.runic.activeRcTenantId : 1))
  }

  const url = action?.meta?.remoteName ? (
    `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/${kind}/${actionName}`
  ) : (
    `${baseAPIUrl}/_api/runic/${kind}/${actionName}`
  )

  if (!process.browser) {
    const req = yield select((state) => state.runic.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.post(url, dataToSend, {headers: {...baseHeaders, ...headers}, params: urlParams})
  } else {
    return yield axios.post(url, dataToSend, {params: urlParams, headers: baseHeaders})
  }
}

export function* runicUploadRequest(actionName, data, callbacks, action) {
  const dataToSend = {...data}
  const urlParams = {}

  const baseAPIUrl = yield select((state) => state.runic.config.baseAPIUrl || '')
  let baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.runic.config.runicPlatform)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  if (dataToSend._rcTenantId) {
    if (dataToSend._rcTenantId !== '__DISABLE') urlParams.rcTenantId = `${dataToSend._rcTenantId}`
    else delete dataToSend._rcTenantId
  } else if (dataToSend.rcTenantCodeName) {
    urlParams.rcTenantCodeName = `${data.rcTenantCodeName}`
    delete dataToSend.rcTenantCodeName
  } else {
    urlParams.rcTenantId = yield select(state => state.runic.config.isMultiTenant ? state.runic.activeRcTenantId : 1)
  }

  const url = action?.meta?.remoteName ? (
    `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/upload/${actionName}`
  ) : (
    `${baseAPIUrl}/_api/runic/upload/${actionName}`
  )


  if (!process.browser) {
    const req = yield select((state) => state.runic.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.post(url, data, {headers: { ...headers, ...baseHeaders, params: urlParams}})
  } else {
    return yield axios.post(url, data, {...callbacks, headers: baseHeaders, params: urlParams})
  }
}

export const callCallbacks = (action, kind, payload) => {
  const _kind = kind.toLowerCase()
  if (action.callbacks && action.callbacks[_kind]) {
    return action.callbacks[_kind](payload)
  }
}
