import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'

// Runic
import { Link, NavLink, Routes, Route, useParams } from 'runic-vera/router'

import useRunicListView from 'runic/hooks/useRunicListView'
import useRunicSource from 'runic/hooks/useRunicSource'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import Entity from 'runic-vera/components/Entity'
import Card from 'hayatinidegistir/components/Card'

import RfLongFormTextFieldPreview from 'runic-form/components/RfLongFormTextFieldPreview'
import RfActionButton from 'runic-form/components/RfActionButton'

import * as RV from 'runic-vera/components/RunicView'


const CourseItemDetail = props => {
  const { courseItemId } = useParams()
  const result = RV.useRunicGraphQuery({
    'rnx_course_item': {
      filters: {
        id: courseItemId
      },
      fields: [
        'name',
        'description',
        'data',
        'user_bookmarked',
        ['rnx_course', {'fields': ['name']}]
      ]
    }
  })

  if (!result.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )

  const course = result.graph.rnx_course_item

  return (
    <Box>
      <Flex sx={{
        mb: 3,
        alignItems: 'center',
        borderBottom: '1px solid #FFF',
        borderBottomColor: 'primary8',
        pb: 2,
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          variant: 'text.header',
          variant: 'text.header',
          fontSize: 'm',
          color: 'primary'
        }}>
          {course.rnx_course.name} {'|'} {course.name}
        </Box>

        <RfActionButton color='primary' iconName={course.user_bookmarked ? 'heart' : 'heart-empty'} actionKey='RxUserActivity.BookmarkEntity' values={{
                  rcEntityId: course.rc_entity_id,
                  domain: 'COURSE'
                }} onSuccess={result.refresh}/>

      </Flex>

      <Card sx={{
        p: 2,
        mb: 3
      }}>
        <Box sx={{
          fontWeight: 'bold'
        }}>
          Açıklama
        </Box>
        {course.description}
      </Card>

      <Card>
        <Box as='video' src={`${__ASSET_URL__}${course.data.video}`} controls sx={{
          width: '100%'
        }}>
          <source src={`${__ASSET_URL__}${course.data.video}`}
            type="video/mp4"></source>
        </Box>
      </Card>

      <Card sx={{
        mt: 3,
        p: 3
      }}>
        <RfLongFormTextFieldPreview content={course.data.text} />
      </Card>
    </Box>

  )
}

export default CourseItemDetail