import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'

// Runic
import { Link, NavLink, Routes, Route, useParams } from 'runic-vera/router'

import useRunicListView from 'runic/hooks/useRunicListView'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import Entity from 'runic-vera/components/Entity'
import Card from 'hayatinidegistir/components/Card'


const CourseDetail = props => {
  const { courseId } = useParams()
  const source = useRunicListView('RnxCourse.RnxCourseItem.ListView', { filters: { 'RnxCourse.id': courseId }, opts: { 'sortBy': 'order', sortDirection: 'ASC' } })
  if (!source.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )

  return (
    <Box>
      <Flex sx={{
        mb: 3,
        alignItems: 'center',
        borderBottom: '1px solid #FFF',
        borderBottomColor: 'primary8',
        pb: 2
      }}>
        <Entity id={courseId} modelName={'RnxCourse'} sx={{
          variant: 'text.header',
          fontSize: 'm',
          color: 'primary'
        }} />
      </Flex>

      {source.empty && 'Ders öğesi yok.'}

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -2
      }}>
        {source?.list?.items?.map((id, dx) => {
          return (
            <Link to={`ders_detay/${id}`} sx={{
              mx: 2,
              flex: ['1 1 100%', null, null, '0 0 250px'],
              mb: 3,
              display: 'block'
            }} key={id}>
                <Entity id={id} modelName={'RnxCourseItem'}>
                  {entity => (
                    <>
                    <Card sx={{
                      p: 2,
                      border: '1px solid #FFF',
                      borderColor: 'primary',
                    }}>
                    <Box>
                      <Box as='img' src={`${__ASSET_URL__}${entity.data.image}`} sx={{
                        width: '100%'
                      }} />
                      <Box p={2} sx={{
                        textAlign: 'center',
                        my: 2,
                        variant: 'text.header',
                        fontSize: 'm'
                      }}>
                        {dx+1} - {entity.name}
                      </Box>
                    </Box>
                    </Card>
                    {entity.description && <Box sx={{
                      my: 2,
                    }}>
                      {entity.description}
                    </Box>}
                    </>
                  )}
                </Entity>
            </Link>
          )
        })}
      </Flex>


    </Box>

  )
}

export default CourseDetail