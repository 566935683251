import React from 'react'

// Vendor
import { useNavigate } from "runic-aura/router"

// Runic
import useRunicState from 'runic/hooks/useRunicState'


const RunicAuthCheck = ({
  children
}) => {
  const { user } = useRunicState()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!user) {
      navigate(`/auth/login`)
    }
  }, [])

  return user ? children : null

}

export default RunicAuthCheck
