import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'
import Button, { ButtonIcon } from 'volcano/components/Button'

// Runic
import { Link, NavLink, Routes, Route } from 'runic-vera/router'

import useRunicSource from 'runic/hooks/useRunicSource'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import Entity from 'runic-vera/components/Entity'

import EntityOrderForm from 'runic-vera/components/EntityOrderForm'

const ForumCategoryMgmt = props => {
  return (
    <Routes>
      <Route path="detay/:tagId" element={<>u</>} />
      <Route path="" element={<ForumCategoryList />} />
    </Routes>
  )
}

const ForumCategoryList = props => {
  const source = useRunicSource('RnxForum.Forum.categories')
  const [editOrder, setEditOrder] = React.useState(false)

  if (!source.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )

  return (
    <Box>
      <Flex sx={{
        alignItems: 'cente'
      }}>
      <RfSingleAction onSuccess={() => source.refresh()} upload header='Yeni Kategori' actionKey='RnxForum.CreateCategory' anchorText='Yeni Kategori' fields={[
        {
          label: 'İsim',
          name: 'name',
          kind: 'text',
          required: true,
        },
      ]} />
      <ButtonIcon iconName='sort-attributes' color='primary' onClick={() => setEditOrder(v => !v)}/>
      </Flex>

      <Flex sx={{
        mx: -2,
        flexWrap: 'wrap',
        mt: 2
      }}>
      {source.data.map(category => {
        return (
          <Flex sx={{
            mx: 2,
            border: '1px solid #FFF',
            borderColor: 'primary',
            p: 2,
            alignItems: 'center'
          }} key={category.value}>

            <Box>
              {category.label}
            </Box>

            <RfSingleAction initialValues={{id: category.value, name: category.label}} onSuccess={() => source.refresh()} upload header='Düzenle' actionKey='RnxForum.UpdateCategory' iconName='pencil' fields={[
              {
                label: 'İsim',
                name: 'name',
                kind: 'text',
                required: true,
              },
            ]} />

            <RfSingleAction initialValues={{id: category.value}} onSuccess={() => source.refresh()} upload header='Sil' actionKey='RnxForum.DeleteCategory' iconName='circle-remove' fields={[
              {
                label: 'Taşınacak Kategori',
                name: 'targetRnxCategoryId',
                kind: 'relationship',
                source: 'RnxForum.Forum.categories_lookup',
                required: true,
              },
            ]} />
          </Flex>
        )
      })}
      </Flex>

      {editOrder && source.data && <EntityOrderForm elementName='RnxForum' actionName='SetCategoryOrder' direction={'horizontal'} onSuccess={() => {
            source.refresh()
            setEditOrder(false)
          }} onCancel={() => {
            setEditOrder(false)
          }} items={source.data.map(x => x.value)} renderer={(id, dx) => {
              const category = source.data.find(x => x.value == id)
              return (
                <Flex sx={{
                  border: '2px solid #FFF',
                  borderColor: 'primary',
                  px: 3,
                  py: 2,
                  mb: 2,
                  mx: 2,
                  transition: 'all 0.2s ease',
                  alignItems: 'center',
                }}>
                  <Icon name='file' color='primary6' sx={{
                    mr: 2
                  }} size={24} />
                  <Box sx={{
                    flex: ' 1 1 0'
                  }}>
                    {category.label}
                  </Box>
                </Flex>
              )
            }}/>}
    </Box>
  )
}

export default ForumCategoryMgmt