import React from 'react'

// Vendor
import { useField, useFormState } from 'react-final-form'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'
import Button from 'volcano/components/Button'
import useFileDialog from 'volcano/hooks/useFileDialog'
import { Menu, MenuItem } from 'volcano/components/Menu'

// Rf
import { RunicFormContext } from './RfForm'
import RfTextField from './RfTextField'


const RfVideoField = ({
  name,
  acceptedFileKinds = 'video/*',
  upload
}) => {
  const { input, meta } = useField(name)
  const formState = useFormState()

  const inputFieldContent = upload ? (
    <VideoInputUpload name={`${name}.video`} srcName={`${name}.src`} acceptedFileKinds={acceptedFileKinds} />
  ) : (
      <VideoInput name={`${name}.video`} srcName={`${name}.src`} acceptedFileKinds={acceptedFileKinds} />
    )

  let src

  if (input.value) src = input.value?.src?.[0] === '/' ? `${__ASSET_URL__}${input.value.src}` : input.value.src

  return (
    <Box>
      {input.value && (
        <>
          <Box sx={{
            position: 'relative'
          }}>
            <Box as='a' sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              p: 1,
              textAlign: 'center',
              bg: 'primary'
            }} onClick={() => input.onChange(null)}>
              <Icon name='square-remove' color='#FFF' size={24} />
            </Box>
            <Box as='video' src={src} sx={{
              width: '100%'
            }} />
          </Box>
          <RfTextField name={`${name}.title`} field={{ name: `${name}.title`, label: 'Başlık' }} />
        </>
      )}
      {inputFieldContent}
    </Box>
  )
}

const VideoInput = ({
  name,
  srcName,
  acceptedFileKinds
}) => {
  const { input, meta } = useField(name)
  const { input: srcInput } = useField(srcName)

  const fileRef = React.useRef()
  fileRef.current = input.value

  const menuRef = React.useRef()

  const ctx = React.useContext(RunicFormContext)

  const openFileDialog = useFileDialog({
    acceptedFileKinds,
    onAdd: (files) => {
      const file = files[0]
      if (fileRef.current) window.URL.revokeObjectURL(fileRef.current.preview)
      const preview = window.URL.createObjectURL(files[0])
      file.preview = preview
      srcInput.onChange(preview)
      input.onChange(file)
    }
  })

  return (
    <Flex mx={-2}>
      <Button onClick={openFileDialog} fullWidth sx={{ mx: 2 }}>
        <Icon name='cloud-upload' />
      </Button>
      {/* {ctx.mediaGalleryUrl && <Menu hideIfNotOpen anchor={<Button fullWidth sx={{ mx: 2 }}>
      <Icon name='set-down' />
    </Button>} placement='bottom-start' ref={menuRef}
    // zIndex={zIndex}
    >
      <ImageGallery ctx={ctx} />
      </Menu>} */}
    </Flex>
  )
}

import useData from 'runic/hooks/useData'

const ImageGallery = ({
  ctx
}) => {
  const { mediaGalleryUrl } = ctx
  const [data, status, refresh] = useData({
    url: mediaGalleryUrl
  })

  console.log('gallery', data)
  return (
      <Box sx={{
        width: '30vw',
        bg: '#FFF'
      }}>
        ue
      </Box>
  )
}

const VideoInputUpload = ({
  name,
  srcName,
  acceptedFileKinds
}) => {
  const { input, meta } = useField(name)
  const { input: srcInput } = useField(srcName)

  const ctx = React.useContext(RunicFormContext)

  const url = ctx.mediaUploadUrl

  const upload = async (file) => {
    const data = new FormData()
    data.append('file', file)
    data.append('data', JSON.stringify({ ...file }))

    const result = await axios.post(url, data, {
      callbacks: {
        onUploadProgress: (e) => {
          const percentCompleted = Math.round((e.loaded * 100) / e.total);
          // action.callbacks.progress({
          //   id: file.id,
          //   progress: percentCompleted,
          //   modelName
          // })
          console.log('percentCompleted', file, percentCompleted)
        }
      }, headers: { 'Content-Type': 'multipart/form-data' }
    }).catch(err => ({ err }))
    if (result.err) {
      console.log('error', result.err)
    } else {
      console.log('success')
      console.log(result.data.path)
      input.onChange(result.data.path)
      srcInput.onChange(result.data.path)
    }
  }

  const openFileDialog = useFileDialog({
    acceptedFileKinds,
    onAdd: (files) => upload(files[0])
  })

  return (
    <Box as='button' onClick={openFileDialog} sx={{
      backgroundColor: 'primary',
      px: 3,
      py: 2,
      border: 'none',
      color: '#FFF',
      cursor: 'pointer',
      width: '100%'
    }}>
      <Icon name='cloud-upload' />
    </Box>
  )
}

export default RfVideoField