import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import { Global, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'

// Volcano
import Volcano from 'volcano'
import { Box, Flex } from 'volcano'

// Runic
import useRunicState from 'runic/hooks/useRunicState'

// Nexus
// import usePermission from 'nexus/hooks/usePermission'
// import useNexusAppState from 'nexus/hooks/useNexusAppState'
import theme from 'runic-vera/theme'

import RunicDevBarContent from './RunicDevBarContent'
// import EditorFrame from './EditorFrame'
// import EditorAreaFrame from './EditorAreaFrame'
// import BrMetaEditor from './BrMetaEditor'
import { RunicFormContext } from 'runic-form/components/RfForm'


const RunicDevBar = ({

}) => {
  const { user } = useRunicState()
  return null
  if (!user?.state === 'PRESIDENT') return null
  const ctx = React.useMemo(() => ({
    lookupBaseUrl: '/_api/runic/source/',
    remoteLookupBaseUrl: '/_api/runic/remote/',
    mediaUploadUrl: '/_api/runic/upload/NexusContent.UploadMedia',
    mediaGalleryUrl: '/_api/runic/source/NexusContent.Media.image_list',
    entityMap: {},
    theme: theme
  }), [])
  // const perm = usePermission({ CAN_USE_CMS: null })
  //   if (!perm.CAN_USE_CMS) return null
  const globalCss = css`
    ${emotionNormalize}

    @font-face {
      font-family: 'EditorFontMain';
      src: url('/_asset/static/font/IBMPlexSans-Light.otf');
    }

    @font-face {
      font-family: 'EditorFontMain';
      src: url('/_asset/static/font/TitilliumWeb-Regular.ttf');
      font-weight: bold;
    }

    .__nxTippy {
      font-family: 'EditorFontMain';
    }

    div {
      font-family: 'EditorFontMain';
    }
  `
  return (
    <Volcano theme={() => theme}>
      <Global styles={globalCss} />
      <Box sx={{
        height: 50,
        'a': {
          color: 'primary8',
          fontFamily: 'EditorFontMain'
        }
        // display: ['none', null, null, 'block']
      }}>
        <RunicFormContext.Provider value={ctx}>
            <BarFrame />
            {/* <EditorFrame />
            <EditorAreaFrame /> */}
          {/* <BrMetaEditor /> */}
        </RunicFormContext.Provider>

      </Box>
    </Volcano>
  )
}

const BarFrame = ({

}) => {

  let sx = {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100vw',
    height: 50,
    color: '#FFF',
    border: 'none',
    p: 0,
    m: 0,
    zIndex: 999
  }

  return (
    // <Box as={Frame} sx={sx}>
    <Box sx={sx}>
      {/* <FrameProvider> */}
      {/* <Volcano theme={() => theme}> */}
      {/* <Global styles={globalCss} /> */}
      <Box sx={{
        // width: '100vw',
        // height: '100vh',

        width: '100vw',
        height: 50,
        backgroundColor: 'dark',
        color: '#FFF',
        paddingLeft: '17px'
      }}>
        <RunicDevBarContent />
      </Box>
      {/* </Volcano> */}
      {/* </FrameProvider> */}
    </Box>
  )
}

export default RunicDevBar