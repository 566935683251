import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'
import Button from 'volcano/components/Button'
import Card from 'volcano/components/Card'

// Runic
import { TextField, CheckboxField } from 'runic-aura/components/RunicField'
import Form, { FormError, useActionForm, yup } from 'runic-form/components/RfForm'
import { useNavigate, useParams, Link } from 'runic-aura/router'


const AuthenticationLogin = ({
  rcTenantId
}) => {
  const params = useParams()
  const rcTenantCode = params?.rcTenantCode
  const isAuthenticated = useSelector(state => state.runic.status.rcUser)
  const navigate = useNavigate()

  const initialValues = React.useMemo(() => {
    const data = {}
    if (rcTenantId) data.rcTenantId = rcTenantId
    else if (rcTenantCode) data.rcTenantCode = rcTenantCode
    else data.rcTenantId = 1
    return data
  }, [rcTenantCode, rcTenantId])

  const [formProps, isSubmitting] = useActionForm(
    'RcApp.Login',
    {
      initialValues,
      onSuccess: () => window.location.reload()
    }
  )

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(`/home`)
    }
  }, [isAuthenticated])

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={[1, 1 / 2, 1 / 2, 1 / 2, 1 / 4]} mt='200px' pr='30px' pt='25px'>
        <Card>
          <Form {...formProps}>
            {/* {director.rConfig.logoPath && <Box sx={{ textAlign: 'center', mb: 3, mt: 3 }}><Box as='img' src={director.rConfig.logoPath} sx={{ width: '80%', mx: 'auto' }} /></Box>} */}
            <TextField name="email" label='E-Posta' tabIndex={1} schema={yup.string().min(4).required()} required focus />
            <TextField name="password" label='Şifre' type='password' tabIndex={2} required />
            <CheckboxField name='rememberMe' label='Beni hatırla' tabIndex={3} />
            <FormError errorMap={{

            }} error={formProps.error}/>
            <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} />
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../forgot' tabIndex={5}>
              Şifremi Unuttum
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationLogin
