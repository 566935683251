import createKey from 'runic/util/key'

const source = (name, payload, fetchKey, callbacks) => ({
  type: `@@runic/source/${name}`,
  meta: {
    status: 'START',
    name,
    kind: '@@runic/source',
    dt: new Date(),
    fetchKey: fetchKey || createKey({s: name, p: payload})
  },
  payload,
  callbacks,
})

source.toString = () => '@@runic/source'

const modelSource = (name, payload, fetchKey, callbacks) => ({
  type: `@@runic/modelSource/${name}`,
  meta: {
    status: 'START',
    name,
    kind: '@@runic/modelSource',
    dt: new Date(),
    fetchKey: fetchKey || createKey({s: name, p: payload})
  },
  payload,
  callbacks,
})

modelSource.toString = () => '@@runic/modelSource'

const graphQuery = (payload, fetchKey, callbacks) => ({
  type: `@@runic/graphQuery/${fetchKey}`,
  meta: {
    status: 'START',
    kind: '@@runic/graphQuery',
    dt: new Date(),
    fetchKey: fetchKey
  },
  payload,
  callbacks,
})

source.toString = () => '@@runic/graphQuery'

export default {
  source,
  modelSource,
  graphQuery
}