import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import RouteTabs from 'runic-vera/components/RouteTabs'

import CourseCategoryMgmt from './CourseCategoryMgmt'
import CourseItemMgmt from './CourseItemMgmt'

const CourseMgmt = props => {
  // const tabs = [
  //   {
  //     codeName: 'course',
  //     name: 'Kurs',
  //     element: <CourseItemMgmt/>
  //   },

  //   {
  //     codeName: 'tags',
  //     name: 'Kategoriler',
  //     element: <CourseCategoryMgmt/>
  //   }
  // ]
  return (
    <Box>
      <Box sx={{
        variant: 'text.pageHeader'
      }}>
        Kurs Yönetimi
      </Box>

      <CourseItemMgmt/>
    </Box>
  )
}

export default CourseMgmt