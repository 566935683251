import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

const useRunicReduxAction = (identifier, kind = 'RCR_ACTION') => {
  const dispatch = useDispatch()
  const [elementName, actionName] = identifier.split('.')
  const runicAction = React.useCallback((payload, callbacks) => ({
    type: actionName,
    meta: {
      status: 'START',
      kind: kind,
      component: elementName,
      actionKind: null
    },
    payload,
    callbacks,
  }), [actionName, elementName])

  runicAction.actionType = actionName

  return React.useCallback((payload, callbacks) => {
    dispatch(runicAction(payload, callbacks))
  }, [runicAction])
}

export default useRunicReduxAction