import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

import useRunicSource from 'runic/hooks/useRunicSource'


const useRunicState = props => {
  const source = useRunicSource('RcApp.State.status', { maxAge: null })
  return {
    user: source?.data?.rcUser
  }
}

export default useRunicState