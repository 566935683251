import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import RouteTabs from 'runic-vera/components/RouteTabs'

import ForumCategoryMgmt from './ForumCategoryMgmt'
// import CourseItemMgmt from './CourseItemMgmt'

const ForumMgmt = props => {
  const tabs = [
    // {
    //   codeName: 'course',
    //   name: 'Kurs',
    //   element: <CourseItemMgmt/>
    // },

    {
      codeName: 'tags',
      name: 'Kategoriler',
      element: <ForumCategoryMgmt/>
    }
  ]
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        color: 'primary',
        fontSize: 'l',
        fontFamily: 'Reverb Header'
      }}>
        Forum Yönetimi
      </Box>

      <RouteTabs tabs={tabs} wrapperSx={{
        mt: 3
      }} forceIndexRoute/>
    </Box>
  )
}

export default ForumMgmt