import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import RfSingleAction from 'runic-form/components/RfSingleAction'
import useRunicSource from 'runic/hooks/useRunicSource'


import Card from 'hayatinidegistir/components/Card'


const GalleryMgmt = props => {
  const source = useRunicSource('RnxJournal.Gallery.images', { params: { codename: 'MOTIVATIONAL' } })
  return (
    <Box sx={{

    }}>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2
      }}>
        <Box sx={{
          variant: 'text.pageHeaderText'
        }}>
          Galeri
        </Box>

        <Box>
          <RfSingleAction
            onSuccess={() => source.refresh()}
            upload header='Yeni Görsel Ekle' actionKey='RnxJournal.CreateGalleryImage' anchorText='Yeni Görsel' fields={[
              {
                label: 'İsim',
                name: 'name',
                kind: 'text',
                required: true,
              },

              {
                label: 'Göesel',
                kind: 'contentImage',
                name: 'image',
              }
            ]} />
        </Box>
      </Flex>

      {!source.ready && 'Yükleniyor'}

      {!source.data?.images && 'Yok'}

      <Flex sx={{
        mx: -2,
        flexWrap: 'wrap'
      }}>
        {source?.data?.images?.map(image => {
          return (
            <Box key={image.id} sx={{
              flex: '0 0 200px',
              mx: 2
            }}>
              <Card sx={{
                // p: 3,
                borderColor: 'accent'
              }}>
                <Box as='img' src={`${__ASSET_URL__}${image.src}`} sx={{
                  width: '100%'
                }} />
              </Card>

              <Flex sx={{
                // mt: 2,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                {image.name}

                <Box>
                <RfSingleAction
                  onSuccess={() => source.refresh()}
                  initialValues={{id: image.id}}
                  header='Sil' actionKey='RnxJournal.DeleteGalleryImage' iconName='circle-remove' buttonText='Sil' />
                </Box>
              </Flex>
            </Box>
          )
        })}
      </Flex>


      {/* <Card sx={{
        p: 3,
        borderColor: 'accent'
      }}>
        {!source.ready && 'Yükleniyor'}

        {!source.data?.images && 'Yok'}

        <Flex sx={{
          mx: -2
        }}>
        {source?.data?.images?.map(image => {
          return (
            <Box key={image} sx={{
              flex: '0 0 200px',
              mx: 2
            }}>
            <Box as='img' src={`${__ASSET_URL__}${image}`} sx={{

              width: '100%'
            }}/>
            </Box>
          )
        })}
        </Flex>
      </Card> */}
    </Box>
  )
}

export default GalleryMgmt