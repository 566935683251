import React from 'react'

import dayjs from 'dayjs'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import useRunicSource from 'runic/hooks/useRunicSource'
import useRunicListView from 'runic/hooks/useRunicListView'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import Entity from 'runic-vera/components/Entity'

import { Link, useParams, Routes, Route } from 'runic-vera/router'

import Card from 'hayatinidegistir/components/Card'


const UserMgmt = props => {
  const source = useRunicSource('RnxCourse.Panel.user_list')
  console.log(source)
  if (!source.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )
  return (
    <Box>
      <Flex sx={{
        justifyContent: 'space-between'
      }}>
      <Box sx={{
        variant: 'text.pageHeader'
      }}>
        Kullanıcılar
      </Box>

      <Box>
      <RfSingleAction onSuccess={source.refresh} upload header='Yeni Kullanıcı' actionKey='RnxCourse.CreateUser' anchorText='Yeni Kullanıcı' fields={[
        {
          label: 'İsim',
          name: 'name',
          kind: 'text',
          required: true,
        },

        {
          label: 'E-Posta',
          name: 'email',
          kind: 'text',
          required: true,
        },

        {
          label: 'Kullanıcı Adı',
          name: 'username',
          kind: 'text',
          required: true,
        },

        {
          label: 'Şifre',
          name: 'password',
          kind: 'password',
          required: true,
        },

        {
          label: 'Bitiş',
          name: 'dateEnd',
          kind: 'date',
          required: true,
        },
      ]} />
      </Box>
      </Flex>

      <Routes>
        <Route path='/' element={<Card sx={{
        px: 3
      }}>
      {source.data.users.map(user => {
        return (
          <Link to={`detay/${user.id}`} key={user.id} sx={{
            display: 'flex',
            my: 3
          }}>
            <Box flex='0 0 200px'>
            {user.name} ({user.username})
            </Box>

            <Box flex='0 0 200px'>
            {user.email}
            </Box>

            <Box flex='0 0 200px'>
            {dayjs(user.datetime_created).format('D MMMM YYYY')}
            </Box>

            {user.data?.dateEnd && <Box flex='0 0 200px'>
            {dayjs(user.data.dateEnd).format('D MMMM YYYY')}
            </Box>}
          </Link>
        )
      })}
      </Card>}/>

        <Route path='detay/:userId' element={<UserDetail/>}/>
        <Route path='detay/:userId/gunluk/:journalId/:journalDate' element={<JournalDetail />} />
      </Routes>
    </Box>
  )
}

import { AdminJournal } from 'hayatinidegistir/apps/client/components/Journal'
const JournalDetail = props => {
  const { journalId, journalDate } = useParams()
  return (
    <Box>
      <Link to='../../../'>Geri Dön</Link>
      <AdminJournal journalId={journalId} journalDate={journalDate} />
    </Box>
  )
}

const UserDetail = props => {
  const { userId } = useParams()
  const source = useRunicSource('RnxCourse.Panel.user', { params: {userId}})
  console.log(source)
  if (!source.ready) return (
    <Box>
      Yükleniyor
    </Box>
  )

  const user = source.data.user

  return (
    <Box>
      <Link to='../../'>Geri dön</Link>

      <Card p={3} mt={3}>
      <Flex>
      <Box flex='0 0 200px'>
            {user.name} ({user.username})
            </Box>

            <Box flex='0 0 200px'>
            {user.email}
            </Box>

            <Box flex='0 0 200px'>
            {dayjs(user.datetime_created).format('D MMMM YYYY')}
            </Box>

            <Box flex='0 0 200px'>
            {source.data?.session?.last_seen_addr}
            </Box>
      </Flex>
      </Card>

      <Card p={3} mt={3}>
      <Subscription subscription={source.data?.subscription?.rnx_subscription}/>
      </Card>

      <Card p={3} mt={3}>
      <Activity rcUserId={user.id}/>
      </Card>

      <Card p={3} mt={3}>
      <Journals rcUserActorId={user.rc_entity_id}/>
      </Card>
    </Box>
  )
}

import * as RV from 'runic-vera/components/RunicView'

const Activity = ({
  rcUserId
}) => {
  const result = RV.useRunicGraphQuery({
    'rx_user_action_activity_list': {
      filters: {
        rc_user_id: rcUserId,
        domain: 'JOURNAL'
      },
      fields: [
        'data',
        'action_kind',
        'datetime_activity',
        ['rc_user', {'fields': ['name']}]
      ]
    }
  })

  const nameMap = {
    'JOURNAL_FOOD_UPDATE': 'Yemek',
    'JOURNAL_MOOD_UPDATE': 'His',
    'JOURNAL_MEASUREMENTS_UPDATE': 'Ölçü',
    'JOURNAL_EXERCISE_UPDATE': 'Egzersiz',
    'JOURNAL_PHOTOS_UPDATE': 'Fotoğraf',
  }

  return (
    <>
    <Box sx={{
        fontWeight: 'bold',
        // fontSize: 'xl',
        mb: 2,
        variant: 'text.headerText',
        color: 'primary2'
      }} >
        Aktiviteler
      </Box>

      <Box>
        {result?.graph?.rx_user_action_activity_list?.items.map(obj => {
          return (
            <Box key={obj.id} mb={2}>
              {dayjs(obj.datetime_activity).add(3, 'hours').format('DD/MM/YYYY HH:mm')} | {obj.rc_user.name}, günlüğüne {nameMap[obj.action_kind]} bilgisi girdi.
            </Box>
          )
        })}
      </Box>
    </>
  )
}

const Journals = ({
  rcUserActorId
}) => {
  const result = RV.useRunicGraphQuery({
    'rnx_journal_log_list': {
      order_by: 'datetime_log|desc',
      filters: {
        owner_rc_entity_id: rcUserActorId,
      },
      fields: [
        'name',
        'data',
        'kind',
        'rnx_journal_id',
        'date_log',
        'datetime_created'
      ]
    }
  })

  const nameMap = {
    'FOOD': 'Yemek',
    'MOOD': 'His',
    'MEASUREMENTS': 'Ölçü',
    'EXERCISE': 'Egzersiz',
    'PHOTOS': 'Fotoğraf',
  }

  return (
    <>
    <Box sx={{
        fontWeight: 'bold',
        // fontSize: 'xl',
        mb: 2,
        variant: 'text.headerText',
        color: 'primary2'
      }} >
        Günlükler
      </Box>

      <Box>
        {result?.graph?.rnx_journal_log_list?.items.map(obj => {
          return (
            <Box key={obj.id} mb={2}>
              <Link to={`gunluk/${obj.rnx_journal_id}/${obj.date_log}`} sx={{
              justifyContent: 'space-between',
              display: 'flex',
              my: 3,
              color: 'primary'
            }}>
              {dayjs(obj.date_log).format('DD/MM/YYYY')} | {nameMap[obj.name]}
              </Link>
            </Box>

          )
        })}
      </Box>
    </>
  )
}

const Subscription = ({
  subscription
}) => {
  let content
  if (!subscription) {
    content = (
      <Box>

        Aktif abonelik bulunmamaktadır.
      </Box>
    )
  } else {
    let endDate
    if (subscription.kind == 'RECURRING_MONTH') {
      endDate = dayjs(subscription.datetime_first_started).add(3, 'hours').add(subscription.period_interval, 'month')
    }
    content = (
      <Box>
        <Box>
          <strong>Başlangıç Tarihi:</strong> {dayjs(subscription.datetime_first_started).add(3, 'hours').format('DD/MM/YYYY')} ({dayjs(subscription.datetime_first_started).add(3, 'hours').fromNow()})
        </Box>

        <Box sx={{
          mt: 2
        }}>
          <strong>Bitiş Tarihi:</strong> {endDate.format('DD/MM/YYYY')} ({endDate.fromNow()})
        </Box>

        <Box sx={{
          mt: 2
        }}>
          {subscription.status === 'ACTIVE' ? (
            <Box sx={{
              mt: 2
            }}>
              Abonelik aktif, {endDate.format('DD/MM/YYYY')} tarihinde otomatik olarak yenilenecek.

              {/* <RfSingleAction onSuccess={() => {
                source.refresh()
                setMessage('Abonelik otomatik olarak yenilenmeyecek.')
              }}  buttonText='İptal et' iconName='circle-remove' anchorText='Abone otomatik yenilemeyi iptal et' header='Abone otomatik yenilemeyi iptal et' actionKey='Hayatinidegistir.CancelSubscription' fields={[
                    {
                      label: 'Şifren',
                      name: 'password',
                      kind: 'password',
                      required: true,
                    },
                  ]} /> */}
            </Box>
          ) : (
            <Box sx={{
              mt: 2
            }}>
              Abonelik otomatik yenilemeye kapatıldı. {endDate.format('DD/MM/YYYY')} tarihinde iptal edilecek ve hesabın kapatılacak.

              {/* <RfSingleAction onSuccess={() => {
                source.refresh()
                setMessage('Abonelik otomatik olarak yenilenecek.')
              }}  buttonText='Yenilemeyi aktifleştir' iconName='refresh' anchorText='Abone otomatik yenilemeyi aktifleştir' header='Abone otomatik yenilemeyi aktifleştir' actionKey='Hayatinidegistir.EnableSubscriptionRenewal' fields={[
                    {
                      label: 'Şifren',
                      name: 'password',
                      kind: 'password',
                      required: true,
                    },
                  ]} /> */}
            </Box>
          )}
        </Box>

      </Box>
    )
  }
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        // fontSize: 'xl',
        mb: 2,
        variant: 'text.headerText',
        color: 'primary2'
      }} >
        Abonelik
      </Box>

      {content}

      {/* {message && (
        <Box sx={{
          p: 3,
          color: '#FFF',
          bg: 'primary'
        }}>
          {message}
        </Box>
      )} */}
    </Box>
  )
}

export default UserMgmt