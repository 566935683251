import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import createKey from 'runic/util/key'
import runicActions from 'runic/systems/runic/actions'

const defaultOps = {
  maxAge: 3000,
  variables: null,
}

import { createSelector } from 'reselect'
import { createCachedSelector } from 're-reselect'


const getDependencyKeys = (state, fetchKey) => state.runic.graph.data?.[fetchKey]?.dependencies
const getTypes = (state) => state.runic.graph.types
const getGraphData = (state, fetchKey) => state.runic.graph.data[fetchKey]

const dependencySelector = createCachedSelector(
  getDependencyKeys,
  getTypes,
  (keys, types) => {
    if (!keys) return null
    const result = {}
    keys.forEach(key => {
      result[key] = types[key]
    })
    return result
  }
)(
  (state, fetchKey) => fetchKey
)

const isListGraph = data => data?.__kind === 'list'
const isTypeGraph = data => data?.__typename !== undefined
import isArray from 'lodash/isArray'

const getFieldData = fieldName => {
  let name, args
  if (isArray(fieldName)) {
    name = fieldName[0]
    args = fieldName[1]
  } else {
    name = fieldName
  }

  return [name, args]
}

const denormalizeObject = (graphData, graphQuery, types) => {
  const result = {...graphData}
  const typeObjKey = `${graphData.__typename}:${graphData.id}`
  const typeObj = types[typeObjKey]
  graphQuery.fields.forEach(fieldName => {
    const [name, args] = getFieldData(fieldName)
    let fieldData = typeObj[name]
    if (args && args.fields) fieldData = denormalizeGraph(fieldData, args, types)
    result[name] = fieldData
  })
  return result
}

const denormalizeGraph = (graphData, graphQuery, types) => {
  if (isTypeGraph(graphData)) return denormalizeObject(graphData, graphQuery, types)
  if (isListGraph(graphData)) {
    const denormalizedList = {...graphData}
    denormalizedList.items = graphData.items.map(item => {
      const graphType = graphQuery
      const itemResult = denormalizeObject(item, graphType, types)
      return itemResult
    })
    return denormalizedList
  }

  const result = {}
  Object.entries(graphData).forEach(([key, value]) => {
    // console.log('entry', key, value, isListGraph(value), isTypeGraph(value))
    if (isListGraph(value)) {
      const denormalizedList = {...value}
      denormalizedList.items = value.items.map(item => {
        const graphType = graphQuery[key]
        // console.log('item', item)
        const itemResult = denormalizeObject(item, graphType, types)
        // console.log('itemresult', itemResult)
        return itemResult
      })
      result[key] = denormalizedList
    } else if (isTypeGraph(value)) {
      const graphType = graphQuery[key]
      result[key] = denormalizeObject(value, graphType , types)
    }
  })
  return result
}

const getDenormalizedGraph = createCachedSelector(
  dependencySelector,
  getGraphData,
  (types, data) => {
    const graph = types ? denormalizeGraph(data.graph, data.graphQuery, types) : null
    const status = data?.status
    return {
      graph, status
    }
  }
)(
  (state, fetchKey) => fetchKey
)

const useRunicGraphQuery = (nameOrQuery, options = defaultOps) => {
  const { variables, maxAge } = options

  const fetchKey = React.useMemo(() => createKey({s: nameOrQuery, v: variables}), [nameOrQuery, variables])
  const dispatch = useDispatch()

  const refresh = React.useCallback(() => {
    if (typeof nameOrQuery === 'string') {
      dispatch(runicActions.graphQuery({queryName: nameOrQuery, variables, fetchKey}, fetchKey))
    } else {
      dispatch(runicActions.graphQuery({query: nameOrQuery, variables, fetchKey}, fetchKey))
    }
  }, [fetchKey])

  const { graph, status } = useSelector(state => getDenormalizedGraph(state, fetchKey))

  let fetchRequired = true
  if (status) {
    if (!maxAge) fetchRequired = false
    else {
      const ageInMs = new Date() - status.dt
      if (ageInMs < maxAge) fetchRequired = false
    }
  }

  if (fetchRequired) refresh()

  const result = React.useMemo(() => ({
    graph,
    dt: status?.dt,
    error: status?.error,
    status: status?.status,
    ready: status?.status === 'READY',
    refresh,
  }), [fetchKey, graph])

  return result
}

export default useRunicGraphQuery