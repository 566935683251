import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Vendor
import * as changeCase from 'volcano/util/text'
import { useSelector } from 'react-redux'


const Entity = ({
  modelName,
  id,
  nameKey = 'name',
  children,sx
}) => {
  const entity = useSelector(state => state.runic.model?.[changeCase.snake(modelName)]?.entity?.[id])
  if (!entity) return null
  let content
  if (children) content = (children(entity))
  else content = entity[nameKey]

  if (sx) {
    return <Box sx={sx}>{content}</Box>
  } else {
    return content
  }
}

export default Entity