import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'

// Runic
import { Link } from 'runic-vera/router'

import useRunicSource from 'runic/hooks/useRunicSource'

import Card from 'hayatinidegistir/components/Card'

import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'
import { TextField, RadioField, CheckboxField } from 'runic-aura/components/RunicField'
import Button from 'volcano/components/Button'

import rnxForumActions from 'elements/rnx_forum/rnx_forum/actions'

export const getInitials = (text, length) => {
  const _length = length || 2

  const splitText = text.split(' ')
  return splitText.length == 1 ? (
    text.substring(0, _length).toUpperCase()
  ) : (
      splitText.slice(0, length).map(word => (word[0].toUpperCase())).join('')
    )
}

import dayjs from 'dayjs'


import { useParams, useResolvedPath, useLocation, Routes, Route } from 'runic-vera/router'

const CategoryLink = ({
  to,
  children,
  end
}) => {
  const path = useResolvedPath(to)
  const location = useLocation()
  const active = end ? location.pathname == path.pathname : location.pathname.startsWith(path.pathname)
  return (
    <Link to={to} sx={{
      display: 'block',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary',
      px: 3,
      py: 2,
      mb: 2,
      ml: [0, null, null, 3],
    }} variants={{
      active: {
        backgroundColor: 'primary',
        color: '#FFF'
      }
    }}
    variant={active && 'active'}
    >
      {children}
    </Link>
  )
}

const ForumIndex = props => {
  const source = useRunicSource('RnxForum.Forum.categories')
  if (!source.ready) {
    return (
      <Box>
        Yükleniyor
      </Box>
    )
  }


  const categories = source.data

  return (
    <Box sx={{
      // p: 4
    }}>
      <Flex justifyContent='space-between' flexWrap='wrap' sx={{
        flexDirection: ['column', null, null, 'row']
      }}>
        <Box sx={{
          fontWeight: 'bold',
          fontSize: 'xl',
          mb: 3
        }} >
          Konuşulanlar
      </Box>
        <Flex sx={{
          // flexDirection: ['column', null, null, 'row']
          flexWrap: ['wrap', null, null, 'no-wrap'],
          justifyContent: ['space-between', null, null, 'unset']
        }}>
        <CategoryLink to={`./`} end >Tamamı</CategoryLink>
          {categories.map((categoryItem, dx) => (
            <CategoryLink key={dx} to={`kategori/${categoryItem.value}`} >{categoryItem.label}</CategoryLink>
          ))}
        </Flex>
      </Flex>
      <Routes>
        <Route path="*" element={<ForumItems categories={categories}/>} />
        <Route path="kategori/:categoryId/*" element={<ForumItems categories={categories}/>} />
        <Route path="b/:topicId/*" element={<ForumTopicDetail/>} />
        <Route path="kategori/:categoryId/b/:topicId/*" element={<ForumTopicDetail/>} />
      </Routes>
    </Box>
  )
}

import ForumTopicDetail from './ForumTopicDetail'

import rnxForumClientActions from 'hayatinidegistir/apps/client/forum/actions'

const ForumItems = ({
  categories,
}) => {
  const params = useParams()
  const categoryId = params.categoryId || '@all'
  const categoryName = params.categoryId ? categories.find(x => x.value == params.categoryId).label : 'Tamamı'
  const dispatch = useDispatch()
  const source = useRunicSource('RnxForum.Forum.topic_list', { params: { rnx_forum_category_id: categoryId } })
  if (!source.ready) {
    return (
      <Box>
        Yükleniyor
      </Box>
    )
  }
  const topics = source.data.rnx_forum_topic_list
  if (!topics) return null
  console.log('source', source)

  return (
    <Flex sx={{ flexDirection: 'column' }}>
    <Flex sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 3
    }}>
      <Box sx={{
        variant: 'text.pageHeader'
      }}>
      {categoryName}
      </Box>

      <Button label='Yeni Başlık' onClick={() => dispatch(rnxForumClientActions.toggleComposer())} />
    </Flex>
      {/* <NewTopic rnxForumId={category.id} refresh={source.refresh} key={`${category.id}+${key}`} onSuccess={() => keySet(e => e + 1)} /> */}
      {topics.map((topic, dx) => {
        const lastMessage = topic.data.lastMessage
        console.log(topic)
        return (
          <Link key={dx} to={`b/${topic.id}`}>
            <Card sx={{
              p: 3,
              width: '100%',
              mb: 3
            }}>
              <Flex justifyContent='space-between' sx={{
                alignItems: [null, null, null, 'center'],
                flexDirection: ['column', null, null, 'row']
              }}>
                <Flex sx={{
                  alignItems: 'center'
                }}>
                <Box sx={{
                    flex: '0 0 auto'
                  }}>
                    <Box sx={{
                      background: '#F8F8F6',
                      border: '1px solid #E3E3E3',
                      borderRadius: '1.1px',
                      p: 2,
                  mr: 2
                    }}>
                      {getInitials(topic.created_by_rnx_forum_user.name, 2)}
                    </Box>
                  </Box>


                  <Card.Title>
                  {topic.name}
                </Card.Title>
                </Flex>
                <Flex sx={{
                  alignItems: ['right', null, null, 'unset'],
                  mt: [2, null, null, 0]
                }}>
                  <Box mr={3}>
                    {topic.count_message} cevap
                </Box>

                  <Box>
                    {dayjs(topic.datetime_created).add(3, 'hours').fromNow()}
                  </Box>
                </Flex>
              </Flex>

              {lastMessage && <Flex sx={{
                ml: 3,
                mt: 3,
              }}>
                <Box sx={{
                  background: '#F8F8F6',
                  border: '1px solid #E3E3E3',
                  borderRadius: '1.1px',
                  p: 2
                }}>
                  {lastMessage && getInitials(lastMessage?.created_by_rc_actor.identifier, 2)}
                </Box>

                <Box sx={{ ml: 2 }}>
                  <Box fontWeight='bold'>{lastMessage?.created_by_rc_actor.identifier}</Box>
                  <Box color='dark8' mt={1} fontSize='s'>
                    {dayjs(lastMessage?.datetime_created).fromNow()}
                  </Box>
                </Box>

                <Box sx={{ ml: 3 }}>
                  {lastMessage?.message}
                </Box>

              </Flex>}

            </Card>
          </Link>
        )
      })}
    </Flex>
  )
}

export default ForumIndex