import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import RfSingleAction from 'runic-form/components/RfSingleAction'
import useRunicSource from 'runic/hooks/useRunicSource'

import RfRichTextFieldView from 'runic-form/components/RfRichTextFieldView'
import RfLongFormTextFieldPreview from 'runic-form/components/RfLongFormTextFieldPreview'


import Card from 'hayatinidegistir/components/Card'


const AnnouncementMgmt = props => {
  const source = useRunicSource('RxFeed.Feed.items', { params: { codeName: '@RR:ANNOUNCEMENT' } })
  console.log('ann', source)
  return (
    <Box sx={{

    }}>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2
      }}>
        <Box sx={{
          variant: 'text.pageHeaderText'
        }}>
          Duyuru
        </Box>

        <Box>
          <RfSingleAction
            onSuccess={() => source.refresh()}
            sx={{
              width: 400
            }}
            upload header='Yeni Duyuru' actionKey='RxFeed.CreateAnnouncement' anchorText='Yeni Duyuru' fields={[
              {
                label: 'Başlık',
                name: 'name',
                kind: 'text',
                required: true,
              },

              {
                label: 'Kısa Metin',
                name: 'description',
                kind: 'richText',
                required: true,
              },

              {
                label: 'Metin',
                name: 'text',
                kind: 'longFormText',
                required: true,
              },

              {
                label: 'Görsel',
                name: 'image',
                kind: 'contentImage',
                required: true,
              },

              {
                label: 'Video',
                name: 'video',
                kind: 'video',
                required: true,
              },

              {
                label: 'Kategoriler',
                kind: 'dropdown',
                name: 'categories',
                isMulti: true,
                options: [
                  {
                    value: {
                      codeName: 'SIDEBAR',
                      name: 'Yan Menü'
                    },
                    label: 'Yan Menü',
                  },

                  {
                    value: {
                      codeName: 'TOP',
                      name: 'Ana Sayfa Üst bölüm'
                    },
                    label: 'Ana Sayfa Üst bölüm',
                  },

                  {
                    value: {
                      codeName: 'MAIN_FEED',
                      name: 'Olan, Biten'
                    },
                    label: 'Olan, Biten',
                  },
                ]
              }
            ]} />
        </Box>
      </Flex>

      {!source.ready && 'Yükleniyor'}

      {!source.data?.feedItems && 'Yok'}

      <Box sx={{
        mx: -2
      }}>
        {source?.data?.feedItems?.map(feedItem => {
          return (
            <Box key={feedItem.id} sx={{
              mx: 2,
              mb: 3
            }}>
              <AnnouncementItem feedItem={feedItem} refresh={source.refresh}/>
            </Box>
          )
        })}
      </Box>


      {/* <Card sx={{
        p: 3,
        borderColor: 'accent'
      }}>
        {!source.ready && 'Yükleniyor'}

        {!source.data?.images && 'Yok'}

        <Flex sx={{
          mx: -2
        }}>
        {source?.data?.images?.map(image => {
          return (
            <Box key={image} sx={{
              flex: '0 0 200px',
              mx: 2
            }}>
            <Box as='img' src={`${__ASSET_URL__}${image}`} sx={{

              width: '100%'
            }}/>
            </Box>
          )
        })}
        </Flex>
      </Card> */}
    </Box>
  )
}

const AnnouncementItem = ({
  feedItem,
  refresh
}) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <Card sx={{
      // p: 3,
      borderColor: 'accent',
      p: 3
    }}>
      <Flex sx={{
        // mt: 2,
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Flex sx={{
          alignItems: 'center'
        }}>
          <Box sx={{
            variant: 'text.header'
          }}>
            {feedItem.data.name}
          </Box>

          <Box sx={{
            // variant: 'text.header',
            mx: 3,
            // color: 'dark8',
            fontSize: 's'
          }}>
            {feedItem.is_active ? 'Yayında' : 'Yayında Değil'}
          </Box>

          <Box sx={{
            // variant: 'text.header',
            ml: 2,
            color: 'dark8',
            fontSize: 's'
          }}>
            {feedItem.data?.categories.map(cat => cat.name).join(', ')}
          </Box>
        </Flex>

        <Box>
          <RfSingleAction
          sx={{
            width: 400
          }}
            onSuccess={() => source.refresh()}
            initialValues={{ id: feedItem.id }}
            header='Sil' actionKey='RxFeed.DeleteAnnouncement' iconName='circle-remove' buttonText='Sil' />

<RfSingleAction
            initialValues={{
              id: feedItem.id,
              name: feedItem.data.name,
              description: feedItem.data.description,
              text: feedItem.data.text,
              categories: feedItem.data.categories,
              image: feedItem.data.image?.src,
              video: feedItem.data.video?.src,
            }}
            onSuccess={() => refresh()}
            upload header='Güncelle' buttonText='Güncelle' actionKey='RxFeed.UpdateAnnouncement' iconName='pencil' fields={[
              {
                label: 'Başlık',
                name: 'name',
                kind: 'text',
                required: true,
              },

              {
                label: 'Kısa Metin',
                name: 'description',
                kind: 'richText',
                required: true,
              },

              {
                label: 'Metin',
                name: 'text',
                kind: 'longFormText',
                required: true,
              },

              {
                label: 'Görsel',
                name: 'image',
                kind: 'contentImage',
                required: true,
              },

              {
                label: 'Video',
                name: 'video',
                kind: 'video',
                required: true,
              },

              {
                label: 'Kategoriler',
                kind: 'dropdown',
                name: 'categories',
                isMulti: true,
                // getValue: (field, options, value) => options?.some(x => {
                //   console.log(x.value.codeName, value)
                //   return x.value.codeName === value.codeName
                // }),
                getValue: (field, options, value) => value.map(v => ({
                  value: {
                    codeName: v.codeName,
                    name: v.name,
                  },
                  label: v.name,
                })),
                options: [
                  {
                    value: {
                      codeName: 'SIDEBAR',
                      name: 'Yan Menü'
                    },
                    label: 'Yan Menü',
                  },

                  {
                    value: {
                      codeName: 'TOP',
                      name: 'Ana Sayfa Üst bölüm'
                    },
                    label: 'Ana Sayfa Üst bölüm',
                  },

                  {
                    value: {
                      codeName: 'MAIN_FEED',
                      name: 'Olan, Biten'
                    },
                    label: 'Olan, Biten',
                  },
                ]
              }
            ]} />
        </Box>
      </Flex>

      {feedItem.data.image && (
        <Box as='img' src={`${__ASSET_URL__}${feedItem.data.image}`} sx={{
          width: '40%',

          display: 'block'
        }} />
      )}

{feedItem.data.video && (
  <Box as='video' src={`${__ASSET_URL__}${feedItem.data.video}`} controls sx={{
    width: '40%',

          display: 'block'
  }}>
    <source src={`${__ASSET_URL__}${feedItem.data.video}`}
      type="video/mp4"></source>
  </Box>
      )}

      <Box sx={{
        mt: 1
      }}>
        <RfRichTextFieldView content={feedItem.data.description} />

      </Box>

      <a onClick={() => setExpanded(v => !v)}>Detaylar</a>

      {expanded && <Box sx={{
        mt: 2
      }}>
        <RfLongFormTextFieldPreview content={feedItem.data.text} />

      </Box>}
    </Card>
  )
}

export default AnnouncementMgmt