import React from 'react'

// Vendor
import loadable from '@loadable/component'

// Runic
import { Route, Routes, useParams } from 'runic-vera/router'
import RunicAuthCheck from './RunicAuthCheck'
import useDirector from 'runic/hooks/useDirector'

import RunicTenantSelector from './RunicTenantSelector'
import RunicTenantIndex from './RunicTenantIndex'

import RunicDevBar from 'runic-vera/apps/dev-bar/components/RunicDevBar'


const RunicSocketManager = loadable(() => import('./RunicSocketManager'))


const RunicHome = ({
  TenantIndex,
  beforeRoutes,
  afterRoutes
}) => {
  const director = useDirector()
  const hasSocket = director.rConfig.socket?.enabled

  const TenantIndexComponent = TenantIndex || director.rConfig.tenantIndex || RunicTenantIndex

  return (
    <RunicAuthCheck>
      {beforeRoutes}
      {hasSocket && <RunicSocketManager/>}
      <Routes>
        <Route path="/home" element={<RunicTenantSelector />}/>
        <Route path="/:rcTenantId/*" element={<TenantIndexComponent />}/>
      </Routes>
      {afterRoutes}
      <RunicDevBar/>
    </RunicAuthCheck>
  )
}

export default RunicHome
