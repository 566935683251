import React from 'react'

import Director from 'runic/director'


const useRunicApp = ({
  apps, systems, storeCreator, config
}) => {
  const [director, directorSet] = React.useState(() => new Director(config))
  const storeRef = React.useRef()
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    // director.registerApp(coreApp)

    apps?.forEach(app => director.registerApp(app))
    systems?.forEach(system => director.registerSystem(system))
    const unsubscribe = director.subscribe('update', directorSet)
    storeRef.current = storeCreator(director)
    setIsReady(true)
    return () => unsubscribe()
  }, [])

  return ({
    isReady,
    store: storeRef.current,
    director
  })
}

export default useRunicApp