import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

import Card from 'hayatinidegistir/components/Card'

const HowToUse = props => {
  return (
    <>
    <Box sx={{
        fontWeight: 'bold',
        fontSize: 'xl',
        mb: 3,
        variant: 'text.pageHeaderText',
      }} >
        Nasıl Çalışır?
      </Box>
    <Card>
        <Box as='video' src={`${__ASSET_URL__}/1/howtouse2.mp4`} controls sx={{
          width: '100%'
        }}>
          <source src={`${__ASSET_URL__}/1/howtouse2.mp4`}
            type="video/mp4"></source>
        </Box>
      </Card>
    </>
  )
}

export default HowToUse