import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'
import { Menu, MenuItem } from 'volcano/components/Menu'

// Runic
import RfField from 'runic-form/components/RfField'
import Form, { useRunicForm, useUrlForm, FormError } from 'runic-form/components/RfForm'
import useRunicActionCreator from 'runic/hooks/useRunicActionCreator'


const RfSingleAction = ({
  iconName = 'plus',
  iconColor = 'primary',
  actionKey,
  onSuccess,
  onError,
  fields,
  initialValues,
  buttonText = 'Oluştur',
  header,
  anchorText,
  iconSize,
  ctx,
  upload,
  url,
  zIndex,
  formOnly,
  local,
  onSubmit,
  sx,
  parseData,
  errorMap,
  children
}) => {
  const ref = React.useRef()
  let content

  if (local) {
    content = <RfSingleLocalActionForm onSubmit={onSubmit} onSuccess={onSuccess} onError={onError} menuRef={ref.current} fields={fields} initialValues={initialValues} buttonText={buttonText} header={header} ctx={ctx} children={children} errorMap={errorMap} />
  } else {
    content = url ? (
      <RfSingleActionUrlForm parseData={parseData} upload={upload} onSuccess={onSuccess} onError={onError} menuRef={ref.current} actionKey={actionKey} fields={fields} initialValues={initialValues} buttonText={buttonText} header={header} ctx={ctx} url={url} children={children} errorMap={errorMap}/>
    ) : (
      <RfSingleActionForm parseData={parseData} upload={upload} onSuccess={onSuccess} onError={onError} menuRef={ref.current} actionKey={actionKey} fields={fields} initialValues={initialValues} buttonText={buttonText} header={header} ctx={ctx} children={children} errorMap={errorMap}/>
    )
  }

  if (formOnly) return (
    <Box sx={sx}>
      {content}
    </Box>
  )
  return (
    <Menu anchor={<ButtonIcon iconSize={iconSize} iconName={iconName} color={iconColor} text={anchorText} />} placement='bottom-start' ref={ref} zIndex={zIndex}>
      <Box sx={sx}>
      {content}
      </Box>
    </Menu>
  )
}


const RfSingleActionForm = ({
  onSuccess,
  onError,
  menuRef,
  actionKey,
  fields,
  initialValues,
  header,
  buttonText,
  ctx,
  upload,
  parseData,
  children,
  errorMap
}) => {
  let actionKind
  if (upload) actionKind = 'RCR_UPLOAD'

  const [elementName, actionName] = actionKey.split('.')
  const action = useRunicActionCreator(actionName, elementName, actionKind)
  const [formProps, isSubmitting] = useRunicForm(action, {
    action: (data) => {
      let actionData = { ...data }
      if (parseData) actionData = parseData(actionData)
      if (upload) {
        const formData = new FormData()
        const uploadData = {}
        fields?.forEach(f => {
          if (f.kind === 'imageSelect') {
            formData.append(f.name, data[f.name])
          } else if (f.kind === 'video') {
            formData.append(f.name, data[f.name]?.video)
          } else if (f.kind === 'contentImage') {
            formData.append(f.name, data[f.name]?.image)
          } else if (f.kind === 'file' || f.kind === 'fileSelect') {
            data[f.name].forEach(file => {
              formData.append(`${f.name}[]`, file)
            })
          } else {
            uploadData[f.name] = data[f.name]
          }
        })
        formData.append('data', JSON.stringify({...initialValues, ...uploadData}))
        formData.append('__version', '2')
        actionData = {
          formData: formData
        }
      }
      return (
        [actionData,{
          success: (response) => {
            onSuccess?.(response, { menuRef })
            menuRef?.close()
          },
          error: (response) => {
            onError?.(response, { menuRef })
          }
        }]
      )
    }
  }, {
    initialValues
  })

  return (
    <Box sx={{
      background: '#FFF',
      p: 3,
      minWidth: 200
    }}>
      <Box sx={{
        fontWeight: 'bold',
        mb: 2
      }}>
        {header}
      </Box>
      <Form {...formProps} ctx={ctx}>
        {fields?.map((f, dx) => {
          return (
            <Box key={dx}>
              <RfField field={f} autoFocus={dx === 0} />
            </Box>
          )
        })}
        <FormError errorMap={errorMap} error={formProps.error}/>
        <Button label={buttonText} fullWidth submit working={isSubmitting} />
        {children}
      </Form>
    </Box>
  )
}

const RfSingleLocalActionForm = ({
  menuRef,
  fields,
  initialValues,
  header,
  buttonText,
  ctx,
  onSubmit,
  children,
  errorMap
}) => {
  const [isSubmitting, setSubmitting] = React.useState(false)

  return (
    <Box sx={{
      background: '#FFF',
      p: 3,
      minWidth: 200
    }}>
      <Box sx={{
        fontWeight: 'bold',
        mb: 2
      }}>
        {header}
      </Box>
      <Form handleSubmit={(data) => onSubmit(data, {setSubmitting, menuRef})} ctx={ctx} initialValues={initialValues}>
        {fields?.map((f, dx) => {
          return (
            <Box key={dx}>
              <RfField field={f} autoFocus={dx === 0} />
            </Box>
          )
        })}
        <Button label={buttonText} fullWidth submit working={isSubmitting} />
        {children}
      </Form>
    </Box>
  )
}

// const parseFields = (fields, data) => {
//   const formdata = {}
//   const jsondata = {}
//   fields?.forEach(f => {
//     console.log('**', f, data)
//     if (f.kind === 'imageSelect') {
//       formdata[f.name] = data[f.name]
//     } else if (f.kind === 'video') {
//       formdata[f.name] = data[f.name].video
//     } else if (f.kind === 'contentImage') {
//       formdata[f.name] = data[f.name].image
//     } else if (f.kind === 'file' || f.kind === 'fileSelect') {
//       formdata[f.name] = []
//       data[f.name].forEach(file => {
//         formdata[f.name].push(file)
//       })
//     } else if (f.kind === 'group') {
//       const subdata = parseFields(f.fields, data[f.name])
//       formdata[f.name] =
//     } else {
//       jsondata[f.name] = data[f.name]
//     }
//   })
// }

const RfSingleActionUrlForm = ({
  onSuccess,
  onError,
  menuRef,
  actionKey,
  fields,
  initialValues,
  header,
  buttonText,
  ctx,
  upload,
  url,
  parseData,
  children,
  errorMap,
  ...props
}) => {
  const [formProps, isSubmitting] = useUrlForm({
    url,
    upload,
    parseData: data => {
      let actionData = { ...data }
      if (parseData) actionData = parseData(actionData)
      if (upload) {
        const formData = new FormData()
        const uploadData = {}
        fields?.forEach(f => {
          console.log('**', f, data)
          if (f.kind === 'imageSelect') {
            formData.append(f.name, data[f.name])
          } else if (f.kind === 'video') {
            formData.append(f.name, data[f.name].video)
          } else if (f.kind === 'contentImage') {
            formData.append(f.name, data[f.name].image)
          } else if (f.kind === 'file' || f.kind === 'fileSelect') {
            data[f.name].forEach(file => {
              formData.append(`${f.name}[]`, file)
            })
          } else if (f.kind === 'group') {

          } else {
            uploadData[f.name] = data[f.name]
          }
        })
        formData.append('data', JSON.stringify({...initialValues, ...uploadData}))
        formData.append('__version', '2')
        return formData
      }
      return actionData
    },
    initialValues,
    ...props,
    onSuccess: (data) => {
      onSuccess?.(data, { menuRef })
      menuRef?.close()
    },
    onError: (data) => {
      onError?.(data, { menuRef })
    },
  })

  return (
    <Box sx={{
      background: '#FFF',
      p: 3,
      minWidth: 200
    }}>
      <Box sx={{
        fontWeight: 'bold',
        mb: 2
      }}>
        {header}
      </Box>
      <Form {...formProps} ctx={ctx}>
        {fields?.map((f, dx) => {
          return (
            <Box key={dx}>
              <RfField field={f} autoFocus={dx === 0} />
            </Box>
          )
        })}
        <FormError errorMap={errorMap} error={formProps.error}/>
        <Button label={buttonText} fullWidth submit working={isSubmitting} />
        {children}
      </Form>
    </Box>
  )
}

export default RfSingleAction