import React from 'react'

// Reverb2
import { Flex, Box } from 'volcano'
import Icon from 'volcano/components/Icon'

// Runic
import useRunicState from 'runic/hooks/useRunicState'
import useRunicReduxAction from 'runic/hooks/useRunicReduxAction'
import { Link, NavLink, Routes, Route, useNavigate } from 'runic-vera/router'

import AdminIndex from 'hayatinidegistir/apps/admin/AdminIndex'
import UserSidebar from 'hayatinidegistir/apps/client/components/UserSidebar'
import UserHistory from 'hayatinidegistir/apps/client/pages/UserHistory'

import ForumHome from 'hayatinidegistir/apps/client/forum/pages/ForumHome'
import Feed from 'hayatinidegistir/apps/client/feed/pages/Feed'
import CourseHome from 'hayatinidegistir/apps/client/course/pages/CourseHome'
import ProfileHome from 'hayatinidegistir/apps/client/profile/pages/ProfileHome'
import BookmarkHome from 'hayatinidegistir/apps/client/bookmark/pages/BookmarkHome'
import HowToUse from 'hayatinidegistir/apps/client/pages/HowToUse'
// import Sidebar from './Sidebar'

const Base = props => {
  const { user } = useRunicState()
  const links = [
    {
      name: 'Bugun',
      url: '',
      end: true
    },

    {
      name: 'Günlüğüm',
      url: 'gunlugum'
    },

    {
      name: 'Konuşulanlar',
      url: 'konusulanlar'
    },

    {
      name: 'Eğitimler',
      url: 'egitimler'
    },

    {
      name: 'Profilim',
      url: 'profilim'
    },

    {
      name: 'Favorilerim',
      url: 'favorilerim'
    },

    {
      name: 'Nasıl Çalışır?',
      url: 'nasil-calisir'
    },

    {
      name: 'Çıkış',
      url: 'cikis'
    },

    // {
    //   name: 'Canlı Yayın',
    //   url: 'canliyayin'
    // },
  ]

  const [open, setOpen] = React.useState(false)

  return (
    <Flex {...props} sx={{
      ...props.sx,
      backgroundColor: '#F2F2F2',
      // width: '100vw',
      // minHeight: '100vh',
      // position: 'absolute',
      // top: 0,
      // left: 0,
      p: 4,
      flexDirection: ['column', null, null, 'row']
    }}>
      <Box sx={{
        flex: ['1 1 auta', null, null, '0 0 300px'],
        mb: [3, null, null, 0]
      }}>
        <Link to='/'><Box as='img' src={`${__ASSET_URL__}/1/hayatinidegistir/logo.svg`} sx={{
          mx: ['auto', null, null, 0],
          width: [150, null, null, 'auto'],
          display: 'block'
        }} /></Link>

        <Flex sx={{
          ml: 0,
          mt: 2,
          display: ['block', null, null, 'none'],
          border: '1px solid #FFF',
          borderColor: 'primary',
          p: 3,
          justifyContent: 'space-between'
        }} onClick={() => setOpen(v => !v)}>
          <Box>
            Menü
          </Box>

          <Box>
            <Icon name='menu'/>
          </Box>
        </Flex>

        <Box sx={{
          display: [open ? 'block' : 'none', null, null, 'block'],
          bg: ['#FFF', null, null, 'inherit'],
          p: 3,
          mb: 2
        }}>
          <Box sx={{
          ml: [0, null, null, 4],
          mt: [2, null, null, 4],
        }}>
          {user.name}
        </Box>

        <Flex sx={{
          flexDirection: 'column',
          mt: 4
        }}>
          {links.map((link, dx) => {
            return (
              <SidebarLink key={dx} link={link} onClick={() => setOpen(false)} />
            )
          })}
        </Flex>

        {user.state === 'PRESIDENT' && (
          <AdminBar />
        )}
        </Box>
      </Box>

      <Box sx={{
        flex: '1 1 0'
      }}>
        <Routes>
          {/* <Route path="auth/*" element={<AuthenticationBase />} />
          <Route path="/" element={<Navigate to={homeUrl} />} />
          <Route path="*" element={<>{children || <RunicVeraHome/>}</>} /> */}
          <Route path="admin/*" element={<AdminIndex />} />
          <Route path="gunlugum/*" element={<UserHistory />} />
          <Route path="konusulanlar/*" element={<ForumHome />} />
          <Route path="egitimler/*" element={<CourseHome />} />
          <Route path="profilim/*" element={<ProfileHome />} />
          <Route path="favorilerim/*" element={<BookmarkHome />} />
          <Route path="cikis/*" element={<Logout />} />
          <Route path="nasil-calisir/*" element={<HowToUse />} />
          <Route path="*" element={<Feed />} />
        </Routes>
      </Box>

      <Routes>
        <Route path="*" element={<Box sx={{
          flex: '0 0 400px',
          ml: [0, null, null, 4],
          mt: [4, null, null, 0]
        }}>
          <UserSidebar />
        </Box>} />
        <Route path="admin/*" element={<AdminSidebar />} />
      </Routes>

      {/* <Box sx={{
        flex: '0 0 400px',
        ml: 4
      }}>
        <Routes>
          <Route path="admin/*" element={<AdminSidebar/>} />
          <Route path="*" element={<UserSidebar/>} />
        </Routes>
      </Box> */}
    </Flex>
  )
}

const AdminSidebar = () => {
  return null
}

const SidebarLink = ({
  link,
  onClick
}) => {
  return (
    <NavLink to={`${link.url}/`} end={link.end} sx={{
      color: '#98A4A3',
      display: 'block',
      mb: 3,
      position: 'relative',
      pl: [0, null, null, 4],
      '&:hover': {
        color: '#0D7C74',
      }
    }} onClick={onClick} variants={{
      active: {
        color: '#0D7C74 !important',
        fontWeight: 'bold',
        '&:before': {
          content: "''",
          borderRadius: 100,
          backgroundColor: 'primary',
          width: 10,
          height: 10,
          position: 'absolute',
          display: ['none', null, null, 'block'],
          top: '3px',
          left: 10,
        }
      }
    }}>
      {link.name}
    </NavLink>
  )
}

const AdminBar = ({

}) => {
  const links = [
    {
      name: 'Dersler',
      url: 'admin/dersler',
    },

    {
      name: 'Duyurular',
      url: 'admin/duyuru',
    },

    {
      name: 'Galeri',
      url: 'admin/galeri',
    },

    {
      name: 'Günlükler',
      url: 'admin/gunlukler'
    },

    {
      name: 'Forum',
      url: 'admin/forum'
    },

    {
      name: 'Kullanıcılar',
      url: 'admin/kullanicilar'
    },

    // {
    //   name: 'Abonelikler',
    //   url: 'admin/abonelikler'
    // },

    {
      name: 'Yetkilendirme',
      url: 'admin/yetkilendirme'
    },

    // {
    //   name: 'Konuşulanlar',
    //   url: 'konusulanlar'
    // },

    // {
    //   name: 'Eğitimler',
    //   url: 'egitimler'
    // },

    // {
    //   name: 'Canlı Yayın',
    //   url: 'canliyayin'
    // },
  ]

  return (
    <Box sx={{
      borderTop: '1px solid #FFF',
      borderTopColor: 'primary',
      width: '150px',
      mt: 3
    }}>
      <Flex sx={{
        flexDirection: 'column',
        mt: 4
      }}>
        {links.map((link, dx) => {
          return (
            <SidebarLink key={dx} link={link} />
          )
        })}
      </Flex>
    </Box>
  )
}

const Logout = props => {
  const { user } = useRunicState()
  const logoutAction = useRunicReduxAction('RcApp.Logout')
  const navigate = useNavigate()

  React.useEffect(() => {
    logoutAction({rcTenantId: user && user.rc_tenant_id}, {
      // success: () => navigate(`/`)
      success: () => window.location.reload()
    })
  }, [])

  return (null)
}

export default Base