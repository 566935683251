import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import createKey from 'runic/util/key'
import runicActions from 'runic/systems/runic/actions'

const defaultOps = {
  maxAge: 3000,
  filters: null,
  opts: null
}

const useRunicListView = (name, options = defaultOps) => {
  const { filters, maxAge, opts } = options

  const fetchKey = React.useMemo(() => createKey({s: name, f: filters}), [name, filters])
  const sourceData = useSelector(state => state.runic.source?.[name]?.[fetchKey])

  const list = useSelector(state => state.runic.model?.[sourceData?.data?.modelName]?.list?.[fetchKey])
  const view = useSelector(state => state.runic.model?.[sourceData?.data?.modelName]?.view?.[fetchKey])

  const dispatch = useDispatch()

  const refresh = React.useCallback(() => {
    const params = {
      filters,
      opts: {
        ...opts,
        fetchKey
      }
    }
    dispatch(runicActions.modelSource(name, params, fetchKey))
  }, [fetchKey])

  let fetchRequired = true
  if (sourceData) {
    if (!maxAge) fetchRequired = false
    else {
      const ageInMs = new Date() - sourceData.dt
      if (ageInMs < maxAge) fetchRequired = false
    }
  }

  if (fetchRequired) refresh()

  const result = React.useMemo(() => ({
    list,
    view,
    dt: sourceData?.dt,
    error: sourceData?.error,
    status: sourceData?.status,
    ready: sourceData?.status === 'READY',
    empty: !list?.items?.length,
    refresh,
  }), [fetchKey, sourceData?.data])

  return result
}

export default useRunicListView