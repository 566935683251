import React from 'react'

// Volcano
import { Flex, Box } from 'volcano'

// Runic
import { Routes, Route, Link, useParams, useNavigate } from 'runic-vera/router'


const RouteTabs = ({
  tabs,
  paramKey = 'tabId',
  ctx,
  forceIndexRoute,
  wrapperSx
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const [isReady, isReadySet] = React.useState(forceIndexRoute ? false : true)
  React.useEffect(() => {
    if (forceIndexRoute) {
      navigate(tabs[0].codeName, { replace: true })
      isReadySet(true)
    }
  }, [])

  if (!isReady) return null

  return (
    <Box sx={wrapperSx}>
      <Flex mx={-2} mb={3}>
        {tabs.map((tab, dx) => {
          const isActive = dx === 0 && !forceIndexRoute ? params['*'] == '' : params['*'] === tab.codeName
          return (
            <Link to={dx === 0 && !forceIndexRoute ? './' : `${tab.codeName}`} key={dx} sx={{
              mx: 2,
              display: 'inlineBlock',
              borderBottom: isActive ? '3px solid #FFF' : '1px solid #FFF',
              borderBottomColor: 'primary',
              textAlign: 'center',
              fontWeight: isActive ? 'bold' : 'normal',
              py: 2,
              px: 3,
              transition: 'all 0.1s linear',
            }}>
              {tab.name}
            </Link>
          )
        })}
      </Flex>

      <Box>
        <Routes>
          <Route path='//*' element={<Tab tab={tabs[0]} ctx={ctx}/>} />
          <Route path={`:${paramKey}/*`} element={<RouteTab tabs={tabs} ctx={ctx} paramKey={paramKey} />} />
        </Routes>
      </Box>
    </Box>
  )
}

const Tab = ({
  tab,
  ctx
}) => {
  const element = React.cloneElement(tab.element, {tab, ctx})
  return (
    element
  )
}

const RouteTab = ({
  tabs,
  ctx,
  paramKey
}) => {
  const params = useParams()
  const tabCodeName = params[paramKey]
  const tab = tabs.find(x => x.codeName === tabCodeName)
  const element = React.cloneElement(tab.element, {tab, ctx})
  return (
    element
  )
}

export default RouteTabs