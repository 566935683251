import React from 'react'

// Volcano
import { Flex, Box } from 'volcano'


const Card = React.forwardRef((props, ref) => {
  return (
    <Box {...props} ref={ref} sx={{
      background: '#FFFFFF',
      border: '1px solid #0D7C74',
      borderRadius: '4px',
      boxSizing: 'border-box',
      ...props.sx,
    }}>
      {props.children}
    </Box>
  )
})

Card.Title = props => (
  <Box {...props} sx={{
    fontWeight: 'bold',
    ...props
  }}/>
)

export default Card