import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import * as RV from 'runic-vera/components/RunicView'

import Card from 'hayatinidegistir/components/Card'
import { Link } from 'runic-vera/router'


const BookmarkHome = props => {
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        fontSize: 'xl',
        mb: 3,
        variant: 'text.pageHeaderText',
      }} >
        Favorilerim
      </Box>

      <Card sx={{
        p: 3,
        width: '100%',
        mb: 3,
        mt: 3
        // mb: 3
      }}>
        <ForumBookmarks/>
      </Card>

      <Card sx={{
        p: 3,
        width: '100%',
        mb: 3,
        mt: 3
        // mb: 3
      }}>
        <CourseBookmarks/>
      </Card>
    </Box>
  )
}

const ForumBookmarks = props => {
  const result = RV.useRunicGraphQuery('RnxForum.UserForumBookmark.user_forum_bookmark_list')

  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        // fontSize: 'xl',
        mb: 2,
        variant: 'text.headerText',
        color: 'primary2'
      }} >
        Forum
      </Box>

      {result?.graph?.user_forum_bookmark_list.items.map(obj => {
        return (
          <Box key={obj.id} sx={{
            mb: 2
          }}>
            <Link to={`../konusulanlar/b/${obj.rnx_forum_topic.id}`}>{obj.rnx_forum_topic.name} | {obj.rnx_forum_topic.created_by_rnx_forum_user.name}</Link>
          </Box>
        )
      })}
    </Box>
  )
}

const CourseBookmarks = props => {
  const result = RV.useRunicGraphQuery('RnxCourse.UserCourseItemBookmark.user_course_item_bookmark_list')
  console.log('ss', result)
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        // fontSize: 'xl',
        mb: 2,
        variant: 'text.headerText',
        color: 'primary2'
      }} >
        Dersler
      </Box>

      {result?.graph?.user_course_item_bookmark_list.items.map(obj => {
        return (
          <Box key={obj.id} sx={{
            mb: 2
          }}>
            <Link to={`../egitimler/egitim/detay/${obj.rnx_course_item.rnx_course.id}/ders_detay/${obj.rnx_course_item.id}`}>{obj.rnx_course_item.rnx_course.name} | {obj.rnx_course_item.name}</Link>
          </Box>
        )
      })}
    </Box>
  )
}

export default BookmarkHome