import React from 'react'

// Vendor
import { useField } from 'react-final-form'
import isHotkey from 'is-hotkey'

import { Editor, Range, Node, Transforms, createEditor } from 'slate'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { withHistory } from 'slate-history'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'

// Project
import { Element, Leaf } from './ForumSlate'


const Toolbar = props => (
  <Flex {...props} sx={{
    mx: -1,
    px: 3,
    mt: 2,
    // boxShadow: '0 3px 4px rgba(0, 0, 0, 0.20)',
    // justifyContent: 'space-around'
    // py: 2,
  }}>
      {props.children}
    </Flex>
)

const Button = props => (
  <Box {...props} sx={{
    border: '1px solid #FFF',
    borderColor: 'primary',
    p: 1,
    mx: 1,
    backgroundColor: props.active ? 'primary' : 'unset',
    color: props.active ? '#FFF' : 'current-color'
  }} />
)

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const ForumEditor = ({
  name,
  data
}) => {
  // const [value, setValue] = React.useState(initialValue)
  const { input, meta } = useField(name || 'text', {
    initialValue: data || initialValue
  })
  const renderElement = React.useCallback(props => <Element {...props} />, [])
  const renderLeaf = React.useCallback(props => <Leaf {...props} />, [])
  const editor = React.useMemo(() => withHistory(withLinks(withReact(createEditor()))), [])
  // const editor = withHistory(withReact(createEditor()))
  return (
    // <Slate editor={editor} value={value} onChange={value => setValue(value)}>
    <Slate editor={editor} value={input.value} onChange={input.onChange}>
      <Box sx={{
        height: 200,
        border: '1px solid #000',
        borderColor: 'primary',
        mx: 2,
        // borderRadius: 2,
        // px: 3,
        // py: 1
      }}>

        <Toolbar>
          <MarkButton format="bold" icon="bold" />
          <MarkButton format="italic" icon="italic" />
          <MarkButton format="underline" icon="underline" />
          {/* <MarkButton format="code" icon="code" /> */}
          {/* <BlockButton format="heading-one" icon="header" /> */}
          <BlockButton format="heading-two" icon="header" />
          <BlockButton format="block-quote" icon="chevron-right" />
          <BlockButton format="numbered-list" icon="list-numbers" />
          <BlockButton format="bulleted-list" icon="list" />
        </Toolbar>
        <Box sx={{
          height: 'calc(100% - 45px)',
          overflow: 'auto',
          px: 3,
          // pt: 2,
          // pb: 2,
          mt: 2,
        }}>
        <Editable
          style={{
            height: 'calc(100% - 16px)',
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Mesajınızı buraya yazın."
          spellCheck
          // autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
        </Box>
      </Box>
    </Slate>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon name={icon} />
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon name={icon} />
    </Button>
  )
}

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
  // {
  //   type: 'paragraph',
  //   children: [
  //     { text: 'This is editable ' },
  //     { text: 'rich', bold: true },
  //     { text: ' text, ' },
  //     { text: 'much', italic: true },
  //     { text: ' better than a ' },
  //     { text: '<textarea>', code: true },
  //     { text: '!' },
  //   ],
  // },
  // {
  //   type: 'paragraph',
  //   children: [
  //     {
  //       text:
  //         "Since it's rich text, you can do things like turn a selection of text ",
  //     },
  //     { text: 'bold', bold: true },
  //     {
  //       text:
  //         ', or add a semantically rendered block quote in the middle of the page, like this:',
  //     },
  //   ],
  // },
  // {
  //   type: 'block-quote',
  //   children: [{ text: 'A wise quote.' }],
  // },
  // {
  //   type: 'paragraph',
  //   children: [{ text: 'Try it out for yourself!' }],
  // },
]

var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

function isUrl(string){
  if (typeof string !== 'string') {
    return false;
  }

  var match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  var everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainRE.test(everythingAfterProtocol) ||
      nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

export default ForumEditor