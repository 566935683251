import React from 'react'

// Vendor
import { Provider } from 'react-redux'

// Runic
import { RunicContext } from 'runic/context'

import core from 'runic/systems/core'
import authentication from 'runic/systems/authentication'
import runic from 'runic/systems/runic'
import model from 'runic/systems/model'
import entity from 'runic/systems/entity'
import action from 'runic/systems/action'
import ui from 'runic/systems/ui'
import upload from 'runic/systems/upload'

const defaultSystems = [
  // core,
  runic,
  authentication,
  // model,
  // entity,
  // action,
  // ui,
  // upload
]

import useRunicApp from 'runic/hooks/useRunicApp'

const RunicVeraBase = ({
  apps = [],
  systems = [],
  storeCreator,
  config,
  children
}) => {
  const { isReady, store, director } = useRunicApp(({
    apps,
    systems: [...defaultSystems, ...systems],
    storeCreator,
    config
  }))
  if (!isReady) return null

  return (
    <RunicContext.Provider value={director}>
      <Provider store={store}>
        {children}
      </Provider>
    </RunicContext.Provider>
  )

}

export default RunicVeraBase
