// Runic
import { createActions } from 'runic/core/redux'

const ACTIONS = {
  LOCAL: {
    toggleComposer: {},
    toggleMessageComposer: {}
  }
}

export default createActions(ACTIONS, 'rnxForum')
