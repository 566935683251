import React from 'react'

// Vendor
import { useField } from 'react-final-form'
import Select from 'react-select'
import { useTheme } from 'emotion-theming'
import { setIn, getIn } from 'final-form'

// Volcano
import { Box, Flex } from 'volcano'
import { Checkbox, Label } from 'volcano/components/Checkbox'


import { useFormState, useForm } from 'react-final-form'

import { RunicFormContext } from './RfForm'

const RfMultiCheckbox = ({
  name,
  field,
  options,
}) => {
  const theme = useTheme()

  const ctx = React.useContext(RunicFormContext)

  const opts = options || field.options

  const formState = useFormState({
    subscription: {
      values: true
    }
  })
  console.log(formState.values)

  const unMountRef = React.useRef()

  React.useEffect(() => {
    return () => unMountRef.current = true
  }, [])

  return (
    <Box>
      <Box>
        {opts.map((option, odx) => {
          return (
            <MultiCheckboxfield unMountRef={unMountRef} key={`${option.name}.${option.value}`} name={name} value={option.value} field={{ label: option.label }} />
          )
        })}
      </Box>
    </Box>
  )
}

const MultiCheckboxfield = ({
  name,
  field,
  value,
  unMountRef
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => {
      let error
      if (field?.required && (
        value === undefined ||
        value === null ||
        value == ''
      )) {
        error = 'Gerekli'
      }
      return error
    }
  })
  const valueRef = React.useRef()
  valueRef.current = input.value

  React.useEffect(() => {
    return () => {
      if (unMountRef.current) return
      if (valueRef.current && valueRef.current.length) {
        const newVal = [...valueRef.current]
        newVal.splice(valueRef.current.findIndex(x => x === value), 1)
        input.onChange(newVal)
      }
    }
  }, [])

  return (
    <Box sx={{
      width: '100%'
    }}>
    <Label>
        <Checkbox
          {...input}
          checked={input.value?.includes(value) ? 'checked' : null}
          onChange={e => {
            if (e.target.checked) input.onChange([...input.value, value])
            else {
              const newVal = [...input.value]
              newVal.splice(input.value.findIndex(x => x === value), 1)
              input.onChange(newVal)
            }
          }}
        />
        {field.label}
      </Label>
    {meta.touched && meta.error}
    </Box>
  )
}

export default RfMultiCheckbox