import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import useRunicListView from 'runic/hooks/useRunicListView'
import { Link, useParams, useResolvedPath, useLocation, Routes, Route } from 'runic-vera/router'
import Entity from 'runic-vera/components/Entity'

import CourseList from './CourseList'
import CourseDetail from './CourseDetail'
import CourseItemDetail from './CourseItemDetail'

const CategoryLink = ({
  to,
  children,
  end
}) => {
  const path = useResolvedPath(to)
  const location = useLocation()
  const active = end ? location.pathname == path.pathname : location.pathname.startsWith(path.pathname)
  return (
    <Link to={to} sx={{
      display: 'block',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary',
      px: 3,
      py: 2,
      mb: 2,
      ml: [0, null, null, 3],
    }} variants={{
      active: {
        backgroundColor: 'primary',
        color: '#FFF'
      }
    }}
      variant={active && 'active'}
    >
      {children}
    </Link>
  )
}


const CourseHome = props => {
  const source = useRunicListView('RxTag.RxTag.ListView', { filters: { 'RxTagKind.code_name': 'COURSES' }, opts: { 'sortBy': 'order', sortDirection: 'ASC' } })
  if (!source.ready) {
    return (
      <Box>
        Yükleniyor
      </Box>
    )
  }

  return (
    <Box sx={{
      // p: 4
    }}>
      <Flex justifyContent='space-between' sx={{
        flexDirection: ['column', null, null, 'row']
      }}>
        <Box sx={{
          fontWeight: 'bold',
          fontSize: 'xl',
          mb: 3,
          flex: ['1 1 auto', null, null, '0 0 150px']
        }} >
          Eğitimler
        </Box>

        <Flex sx={{
          flexWrap: 'wrap',
          flex: [null, null, null, '1 1 0'],
          flexWrap: ['wrap', null, null, 'no-wrap'],
          justifyContent: ['space-between', null, null, 'flex-end']
        }}>
          <CategoryLink to={`./`} end>Tamamı</CategoryLink>
          {source?.list?.items?.map(id => {
            return (
              <CategoryLink key={id} to={`kategori/${id}`} ><Entity id={id} modelName={'RxTag'} /></CategoryLink>
            )
          })}
        </Flex>
      </Flex>
      <Routes>
        <Route path="/*" element={<CourseList categories={source.list.items} />} />
        <Route path="kategori/:categoryId/*" element={<CourseList categories={source.list.items} />} />

        <Route path="kategori/:categoryId/egitim/detay/:courseId" element={<CourseDetail categories={source.list.items} />} />
        <Route path="egitim/detay/:courseId/*" element={<CourseDetail categories={source.list.items} />} />

        <Route path="kategori/:categoryId/egitim/detay/:courseId/ders_detay/:courseItemId" element={<CourseItemDetail categories={source.list.items} />} />
        <Route path="egitim/detay/:courseId/ders_detay/:courseItemId/*" element={<CourseItemDetail categories={source.list.items} />} />
        {/* <Route path="kategori/:categoryId/*" element={<ForumItems categories={categories}/>} />
          <Route path="b/:topicId/*" element={<ForumTopicDetail/>} /> */}
      </Routes>
    </Box>
  )
}

export default CourseHome