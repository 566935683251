import React from 'react'

// Vendor
import { useField } from 'react-final-form'


// Volcano
import { Box, Flex } from 'volcano'
import BareTextField from 'volcano/components/TextField'


const RfTextField = ({
  name,
  type,
  multiLine,
  field,
  placeholder,
  autoFocus
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => {
      let error
      if (field?.required && (
        value === undefined ||
        value === null ||
        value == ''
      )) {
        error = 'Gerekli'
      }
      return error
    }
  })

  if (multiLine) {
    return (
      <Box as='textarea' {...input} sx={{
        width: '100%',
        px: 2,
        py: 2
      }}/>
    )
  }

  return (
    <Box sx={{
      width: '100%'
    }}>
    {/* <Box placeholder={placeholder} as='input' type={type} required={field?.required} sx={{
      width: '100%',
      px: 2,
      py: 2
    }} {...input} autoFocus={autoFocus}/> */}
    <BareTextField requiredLabel={field?.requiredLabel} placeholder={placeholder} type={type} required={field?.required} label={field?.label} sx={{
      width: '100%',
      px: 2,
      py: 2
    }} {...input} autoFocus={autoFocus}/>
    {meta.touched && meta.error}
    </Box>
  )
}

export default RfTextField