import { hot } from 'react-hot-loader/root'
import React from 'react'

// Riva
import RunicVeraApp from 'runic-vera/RunicVeraApp'

// Project
import storeCreator from './core/store'
import theme from './core/theme'
import globalCss from './theme/globalCss'

import TenantIndex from './components/TenantIndex'

import tenantApp from 'elements/rc_app/tenant'

import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'
dayjs.locale('tr')
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(calendar)

import "react-datepicker/dist/react-datepicker.css"
import 'react-day-picker/lib/style.css'

import rnxForumApp from 'hayatinidegistir/apps/client/forum'

const apps = [
  tenantApp,
  rnxForumApp
]

import SimpleReactLightbox from "simple-react-lightbox"

const App = () => (
  <SimpleReactLightbox>
  <RunicVeraApp
    storeCreator={storeCreator}
    config={{
      isMultiTenant: false,
      runicPlatform: 'RUNIC_MAIN',
      tenantIndex: TenantIndex
    }}
    theme={theme}
    globalCss={globalCss}
    apps={apps}>
  </RunicVeraApp>
  </SimpleReactLightbox>
)

export default hot(App)
