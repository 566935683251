import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import { Link, NavLink, Routes, Route, useParams } from 'runic-vera/router'

import useRunicListView from 'runic/hooks/useRunicListView'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import Entity from 'runic-vera/components/Entity'
import Card from 'hayatinidegistir/components/Card'

import RfLongFormTextFieldPreview from 'runic-form/components/RfLongFormTextFieldPreview'


const CourseList = props => {
  const { categoryId } = useParams()
  const source = useRunicListView('RnxCourse.RnxCourse.ListView', { filters: { 'categoryId': categoryId }, opts: { 'sortBy': 'order', sortDirection: 'ASC', kind: 'by_category' } })
  if (!source.ready) {
    return (
      <Box>
        Yükleniyor
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={{
        variant: 'text.header',
        mt: 2
      }}>
        {categoryId ? <Entity id={categoryId} modelName={'RxTag'} sx={{
          variant: 'text.header',
        }} /> : 'Tamamı'}
      </Box>

      {source.empty && 'Ders yok.'}

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -2,
        mt: 3
      }}>
        {source?.list?.items?.map(id => {
          return (
            <Link to={`egitim/detay/${id}`} sx={{
              mx: 2,
              border: '1px solid #FFF',
              borderColor: 'primary',
              // flex: ['1 1 100%', null, null, '0 0 150px'],
              flex: ['1 1 100%', null, null, '1 1 100%'],
              mb: 3
            }} key={id}>
              <Card sx={{
                p: 2
              }}>
              <Entity id={id} modelName={'RnxCourse'}>
                {entity => (
                  <Flex>
                    <Box as='img' src={`${__ASSET_URL__}${entity.data.image}`} sx={{
                      width: '100%',
                      flex: '0 0 200px',
                      my: 3
                    }} />
                    <Box sx={{
                      ml: 3,
                      my: 2
                    }}>
                      <Box sx={{
                        variant: 'text.header',
                        fontSize: 'l'
                      }}>
                        {entity.name}
                      </Box>

                      {entity.data?.text && <Box sx={{
                      }}>
                        <RfLongFormTextFieldPreview content={entity.data.text}/>
                      </Box>}
                    </Box>
                  </Flex>
                )}
              </Entity>
              </Card>
            </Link>
          )
        })}
      </Flex>
    </Box>
  )
}

export default CourseList