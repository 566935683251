import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import { Link, NavLink, Routes, Route } from 'runic-vera/router'

import CourseMgmt from './course/pages/CourseMgmt'
import ForumMgmt from './forum/pages/ForumMgmt'
import GalleryMgmt from './gallery/pages/GalleryMgmt'
import AnnouncementMgmt from './announcement/pages/AnnouncementMgmt'
import JournalMgmt from './journal/pages/JournalMgmt'
import UserMgmt from './user/pages/UserMgmt'
import AuthMgmt from './authorization/pages/AuthMgmt'


const AdminIndex = props => {
  return (
    <Routes>
      <Route path="dersler/*" element={<CourseMgmt />} />
      <Route path="forum/*" element={<ForumMgmt />} />
      <Route path="galeri/*" element={<GalleryMgmt />} />
      <Route path="duyuru/*" element={<AnnouncementMgmt />} />
      <Route path="gunlukler/*" element={<JournalMgmt />} />
      <Route path="kullanicilar/*" element={<UserMgmt />} />
      <Route path="yetkilendirme/*" element={<AuthMgmt />} />
    </Routes>
  )
}

export default AdminIndex