import React from 'react'

// Vendor
import dayjs from 'dayjs'
import { useField } from 'react-final-form'

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'
import Icon from 'volcano/components/Icon'

// Runic
import useRunicSource from 'runic/hooks/useRunicSource'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import { Link, Routes, Route, useParams } from 'runic-vera/router'

//
import Card from 'hayatinidegistir/components/Card'
import { AdminJournal } from 'hayatinidegistir/apps/client/components/Journal'


const JournalMgmt = props => {
  return (
    <Box>
      <Box sx={{
        variant: 'text.pageHeader'
      }}>
        Günlükler
      </Box>

      <Routes>
        <Route path='/' element={<DayJournals />} />
        <Route path='detay/:journalId/:journalDate' element={<JournalDetail />} />
      </Routes>
    </Box>
  )
}

const DayJournals = props => {
  const [date, _setDate] = React.useState(() => new Date().toISOString())
  const source = useRunicSource('RnxJournal.AdminJournal.updated_journal_list', { params: { dateTime: date } })
  const setDate = (v) => {
    _setDate(v)
  }

  React.useEffect(() => {
    source.refresh()
  }, [date])

  if (!source.ready) return null

  return (
    <Box>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 3
      }}>
        <Flex sx={{
          alignItems: 'center',
        }}>
          <ButtonIcon iconName='chevron-left' onClick={() => setDate(dayjs(date).subtract(1, 'day').toISOString())} />
          <Box>
            {dayjs(date).format('D MMMM, YYYY')}
          </Box>
          {dayjs().diff(dayjs(date), 'day') > 0 && <ButtonIcon iconName='chevron-right' onClick={() => setDate(dayjs(date).add(1, 'day').toISOString())} />}
        </Flex>
      </Flex>

      <Card sx={{
        px: 3
      }}>
        {source.data.journalList.map(journal => {
          return (
            <Link to={`detay/${journal.id}/${date}`} sx={{
              justifyContent: 'space-between',
              display: 'flex',
              my: 3
            }}>
              <Box>
                {journal.owner_rc_entity.identifier}
              </Box>

              <Box>
                {dayjs(journal.datetime_last_entry).add(3, 'hours').format('D MMMM, YYYY, HH:MM')}
              </Box>
            </Link>
          )
        })}
      </Card>
    </Box>
  )
}

const JournalDetail = props => {
  const { journalId, journalDate } = useParams()
  return (
    <Box>
      <Link to='../../../'>Geri Dön</Link>
      <AdminJournal journalId={journalId} journalDate={journalDate} />
    </Box>
  )
}

export default JournalMgmt