import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import RfSingleAction from 'runic-form/components/RfSingleAction'
import useRunicSource from 'runic/hooks/useRunicSource'

import RfRichTextFieldView from 'runic-form/components/RfRichTextFieldView'
import RfLongFormTextFieldPreview from 'runic-form/components/RfLongFormTextFieldPreview'


import Card from 'hayatinidegistir/components/Card'


const TopAnnouncement = props => {
  const source = useRunicSource('RxFeed.Feed.items', { params: { codeName: 'TOP', active: true } })
  if (!source.ready) return null
  if (!source.data.feedItems?.length) return null

  return (
    <Box>
      <Box sx={{
        variant: 'text.pageHeaderText',
      }}>
        Duyurular
      </Box>
      {source.data.feedItems.map(item => {
        return (
          <Announcement item={item} key={item.id}/>
        )
      })}
    </Box>
  )
}

const Announcement = ({
  item
}) => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <Box sx={{
      my: 3,
      pl: 3,
      // bg: 'accent',
      // color: '#FFF',
      borderLeft: '3px solid #FFF',
      borderLeftColor: 'accent',
    }}>
      <Box sx={{
        variant: 'text.header'
      }}>
        {item.data.name}
      </Box>

      {item.data.image && (
        <Box as='img' src={`${__ASSET_URL__}${item.data.image}`} sx={{
          width: '40%',
                    display: 'block'
        }} />
      )}

{item.data.video && (
  <Box as='video' src={`${__ASSET_URL__}${item.data.video}`} controls sx={{
    width: '40%',
                    display: 'block'
  }}>
    <source src={`${__ASSET_URL__}${item.data.video}`}
      type="video/mp4"></source>
  </Box>
      )}

      <Box>
        <RfRichTextFieldView content={item.data.description} />
      </Box>

      {item.data.text && <Box as='a' color='primary' onClick={() => setExpanded(v => !v)}>
        Detaylar
      </Box>}

      {expanded && <Box sx={{
        mt: 2
      }}>
        <RfLongFormTextFieldPreview content={item.data.text} />

      </Box>}
    </Box>
  )
}

export default TopAnnouncement