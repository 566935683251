import React from 'react';

// Vendor
import {
  Route,
  Routes,
} from 'runic-vera/router'

// Self
import AuthenticationLogin from './AuthenticationLogin'
import AuthenticationLogout from './AuthenticationLogout'
import AuthenticationForgot from './AuthenticationForgot'
import AuthenticationReset from './AuthenticationReset'
// import AuthenticationEmailVerify from './AuthenticationEmailVerify'


const AuthenticationBase = props => {

  return(
    <Routes>
      <Route path={`login`} element={<AuthenticationLogin/>}/>
      <Route path={`login/:rcTenantCode`} element={<AuthenticationLogin/>}/>
      <Route path={`logout`} element={<AuthenticationLogout/>}/>
      <Route path={`forgot`} element={<AuthenticationForgot/>}/>
      <Route path={`reset/:token`} element={<AuthenticationReset/>}/>
      {/*
      <Route path={`verify/:token`} element={<AuthenticationEmailVerify/>}/> */}
    </Routes>
  )
}

export default AuthenticationBase
