// Runic
import { createActions } from 'runic/core/redux'

export default createActions({
  RCR_SOURCE: {
    rcrRivaSetup: {component: 'RcApp', source: 'RcrRiva'},
    rcrRivaTenantSetup: {component: 'RcApp', source: 'RcrRiva'},
    bootstrap: {component: 'RcApp', source: 'RcrRivaUi'},
    rcrRivaGetTenantList: {component: 'RcApp', source: 'RcrRiva'},
  },
  LOCAL: {
    runicBootstrap: {},
    rcrUiBootstrap: {},
    rcrActivateRcTenant: {},
    rcrAppVersionUpdate: {},
    resetUserStatus: {}
  }
}, 'core')
