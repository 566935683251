import React from 'react'

// Vendor
import dayjs from 'dayjs'
import { useField } from 'react-final-form'
import { SRLWrapper, useLightbox  } from "simple-react-lightbox"

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'
import Icon from 'volcano/components/Icon'

// Runic
import useRunicSource from 'runic/hooks/useRunicSource'
import RfSingleAction from 'runic-form/components/RfSingleAction'

//
import Card from 'hayatinidegistir/components/Card'


const Journal = props => {
  const [date, _setDate] = React.useState(() => new Date().toISOString())
  const source = useRunicSource('RnxJournal.Journal.today', { params: { dateTime: date } })
  const setDate = (v) => {
    _setDate(v)
    source.refresh()
  }
  if (!source.ready) return null

  return (
    <Box sx={{
      mb: 4,
    }}>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 3
      }}>
        <Box sx={{
          variant: 'text.pageHeaderText',
          // color: 'accent'
        }}>
          Günlüğüm
        </Box>

        <Flex sx={{
          alignItems: 'center',
        }}>
          <ButtonIcon iconName='chevron-left' onClick={() => setDate(dayjs(date).subtract(1, 'day').toISOString())} />
          <Box>
            {dayjs(date).format('D MMMM, YYYY')}
          </Box>
          {dayjs().diff(dayjs(date), 'day') > 0 && <ButtonIcon iconName='chevron-right' onClick={() => setDate(dayjs(date).add(1, 'day').toISOString())} />}
        </Flex>
      </Flex>
      <Measurements data={source.data} date={date} refresh={source.refresh} />
      <Box mt={3} />
      <Foods data={source.data} date={date} refresh={source.refresh} />
      <Box mt={3} />
      <Exercises data={source.data} date={date} refresh={source.refresh} />
      <Box mt={3} />
      <Thoughts data={source.data} date={date} refresh={source.refresh} />
      <Box mt={3} />
      <Photos data={source.data} date={date} refresh={source.refresh} />
      <Box mt={3} />
      {source?.data?.journal?.id && <JournalComments journalId={source.data.journal.id} date={date} />}
    </Box >
  )
}

export const AdminJournal = ({
  journalId,
  journalDate
}) => {
  const [date, _setDate] = React.useState(journalDate)
  const source = useRunicSource('RnxJournal.AdminJournal.user_today', { params: { dateTime: date, journalId } })
  const setDate = (v) => {
    _setDate(v)
    source.refresh()
  }
  if (!source.ready) return null

  return (
    <Box sx={{
      mb: 4,
    }}>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 3
      }}>
        <Box sx={{
          variant: 'text.pageHeaderText',
          // color: 'accent'
        }}>
          {source.data.journal.owner_rc_entity.identifier}
        </Box>

        <Flex sx={{
          alignItems: 'center',
        }}>
          <ButtonIcon iconName='chevron-left' onClick={() => setDate(dayjs(date).subtract(1, 'day').toISOString())} />
          <Box>
            {dayjs(date).format('D MMMM, YYYY')}
          </Box>
          {dayjs().diff(dayjs(date), 'day') > 0 && <ButtonIcon iconName='chevron-right' onClick={() => setDate(dayjs(date).add(1, 'day').toISOString())} />}
        </Flex>
      </Flex>
      <Measurements data={source.data} date={date} refresh={source.refresh} allowCreate={false} />
      <Box mt={3} />
      <Foods data={source.data} date={date} refresh={source.refresh} allowCreate={false} />
      <Box mt={3} />
      <Exercises data={source.data} date={date} refresh={source.refresh} allowCreate={false} />
      <Box mt={3} />
      <Thoughts data={source.data} date={date} refresh={source.refresh} allowCreate={false} />
      <Box mt={3} />
      <Photos data={source.data} date={date} refresh={source.refresh} allowCreate={false} />
      <Box mt={3} />
      <JournalComments journalId={source.data.journal.id} date={date} />
    </Box >
  )
}

const JournalComments = ({
  journalId,
  date
}) => {
  const source = useRunicSource('RnxJournal.AdminJournal.comments', { params: { dateTime: date, journalId } })
  const [create, setCreate] = React.useState(false)
  console.log('journal', source)

  return (
    <Box>
      <Card sx={{
        p: 3
      }}>
        <Flex sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <Box sx={{ color: 'accent', fontWeight: 'bold', fontSize: 'l' }}>Yorumlar</Box>
        </Flex>

        {source.data?.comments?.map(comment => {
          return (
            <Box key={comment.id} sx={{
              my: 3
            }}>
              <Box>
                <Box sx={{
                  fontWeight: 'bold'
                }}>
                {comment.rc_user.name}
                </Box>
                <Box sx={{
                  color: 'dark8'
                }}>
                {dayjs(comment.datetime_created).add(3, 'hours').fromNow()}
                </Box>
              </Box>
              <Box sx={{
                whiteSpace: 'pre-wrap',
                lineBreak: 'anywhere'
              }}>
                {comment.text}
              </Box>
            </Box>
          )
        })}

        <Box px={0} as='a' onClick={() => setCreate(v => !v)} sx={{
          color: 'primary',
          fontWeight: 'bold',
          display: 'block',
          mt: 3
        }}>
          <Icon icon='circle-plus' width={24}
            height={24} /> <span>Yorum Ekle</span>
        </Box>

        {create && <RfSingleAction
          onSuccess={() => {
            source.refresh()
            setCreate(false)
          }}
          initialValues={{
            journalId,
            date,
          }} header='Yorum' actionKey='RnxJournal.CreateComment' fields={[
            {
              label: 'Yorum',
              name: 'comment',
              kind: 'textArea',
            },
          ]} formOnly />}
      </Card>
    </Box>
  )
}

const Measurements = ({
  data,
  date,
  refresh,
  allowCreate = true
}) => {
  const [create, setCreate] = React.useState(false)
  return (
    <Card sx={{
      p: 3
    }}>
      <Box sx={{ color: 'accent', fontWeight: 'bold', mb: 3, fontSize: 'l' }}>Ölçülerim</Box>

      {data?.itemsByKind?.['MEASUREMENTS'] && Object.entries(data.itemsByKind['MEASUREMENTS'].journal.data.values).map(([k, v]) => {
        let name
        if (k == 'BUST') name = 'Göğüs'
        else if (k == 'WAIST') name = 'Bel'
        else if (k == 'HIPS') name = 'Basen'
        else if (k == 'WEIGHT') name = 'Kilo'
        return (
          <Box key={k} sx={{ mb: 3 }}>
            <Box as='span'>{name}</Box>
            <Box as='span' pl={4}>{v}</Box>
          </Box>
        )
      })}
      {allowCreate && <Box px={0} as='a' onClick={() => setCreate(v => !v)} sx={{
        color: 'primary',
        fontWeight: 'bold',
        display: 'block',
        mt: 3
      }}>
        <Icon icon='circle-plus' width={24}
          height={24} /> <span>Ekle/Düzenle</span>
      </Box>}

      {create && <AddMeasuements data={data} close={() => {
        setCreate(v => !v)
        refresh()
      }} date={date} />}
    </Card>
  )
}

const AddMeasuements = ({
  close,
  data,
  date
}) => {
  const initialValues = data?.itemsByKind?.['MEASUREMENTS']?.journal.data.values

  return (
    <RfSingleAction
      parseData={data => {
        return ({
          kind: 'MEASUREMENTS',
          data,
          date
        })
      }}
      onSuccess={() => {
        close()
      }}
      initialValues={initialValues} formOnly header='Ölçülerim' actionKey='RnxJournal.CreateOrUpdateJournalLog' fields={[
        {
          label: 'Kilo',
          name: 'WEIGHT',
          kind: 'number',
        },

        {
          label: 'Göğüs',
          name: 'BUST',
          kind: 'number',
        },

        {
          label: 'Bel',
          name: 'WAIST',
          kind: 'number',
        },
        {
          label: 'Basen',
          name: 'HIPS',
          kind: 'number',
        },
      ]}>
      <Button label='Vazgeç' fullWidth sx={{ mt: 2 }} onClick={close} />
    </RfSingleAction>
  )
}

const Foods = ({
  data,
  date,
  refresh,
  allowCreate = true
}) => {
  const [create, setCreate] = React.useState(false)
  return (
    <Card sx={{
      p: 3
    }}>
      <Box sx={{ color: 'accent', fontWeight: 'bold', mb: 3, fontSize: 'l' }}>Yediklerim</Box>

      {data?.itemsByKind?.['FOOD'] && Object.entries(data.itemsByKind['FOOD'].journal.data.values).sort((a, b) => {
        if (a[0] > b[0]) return 1
        if (b[0] > a[0]) return -1
        return 0
      }).map(([k, v]) => {
        return (
          <Flex key={k} sx={{
            alignItems: 'center',
            mb: 3,
          }}>
            <Box flex='0 0 120px' sx={{
              border: '1px solid #0D7C74',
              display: 'inline-block',
              borderRadius: 1,
              p: 2,
              backgroundColor: 'primary',
              color: '#FFF',
              width: 120,
              textAlign: 'center',
              mr: 2
            }}>
              {k}
            </Box>
            <Box sx={{
              whiteSpace: 'pre-wrap',
              lineBreak: 'anywhere'
            }}>{v}</Box>
          </Flex>
        )
      })}
      {allowCreate && <Box px={0} as='a' onClick={() => setCreate(v => !v)} sx={{
        color: 'primary',
        fontWeight: 'bold',
        display: 'block',
        mt: 3
      }}>
        <Icon icon='circle-plus' width={24}
          height={24} /> <span>Ekle</span>
      </Box>}

      {create && <AddFood data={data} close={() => {
        setCreate(v => !v)
        refresh()
      }} date={date} />}
    </Card>
  )
}

const AddFood = ({
  date,
  close,
  data
}) => {

  // const initialValues = data?.itemsByKind?.['MEASUREMENTS']?.journal.data.values
  const initialValues = {}

  const dates = [
    '06:00 - 08:00',
    '08:00 - 10:00',
    '10:00 - 12:00',
    '12:00 - 14:00',
    '14:00 - 16:00',
    '16:00 - 18:00',
    '18:00 - 20:00',
    '20:00 - 22:00',
    '22:00 - 00:00',
  ]

  return (
    <RfSingleAction
      parseData={data => {
        return ({
          kind: 'FOOD',
          data: {
            [data.hour]: data.value
          },
          date
        })
      }}
      onSuccess={() => {
        close()
      }}
      initialValues={initialValues} formOnly header='Yediklerim' actionKey='RnxJournal.CreateOrUpdateJournalLog' fields={[
        {
          label: 'Saat',
          name: 'hour',
          kind: 'custom',
          element: <SelectField items={dates} name='hour' />
        },

        {
          label: 'Yediklerim',
          name: 'value',
          kind: 'textArea',
        },
      ]} />
  )
}

const Exercises = ({
  data,
  date,
  refresh,
  allowCreate = true
}) => {
  const [create, setCreate] = React.useState(false)

  return (
    <Card sx={{
      p: 3
    }}>
      <Box sx={{ color: 'accent', fontWeight: 'bold', mb: 3, fontSize: 'l' }}>Egzersizlerim</Box>

      <Box mb={3} sx={{
        whiteSpace: 'pre-wrap'
      }}>
        {data?.itemsByKind?.['EXERCISE'] && data.itemsByKind['EXERCISE'].journal.data.values.exercise}
      </Box>
      {allowCreate && <Box px={0} as='a' onClick={() => setCreate(v => !v)} sx={{
        color: 'primary',
        fontWeight: 'bold',
        display: 'block',
        mt: 3
      }}>
        <Icon icon='circle-plus' width={24}
          height={24} /> <span>Ekle/Düzenle</span>
      </Box>}

      {create && <AddExercise data={data} close={() => {
        setCreate(v => !v)
        refresh()
      }} date={date} />}
    </Card>
  )
}


const AddExercise = ({
  close,
  data,
  date
}) => {
  const initialValues = data?.itemsByKind?.['EXERCISE']?.journal.data.values

  return (
    <RfSingleAction
      sx={{
        width: '100%'
      }}
      parseData={data => {
        return ({
          kind: 'EXERCISE',
          data,
          date,
        })
      }}
      onSuccess={() => {
        close()
      }}
      initialValues={initialValues} formOnly header='Egzersizlerim' actionKey='RnxJournal.CreateOrUpdateJournalLog' fields={[
        {
          label: 'Egzersiz',
          name: 'exercise',
          kind: 'textArea',
        },
      ]} />
  )

}

const Thoughts = ({
  date,
  refresh,
  data,
  allowCreate = true
}) => {
  const [create, setCreate] = React.useState(false)

  return (
    <Card sx={{
      p: 3
    }}>
      <Box sx={{ color: 'accent', fontWeight: 'bold', mb: 3, fontSize: 'l' }}>Düşüncelerim, Hissettiklerim</Box>

      {data?.itemsByKind?.['MOOD'] && Object.entries(data.itemsByKind['MOOD'].journal.data.values).map(([dt, val]) => {
        return (
          <Box key={dt} sx={{ mb: 3 }}>
            <Box flex='0 0 120px' sx={{
              position: 'relative',
              pl: 4,
              mb: 3,
              '&:before': {
                content: "''",
                borderRadius: 100,
                backgroundColor: 'primary',
                width: 10,
                height: 10,
                position: 'absolute',
                display: 'block',
                top: '3px',
                left: 0,
                lineHeight: 1.3
              }
            }}>
              {dayjs(dt).format('HH:MM')}
            </Box>
            <Box pl={4}>{val}</Box>
          </Box>
        )
      })}
      {allowCreate && <Box px={0} as='a' onClick={() => setCreate(v => !v)} sx={{
        color: 'primary',
        fontWeight: 'bold',
        display: 'block',
        mt: 3
      }}>
        <Icon icon='circle-plus' width={24}
          height={24} /> <span>Ekle/Düzenle</span>
      </Box>}

      {create && <AddThought data={data} close={() => {
        setCreate(v => !v)
        refresh()
      }} date={date} />}
    </Card>
  )
}

const AddThought = ({
  date,
  close,
  data
}) => {
  const initialValues = {}

  return (
    <RfSingleAction
      parseData={data => {
        return ({
          kind: 'MOOD',
          data: {
            [new Date().toISOString()]: data.value
          },
          date
        })
      }}
      onSuccess={() => {
        close()
      }}
      initialValues={initialValues} formOnly header='Düşüncelerim, Hissettiklerim' actionKey='RnxJournal.CreateOrUpdateJournalLog' fields={[
        {
          label: 'Düşüncelerim, Hissettiklerim',
          name: 'value',
          kind: 'textArea',
        },
      ]} />
  )
}

const Photos = ({
  date,
  refresh,
  data,
  allowCreate = true
}) => {
  const [create, setCreate] = React.useState(false)
  const {openLightbox} = useLightbox()

  return (
    <Card sx={{
      p: 3
    }}>
      <Box sx={{ color: 'accent', fontWeight: 'bold', mb: 3, fontSize: 'l' }}>Fotoğraflarım</Box>
      {data?.itemsByKind?.['PHOTOS'] && data.itemsByKind['PHOTOS'].journal.data.images && <SRLWrapper images={data.itemsByKind['PHOTOS'].journal.data.images.map(photo => ({src: `${__ASSET_URL__}${photo}`}))} options={{
        buttons: {
          // backgroundColor: 'rgba(30,30,36,0.8)',
          // iconColor: 'rgba(255, 255, 255, 0.8)',
          // iconPadding: '5px',
          showAutoplayButton: false,
          showCloseButton: true,
          showDownloadButton: false,
          showFullscreenButton: false,
          showNextButton: true,
          showPrevButton: true,
          showThumbnailsButton: false,
          // size: '40px'
        },
        settings: {
          disablePanzoom: true,
          disableWheelControls: true,
        }
      }} />}

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -2
      }}>
        {data?.itemsByKind?.['PHOTOS'] && data.itemsByKind['PHOTOS'].journal.data.images.map((photo, dx) => {
          return (
            <Box key={dx} onClick={() => openLightbox(dx)}>
              <Box as='img' src={`${__ASSET_URL__}${photo}`} sx={{
                width: 200,
                mx: 2
              }} />
            </Box>
          )
        })}
      </Flex>
      {allowCreate && <Box px={0} as='a' onClick={() => setCreate(v => !v)} sx={{
        color: 'primary',
        fontWeight: 'bold',
        display: 'block',
        mt: 3
      }}>
        <Icon icon='circle-plus' width={24}
          height={24} /> <span>Ekle/Düzenle</span>
      </Box>}

      {create && <AddPhoto data={data} close={() => {
        setCreate(v => !v)
        refresh()
      }} date={date} />}
    </Card>
  )
}

const AddPhoto = ({
  date,
  close,
  data
}) => {
  const initialValues = {
    date
  }

  return (
    <RfSingleAction
      upload
      onSuccess={() => {
        close()
      }}
      initialValues={initialValues} buttonText='Kaydet' formOnly actionKey='RnxJournal.CreatePhotoLog' fields={[
        {
          label: 'Fotoğraflarım',
          name: 'photo',
          kind: 'contentImage',
        },
      ]} />
  )
}

export const SelectField = React.forwardRef((props, ref) => {
  const { name, items, ...rest } = props
  const { input, meta } = useField(name)
  // console.log('- test', input.name, input.value)
  console.log(input, meta)
  return (
    <Box mb={3}>
      <Flex flexWrap='wrap' mx={-2} >
        {items.map((item, dx) => (
          <Box key={dx} flex='0 0 120px' textAlign='center' as='a' onClick={() => input.onChange(item)} sx={{
            border: '1px solid #0D7C74',
            borderRadius: 1,
            p: 2,
            mx: 2,
            mb: 2
          }} variants={{
            selected: {
              backgroundColor: 'primary',
              color: '#FFF'
            },

            default: {
              backgroundColor: '#F2F9F8',
            }
          }} variant={item === input.value ? 'selected' : 'default'}>
            {item}
          </Box>
        ))}
      </Flex>
    </Box>
  )
})


export default Journal