import React from 'react'

// Vendor
import { useFieldArray } from 'react-final-form-arrays'
import { useFormState, useForm } from 'react-final-form'
import dotProp from 'dot-prop'

// Volcano
import { Flex, Box } from 'volcano'
import Icon from 'volcano/components/Icon'

// Blockreactor
import RfRichTextField from './RfRichTextField'
// import RfRichTextField from 'blockreactor/components/fields/RfRichTextField'
import RfTextField from './RfTextField'
import RfImageField from './RfImageField'
import RfDateField from './RfDateField'
import RfDateTimeField from './RfDateTimeField'
import RfDropdownField from './RfDropdownField'
import RfRecurrenceField from './RfRecurrenceField'
import RfLookupField from './RfLookupField'
import RfBooleanField from './RfBooleanField'
import RfMultiCheckbox from './RfMultiCheckbox'
import RfMultiCheckboxGroup from './RfMultiCheckboxGroup'
import RfImageSelectField from './RfImageSelectField'
import RfFileSelectField from './RfFileSelectField'
import RfContentImageField from './RfContentImageField'
import RfLongFormTextField from './RfLongFormTextField'
import RfVideoField from './RfVideoField'
import RfGraphLookupField from './RfGraphLookupField'

const initialArrayValue = [{}]

const RfArrayField = ({ field, group, FieldComponent, ...rest }) => {
  const [collapsed, collapsedSet] = React.useState(false)
  const toggleCollapse = React.useCallback(() => collapsedSet(v => !v))
  const { fields } = useFieldArray(field.name, {
    initialValue: field.defaultCount ? initialArrayValue : undefined
  })
  // console.log('fields', fields)
  return (
    <Box sx={{
      mt: 3
    }}>
      <Box sx={{
        // fontWeight: 'bold',
        color: 'primary8',
        mb: 2,
        ...rest?.labelSx
      }}>
        <Flex sx={{
          justifyContent: 'space-between'
        }}>
          <Box>
            {field.label || field.name}
          </Box>
          <Flex>
          <Box as='a' sx={{
            mx: 1
          }} onClick={toggleCollapse}><Icon color='primary8' name={collapsed ? 'chevron-last-down' : 'chevron-last-up'} /></Box>

<Box as='a' sx={{
            mx: 1
          }} onClick={() => fields.push(group ? {} : null)}><Icon color='primary8' name={'circle-plus'} /></Box>
          </Flex>
        </Flex>
      </Box>
      {!collapsed ? fields.map((name, dx) => {
        return (
          <Box sx={{
            p: 3,
            border: '1px solid #000',
            borderColor: 'primary',
            mb: 3
          }} key={dx}>
            <Flex sx={{
              mb: 2,
              justifyContent: 'space-between'
            }}>
              <Box>
                ( {dx + 1} )
              </Box>
              <Box>
                {dx !== 0 && <Box as='a' sx={{
                  mx: 1
                }} onClick={() => fields.swap(dx, dx - 1)}><Icon color='primary8' name='chevron-up' /></Box>}
                {dx !== fields.length - 1 && <Box as='a' sx={{
                  mx: 1
                }} onClick={() => fields.swap(dx, dx + 1)}><Icon color='primary8' name='chevron-down' /></Box>}
                <Box as='a' sx={{
                  mx: 1
                }} onClick={() => fields.remove(dx)}><Icon color='primary8' name='circle-remove' /></Box>
              </Box>
            </Flex>
            {group ? (
              field.fields.map((f, fdx) => {
                return (
                  <FieldComponent FieldComponent={FieldComponent} field={{ ...f, name: `${name}.${f.name}`, parentPath: name }} key={fdx} {...rest} />
                )
              })
            ) : (
                <FieldComponent FieldComponent={FieldComponent} field={{ ...field, name: name, list: false, parentPath: name }} {...rest}/>
              )}
          </Box>
        )
      }) : fields.map((name, dx) => {
        return (
          <CollapsedField field={field} group={group} name={name} dx={dx}/>
        )
      })}
      <Box as='button' sx={{
            border: '1px dashed #000',
            borderColor: 'primary',
            mb: 3,
            px: 3,
            py: 2,
            width: '100%'
      }} onClick={(e) => {
        e.preventDefault()
        fields.push(group ? {} : null)
      }}>+ {field.label}</Box>
    </Box>
  )
}
import { setIn, getIn } from 'final-form'

const CollapsedField = ({
  field, name, group, dx
}) => {
  const formState = useFormState()
  const fieldKey = group ? `${name}.${field.fields[0].name}` : name
  console.log(formState.values, fieldKey)
  return (
    <Box sx={{
      my: 2
    }}>
      {`${dx + 1}. `}<Box as='span' sx={{fontWeight: 'bold'}}>{field.fields[0].label}</Box>: {getIn(formState.values, fieldKey) || '?'}

    </Box>
  )
}

const ConnectedRfField = ({ field, FieldComponent, ...rest }) => {
  // console.log('connected', field)
  const form = useForm()
  const formState = useFormState()

  let showIf = true

  if (field.showIf) {
    field.showIf.forEach(fieldShowIf => {
      const fieldName = fieldShowIf.field
      const fullFieldName = field.parentPath ? `${field.parentPath}.${fieldName}` : fieldName
      const value = getIn(formState.values, fullFieldName)
      // console.log('\n\n', fullFieldName, value, formState.values)
      if (fieldShowIf.comparison === 'EQUAL' || fieldShowIf.comparison === 'EQUALS') {
        if (value !== fieldShowIf.value) showIf = false
      }

      if (fieldShowIf.comparison === 'NOT_EQUAL' || fieldShowIf.comparison === 'NOT_EQUALS') {
        if ((value === undefined) || (value === fieldShowIf.value)) showIf = false
      }

      if (fieldShowIf.comparison === 'EXIST' || fieldShowIf.comparison === 'EXISTS') {
        if ((value === undefined) || (value === null)) showIf = false
      }
      // if (!showIf) console.log(field.name, showIf, value, fullFieldName)
    })
  }

  React.useEffect(() => {
    if (!field.showIf) return
    if (showIf) return

    // const fullFieldName = `${field.parentPath}.${field.name}`
    const splitName = field.name.split('.')
    const parentName = splitName.slice(0, splitName.length - 1).join('.')
    const fieldName = splitName[splitName.length - 1]
    const parentValue = getIn(formState.values, parentName)
    const newParentValue = setIn(parentValue || {}, fieldName, undefined)
    console.log('changing', field.name, parentName, parentValue, newParentValue)
    form.change(parentName, newParentValue)
  }, [showIf])

  if (!showIf) return null

  return (<FieldComponent field={{...field, showIf: null}} {...rest}/>)

}

const RfField = ({ field, FieldComponent = RfField, autoFocus, sx, ...rest }) => {
  // console.log(field)
  // console.log('fieldContent', fieldContent)
  const [descriptionOpen, descriptionOpenSet] = React.useState(false)
  if (field.showIf) return <ConnectedRfField field={field} autoFocus={autoFocus} FieldComponent={FieldComponent} {...rest}/>

  if (field.kind === 'group') {
    if (field.list) {
      return <RfArrayField field={field} group FieldComponent={FieldComponent} {...rest} />
    } else {
      let content

      if (field.display?.flex) {
        content = (
          <Flex sx={{
            flexWrap: 'wrap',
            mx: -2,
            alignItems: field.display.alignItems || 'center'
          }}>
            {field.fields.map((f, dx) => (
              <Box key={dx} sx={{
                flex: '1 1 40%',
                mx: 2
              }}>
                <FieldComponent field={{ ...f, name: `${field.name}.${f.name}`, parentPath: field.name }} FieldComponent={FieldComponent} {...rest} />
              </Box>
            ))}
          </Flex>
        )
      } else {
        content = field.fields.map((f, dx) => <FieldComponent field={{ ...f, name: `${field.name}.${f.name}`, parentPath: field.name }} key={dx} FieldComponent={FieldComponent} {...rest} />)
      }
      return (
        <Box sx={{
          py: 3
        }}>
          <Box sx={{
        // fontWeight: 'bold',
        color: 'primary8',
        mb: 2,
        fontWeight: 'bold',
        ...rest?.labelSx
      }}>
        {field.label}
      </Box>
          <Box sx={{
            ml: 2
          }}>
          {content}
          </Box>
        </Box>
      )
    }

  } else if (field.list) {
    return <RfArrayField field={field} FieldComponent={FieldComponent} {...rest} />
  }
  // console.log(`${id}.${field.name}`)

  let fieldElem

  if (field.kind === 'richText') fieldElem = <RfRichTextField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'text') fieldElem = <RfTextField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'password') fieldElem = <RfTextField type='password' name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'textArea') fieldElem = <RfTextField name={field.name} multiLine field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'markdownRichText') fieldElem = <RfTextField name={field.name} multiLine field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'image') fieldElem = <RfImageField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'imageSelect') fieldElem = <RfImageSelectField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'contentImage') fieldElem = <RfContentImageField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'contentImageUpload') fieldElem = <RfContentImageField name={field.name} field={field} autoFocus={autoFocus} upload={true}/>
  else if (field.kind === 'file') fieldElem = <RfFileSelectField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'fileSelect') fieldElem = <RfFileSelectField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'date') fieldElem = <RfDateField name={field.name}field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'dateTime') fieldElem = <RfDateTimeField name={field.name}field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'dropdown') fieldElem = <RfDropdownField name={field.name} options={field.options} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'number') fieldElem = <RfTextField name={field.name} type='number' field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'recurrence') fieldElem = <RfRecurrenceField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'relationship') fieldElem = <RfLookupField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'graphEntity') fieldElem = <RfGraphLookupField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'boolean') fieldElem = <RfBooleanField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'checklist') fieldElem = <RfMultiCheckbox name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'multicheckbox') fieldElem = <RfMultiCheckboxGroup name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'longFormText') fieldElem = <RfLongFormTextField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'video') fieldElem = <RfVideoField name={field.name} field={field} autoFocus={autoFocus}/>
  else if (field.kind === 'videoUpload') fieldElem = <RfVideoField name={field.name} field={field} autoFocus={autoFocus} upload={true}/>
  else if (field.kind === 'custom') fieldElem = React.cloneElement(field.element, {name: field.name, field: field})
  else {
    console.warn('field kind unrecognized', field.kind)
  }

  return (
    <Box sx={{
      mb: 3,
      ...sx
    }}>
      <Flex sx={{
        justifyContent: 'space-between'
      }}>
      <Flex sx={{
        // fontWeight: 'bold',
        color: 'primary8',
        mb: 2,
        alignItems: 'center',
        ...rest?.labelSx
      }}>
        {field.description && (
            <Icon name='square-empty-info' color='primary' sx={{
          mr: 1,
          cursor: 'pointer'
        }} onClick={() => descriptionOpenSet(v => !v)}/>

        )} {field.kind === 'text' || field.kind === 'password' || field.kind == 'number' || field.kind == 'boolean' ? null : field.label}
      </Flex>

      </Flex>

      <Box sx={{
        width: '100%'
      }}>
        {fieldElem}
      </Box>

      {descriptionOpen && (
        <Box sx={{
          mt: 2,
          mb: 4,
          bg: 'light4',
          p: 3,
          fontStyle: 'italic',
          borderRadius: 2
        }}>
        {field.description}
      </Box>
      )}
    </Box>
  )
}

export default RfField