import React from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'

import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'

import { useField } from 'react-final-form'

function withSingleLine(editor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
}

const ForumSlate = ({
  content,
  singleLine
}) => {
  // const [value, setValue] = React.useState(initialValue)
  const renderElement = React.useCallback(props => <Element {...props} singleLine={singleLine} />, [singleLine])
  const renderLeaf = React.useCallback(props => <Leaf {...props} />, [])
  const editor = singleLine ? (
    React.useMemo(() => withHistory(withSingleLine(withReact(createEditor()))), [])
  ) : (
    React.useMemo(() => withHistory(withReact(createEditor())), [])
  )
  // const editor = withHistory(withReact(createEditor()))
  return (
    // <Slate editor={editor} value={value} onChange={value => setValue(value)}>
    <Slate editor={editor} value={content} onChange={() => null}>
      <Box sx={{

      }}>
        <Box sx={{

        }}>
        <Editable
          readOnly
          style={{
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
        </Box>
      </Box>
    </Slate>
  )
}

export const Element = ({ attributes, children, element, singleLine }) => {
  switch (element.type) {
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      )
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h3 {...attributes}>{children}</h3>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return singleLine ? (
        <span {...attributes} style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}>{children}</span>
      ) : (
        <div {...attributes} style={{
          marginTop: '12px',
          marginBottom: '12px'
          // marginBottom: '12px'
        }}>{children}</div>
      )
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export default ForumSlate