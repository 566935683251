import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'
import { Form, useUrlForm } from 'volcano/components/Form'
import { TextField } from 'volcano/components/FormField'

import { useNavigate } from 'runic-vera/router'

// Forum
import ForumEditor from './ForumEditor'
import  { ForumCategoryInput } from './ForumCategorySelector'

import rnxForumClientActions from 'hayatinidegistir/apps/client/forum/actions'

const ForumComposer = props => {
  const composerState = useSelector(state => state.rnxForum.composer)
  const dispatch = useDispatch()
  if (!composerState.isOpen) return null
  // const rcUser = useSelector(state => state.nexusApp.user)

  // if (rcUser?.forumVerified) return <ForumComposerForm {...props}/>

  // return <ForumVerification rcUser={rcUser} toggle={props.toggle}/>
  return <ForumComposerForm category={composerState.category} data={composerState.data} toggle={() => dispatch(rnxForumClientActions.toggleComposer())}/>
}


const ForumComposerForm = ({
  toggle,
  category,
  data,
}) => {
  console.log('data', data)
  let message
  let topicLabel

  topicLabel = 'Konu Başlığı'

  const rcTenantId = useSelector(state => state.runic.config.isMultiTenant ? state.runic.activeRcTenantId : 1)

  const navigate = useNavigate()
  const [formProps, isSubmitting] = useUrlForm({
    // url: '/_api/runic/action/RcApp.Login?X-RUNIC-PLATFORM=RUNIC_MAIN',
    url: data ? `/_api/runic/action/RnxForum.UpdateOwnTopic?rcTenantId=${rcTenantId}` : `/_api/runic/action/RnxForum.CreateTopic?rcTenantId=${rcTenantId}`,
    initialValues: {
      topic: data?.topic
    },
    parseData: (dt) => {
      return data ? ({
        ...dt,
        id: data.id,
        // kind: 1,
        // rnxForumCategoryId: dt.rnxForumCategoryId.value,
      }) : ({
        ...dt,
        kind: 1,
        rnxForumCategoryId: dt.rnxForumCategoryId.value,
      })
      // return {
      //   ...data,
      //   kind: 1,
      //   rnxForumCategoryId: data.rnxForumCategoryId.value,
      // }
    },
    headers: {
      'X-RUNIC-PLATFORM': 'RUNIC_MAIN',
    },
    onSuccess: (data) => {
      toggle()
      navigate(`b/${data.id}`)
    },
  })

  return (
    <Box sx={{
      height: [450 + 40 + 20, null, null, null, 450 + 20],
    }}>
      <Box sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: ['100%', null, null, null, '50%'],
        left: [0, null, null, null, '25%'],
        height: [450 + 40 + 20, null, null, null, 450],
        background: '#FFF',
        borderTop: '3px solid #FFF',
        borderTopColor: 'primary',
        boxShadow: '0 0px 30px rgba(0, 0, 0, 0.20)',
        zIndex: '999999'
      }}>
        <Form {...formProps}>
          <Flex sx={{
            justifyContent: 'flex-end',
          }}>
            <ButtonIcon iconName='octagon-remove'  color={'primary'} onClick={() => toggle()}/>
          </Flex>

          {message}

          <Flex sx={{
            mx: 2,
            mt: 2,
            flexDirection: ['column', null, null, null, 'row']
          }}>
            <Box sx={{
              flex: data ? '1 1 100%' : '1 1 60%',
              // mx: 2
            }}>
              <TextField name='topic' label={topicLabel} required wrapperSx={{
                border: '1px solid #000',
                borderColor: 'primary',
                borderRadius: 0,
                mr: 2
              }} />
            </Box>
            {!data && <Box sx={{
              flex: '1 1 40%',
              mb: [3, null, null, null, 0]
              // mx: 2
            }}>
              <ForumCategoryInput name='rnxForumCategoryId' category={category} filters={{isLocked: false}} />
            </Box>}
          </Flex>
          <ErrorBoundary>
          <ForumEditor data={data?.message} />
          </ErrorBoundary>
          <Flex sx={{

          }}>
            <Button submit label='GÖNDER' working={isSubmitting} sx={{
              px: 3,
              py: 2,
              borderRadius: 0,
              fontWeight: 'bold',
              flex: '1 1 auto',
              mx: 2,
              my: 2
            }} />
          </Flex>
        </Form>
      </Box>
    </Box>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
export default ForumComposer