// Vendor
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

// Riva - Function
import { createReducer, setDraft } from 'runic/core/redux'

import runicActions from './actions'
import authenticationActions from '../authentication/actions'

const defaultState = {
  status: {},
  appStatus: 'WAITING',
  source: {},
  model: {},
  graph: {
    schema: {},
    data: {},
    types: {}
  },
  config: {
    baseAPIUrl: null,
    isMultiTenant: false,
    runicPlatform: 'RUNIC_MAIN'
  },
  activeRcTenantId: null
}

const source = (n) => `@@runic/sourceCall/${n}`

const baseReducer = createReducer(defaultState, {
  [source('RcApp.State.status')]: {
    success: (draft, payload) => {
      draft.status = payload
      draft.appStatus = 'READY'
    },

    error: (draft, payload) => draft.appStatus = 'ERROR',
    // start: (draft, payload) => draft.appStatus = 'LOADING'
  },
})

const getOrCreateModelSource = (draft, name) => {
  if (!draft.model[name]) draft.model[name] = {
    view: {},
    list: {},
    entity: {},
  }

  return draft.model[name]
}

const sourceReducer = createReducer(defaultState, {
  '__default': (draft, payload, state, action) => {
    if (action?.meta?.kind === '@@runic/sourceCall') {
      if (action?.meta?.status == 'SUCCESS') {
        if (!draft.source[action.meta.name]) draft.source[action.meta.name] = {}
        if (!draft.source[action.meta.name][action.meta.fetchKey]) draft.source[action.meta.name][action.meta.fetchKey] = {}
        draft.source[action.meta.name][action.meta.fetchKey].dt = action.meta.dt
        draft.source[action.meta.name][action.meta.fetchKey].data = payload
        draft.source[action.meta.name][action.meta.fetchKey].status = 'READY'
      } else if (action?.meta?.status == 'START') {
        if (!draft.source[action.meta.name]) draft.source[action.meta.name] = {}
        if (!draft.source[action.meta.name][action.meta.fetchKey]) draft.source[action.meta.name][action.meta.fetchKey] = {}
        draft.source[action.meta.name][action.meta.fetchKey].dt = action.meta.dt
        draft.source[action.meta.name][action.meta.fetchKey].status = 'FETCHING'
        draft.source[action.meta.name][action.meta.fetchKey].error = null
      } else if (action?.meta?.status == 'ERROR') {
        if (!draft.source[action.meta.name]) draft.source[action.meta.name] = {}
        if (!draft.source[action.meta.name][action.meta.fetchKey]) draft.source[action.meta.name][action.meta.fetchKey] = {}
        draft.source[action.meta.name][action.meta.fetchKey].dt = action.meta.dt
        draft.source[action.meta.name][action.meta.fetchKey].status = 'ERROR'
        draft.source[action.meta.name][action.meta.fetchKey].error = payload
      }
    } else if (action?.meta?.kind === '@@runic/modelSourceCall') {
      if (action?.meta?.status == 'SUCCESS') {
        const fetchKey = payload['@@runic/fetchKey']
        const listView = payload['@@runic/listView']
        const entity = payload['@@runic/entity']
        const view = payload['@@runic/view']

        if (listView) {
          Object.entries(listView).map(([modelName, value]) => {
            const src = getOrCreateModelSource(draft, modelName)
            src.list[fetchKey] = value
          })
        }

        if (view) {
          Object.entries(view).map(([modelName, value]) => {
            const src = getOrCreateModelSource(draft, modelName)
            src.view[fetchKey] = value
          })
        }

        if (entity) {
          Object.entries(entity).map(([modelName, value]) => {
            const src = getOrCreateModelSource(draft, modelName)
            src.entity = rMerge(src.entity, value)
          })
        }


        if (!draft.model[action.meta.name]) draft.source[action.meta.name] = {}
        if (!draft.source[action.meta.name][action.meta.fetchKey]) draft.source[action.meta.name][action.meta.fetchKey] = {}

        draft.source[action.meta.name][action.meta.fetchKey].dt = action.meta.dt
        draft.source[action.meta.name][action.meta.fetchKey].data = payload
        draft.source[action.meta.name][action.meta.fetchKey].status = 'READY'
      } else if (action?.meta?.status == 'START') {
        if (!draft.source[action.meta.name]) draft.source[action.meta.name] = {}
        if (!draft.source[action.meta.name][action.meta.fetchKey]) draft.source[action.meta.name][action.meta.fetchKey] = {}
        draft.source[action.meta.name][action.meta.fetchKey].dt = action.meta.dt
        draft.source[action.meta.name][action.meta.fetchKey].status = 'FETCHING'
        draft.source[action.meta.name][action.meta.fetchKey].error = null
      } else if (action?.meta?.status == 'ERROR') {
        if (!draft.source[action.meta.name]) draft.source[action.meta.name] = {}
        if (!draft.source[action.meta.name][action.meta.fetchKey]) draft.source[action.meta.name][action.meta.fetchKey] = {}
        draft.source[action.meta.name][action.meta.fetchKey].dt = action.meta.dt
        draft.source[action.meta.name][action.meta.fetchKey].status = 'ERROR'
        draft.source[action.meta.name][action.meta.fetchKey].error = payload
      }
    }
  }
})

const normalizeObject = (obj, schema, types) => {
  const normalizedObj = {}
  const typeObj = {}

  Object.entries(obj).forEach(([valKey, valValue]) => {
    if (isListGraph(valValue) || isTypeGraph(valValue)) {
      typeObj[valKey] = normalizeGraph(valValue, schema, types)
    } else {
      typeObj[valKey] = valValue
    }

    if (['id', '__typename', 'rc_entity_id'].includes(valKey)) {
      normalizedObj[valKey] = valValue
    }
  })

  const typeIdentifier = `${obj.__typename}:${typeObj.id}`
  if (!types[typeIdentifier]) types[typeIdentifier] = {...typeObj}
  else {
    types[typeIdentifier] = {
      ...types[typeIdentifier],
      ...typeObj
    }
  }

  return normalizedObj
}

const isListGraph = data => data?.__kind === 'list'
const isTypeGraph = data => data?.__typename !== undefined

const normalizeGraph = (data, schema, types) => {
  const newData = {}

  if (isTypeGraph(data)) {
    return normalizeObject(data, schema, types)
  }

  if (isListGraph(data)) {
    const newItems = data.items.map(val => {
      if (isObject(val) && (val.__typename)) {
        return normalizeGraph(val, schema, types)
      } else {
        return val
      }
    })
    return {
      ...data,
      items: newItems
    }
  }

  Object.entries(data).forEach(([key, value]) => {
    if (isListGraph(value)) {
      const newItems = value.items.map(val => {
        if (isObject(val) && (val.__typename)) {
          return normalizeGraph(val, schema, types)
        } else {
          return val
        }
      })
      newData[key] = {
        ...value,
        items: newItems
      }
    } else {
      if (isTypeGraph(value)) {
        newData[key] = normalizeObject(value, schema, types)
      } else {
        newData[key] = value
      }
    }
  })
  return newData
}

const graphReducer = createReducer(defaultState, {
  '__default': (draft, payload, state, action) => {
    if (action?.meta?.kind === '@@runic/graphQuery') {
      if (action?.meta?.status == 'SUCCESS') {
        const { data, schema, fetchKey, graphQuery } = payload
        const status = {
          dt: action.meta.dt,
          status: 'READY',
          isFetching: false
        }

        draft.graph.schema = rMerge(draft.graph.schema, schema)

        const types = {}
        const normalized = normalizeGraph(data, schema, types)
        const dependencies = Object.keys(types).map(x => x)

        draft.graph.types = rMerge(draft.graph.types, types)
        draft.graph.data[fetchKey] = { graph: normalized, status, dependencies, graphQuery }
      } else if (action?.meta?.status == 'START') {
        const { fetchKey } = action.meta
        if (!draft.graph.data[fetchKey]) {
          draft.graph.data[fetchKey] = {
            status: {
              isFetching: true,
              status: 'FETCHING',
              dt: action.meta.dt,
            }
          }
        } else {
          draft.graph.data[fetchKey].status.isFetching = true
          if (draft.graph.data[fetchKey].status.status !== 'READY')draft.graph.data[fetchKey].status.status = 'FETCHING'
        }
      } else if (action?.meta?.status == 'ERROR') {
        const { fetchKey } = action.meta
        draft.graph.data[fetchKey].status.error = payload
      }
    }
  }
})

const rMerge = (existing, incoming) => {
  const merged = { ...existing }

  Object.keys(incoming).forEach(incomingKey => {
    const incomingVar = incoming[incomingKey]
    const existingVar = merged[incomingKey]

    if (incomingVar === existingVar) {
      return
    }

    if (existingVar == null || existingVar == undefined) {
      merged[incomingKey] = incomingVar
      return
    }

    // if (incomingVar == null || incomingVar == undefined) {
    //   merged[incomingKey] = incomingVar
    //   return
    // }

    if (incomingVar == null) {
      merged[incomingKey] = incomingVar
      return
    } else if (existingVar && incomingVar == undefined) { // For partial updates.
      return
    }

    // Because isObject returns true for arrays, we need to check isArray first.
    const isArrIncomingVar = isArray(incomingVar)
    const isArrExistingVar = isArray(existingVar)

    if (isArrIncomingVar && isArrExistingVar) {
      // FIXME: remove the redundant clause if it's not needed when stabilized.
      if (incomingVar.length == 0) {
        merged[incomingKey] = incomingVar
        return
      } else {
        merged[incomingKey] = incomingVar
        return
      }
    }

    const isObjIncomingVar = isObject(incomingVar)
    const isObjExistingVar = isObject(existingVar)

    // FIXME: check if working for partial updates
    if (isObjExistingVar && isObjIncomingVar) {
      merged[incomingKey] = rMerge(existingVar, incomingVar)
      return
    }

    merged[incomingKey] = incomingVar

  })

  return merged
}

export default (state = defaultState, action) => {
  const baseState = baseReducer(state, action)
  const sourceState = sourceReducer(baseState, action)
  const finalState = graphReducer(sourceState, action)
  return finalState
}