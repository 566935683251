import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

import Layout from './Layout'

const TenantIndex = props => {
  return (
    <Layout/>
  )
}

export default TenantIndex