import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

// Volcano
import { Box, Flex } from 'volcano'
import { ButtonIcon } from 'volcano/components/Button'

import useRunicActionCreator from 'runic/hooks/useRunicActionCreator'


const RfActionButton = ({
  actionKey,
  actionKind,
  color,
  iconName,
  values,
  onSuccess
}) => {
  const dispatch = useDispatch()
  const [elementName, actionName] = actionKey.split('.')
  const action = useRunicActionCreator(actionName, elementName, actionKind)

  return (
    <ButtonIcon color={color} iconName={iconName} onClick={() => dispatch(action(values, {success: onSuccess}))}/>
  )
}

export default RfActionButton