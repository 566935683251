import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'

// Runic
import { Link, useActiveLink } from 'runic-vera/router'


const BoxLink = ({
  to, id, children, icon, sx, end
}) => {
  const isActive = useActiveLink({to, end})
  return (
    <Link variant={isActive && 'active'} to={to} sx={{
      mb: 2,
      display: 'block',
      ...sx
    }} variants={{
      active: {
        color: 'primary',
        fontWeight: 'bold',
      }
    }}>
      <Flex sx={{
        border: '2px solid #FFF',
        borderColor: isActive ? 'accent' : 'primary',
        px: 3,
        py: 2,
        mb: 2,
        transition: 'all 0.2s ease',
        alignItems: 'center',
        ':hover': {
          [`.lastIcon${id}`]: {
            ml: 3
          },
          borderColor: 'accent3',
        }
      }}>
        <Box sx={{
          flex: ' 1 1 0'
        }}>
          <Icon name={icon}>
            <Box ml={2}>
              {children}
            </Box>
          </Icon>
        </Box>

        <Flex sx={{
          flex: '0 0 36px',
          alignItems: 'center'
        }}>
          <Icon className={`lastIcon${id}`} name='chevron-right' color='primary' sx={{
            ml: 2,
            transition: 'all 0.2s ease'
          }} />
        </Flex>
      </Flex>
    </Link>
  )
}

export default BoxLink