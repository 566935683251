import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useTheme } from 'emotion-theming'
import axios from 'axios'
import { useField } from 'react-final-form'
import AsyncSelect from 'react-select/async'


const ForumCategorySelector = ({
  value,
  onChange,
  defaultValue,
  options,
  defaultOptions,
  asyncDefaultOptions,
  loadingMessage = () => 'Yükleniyor',
  noOptionsMessage = () => 'Bulunamadı.',
  styles = {},
  filters
}) => {
  const theme = useTheme()
  const rcTenantId = useSelector(state => state.runic.config.isMultiTenant ? state.runic.activeRcTenantId : 1)

  return (
    <AsyncSelect
      onChange={onChange}
      value={value}
      options={options}
      defaultOptions={asyncDefaultOptions || defaultOptions}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      defaultValue={defaultValue}
      loadOptions={(input, cb) => {
        // let r = await axios.get(`http://${url.host}/_api/runic/source/BookCommunity.User.status`, {headers}).catch(err => ({err}))
        // r = r.err ? null : r.data
        // return r
        const headers = {
          'X-RUNIC-PLATFORM': 'RUNIC_MAIN',
        }
        axios.get(`/_api/runic/source/RnxForum.Forum.categories`, { params: { value: input, ...filters, rcTenantId }, headers }).then(r => {
          asyncDefaultOptions ? cb([...asyncDefaultOptions, ...r.data]) : cb(r.data)
        })
        // r = r.err ? null : r.data
        // return r
      }}
      // options={options}
      cacheOptions
      defaultOptions
      placeholder='Kategori seç...'
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderColor: theme.colors.primary,
          paddingTop: 4,
          paddingBottom: 4,
          ...styles.control,
        })
      }} />
  )
}

export const ForumCategoryInput = ({
  name,
  category,
  filters
}) => {
  const defaultValue = category ? {
    value: category.id,
    label: category.name
  } : null
  const { input, meta } = useField(name, {
    initialValue: defaultValue
  })
  const defaultOptions = React.useMemo(() => {
    return category ? [{
      value: category.id,
      label: category.name
    }]: null
  }, [category?.id])
  return <ForumCategorySelector value={input.value} onChange={input.onChange} defaultValue={defaultValue} filters={filters} defaultOptions={defaultOptions}/>
}

export default ForumCategorySelector