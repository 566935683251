import React from 'react'

// Vendor
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { setIn, getIn } from 'final-form'

// Volcano
import { Box, Flex } from 'volcano'

const widthMap = {
  MINI: 50,
  SMALL: 30,
  DEFAULT: 100,
  MEDIUM: 200,
  LARGE: 500
}

const SimpleTable = ({
  items,
  columns,
  actions,
  sx,
  alignLast,
  maxHeight
}) => {
  if (!items) return null

  const extraHeaderHeight = 0
  const rowHeight = 60
  const rowCount = items.length
  const headerHeight = 40 + extraHeaderHeight
  const calculatedHeight = (rowHeight * rowCount)

  const calculatedMaxHeight = maxHeight === undefined ? window.innerHeight - 160 - extraHeaderHeight : maxHeight
  const tableHeight = calculatedMaxHeight ? calculatedHeight < calculatedMaxHeight ? calculatedHeight : calculatedMaxHeight : calculatedHeight

  const cols = columns.map(({label, key, widthKey, flexGrow}) => {
    if (key == '@dx') {
      if (!widthKey) widthKey = 'MINI'
      if (!flexGrow) flexGrow = 0
    }

    return {
      label,
      key,
      widthKey,
      flexGrow
    }
  })

  return (
    <Box sx={sx}>
      <AutoSizer disableHeight>
      {({ width }) => (
        <>
          <Flex sx={{
            height: headerHeight,
            width: width,

            borderBottom: '1px solid #FFF',
            borderBottomColor: 'light4'
          }}>
          {cols.map(({label, key, widthKey, flexGrow}, colDx) => {
            return (
              <Box sx={{
                flex: `${flexGrow !== undefined ? flexGrow : 1} 1 ${widthMap[widthKey || 'DEFAULT']}px`,
                py: 15,
                fontWeight: 'bold'
              }} key={key}>
                {alignLast && colDx === cols.length - 1 ? (
                  <Box sx={{
                    float: 'right',
                    display: 'inline-block'
                  }}>
                    {label}
                  </Box>
                ) : (
                  label
                )}
              </Box>
            )
          })}
          </Flex>

          <List
            itemData={{items, columns: cols, actions, count: items?.length, alignLast}}
            itemKey={(dx, data) => data.items[dx].id}
            itemSize={rowHeight}
            itemCount={rowCount}
            height={tableHeight}
            width={width}
          >
            {Row}
          </List>
        </>
      )}
    </AutoSizer>
    </Box>
  )
}

const Row = ({
  data,
  index,
  style
}) => {
  const item = data.items[index]
  return (
    <Flex style={style} sx={{
      borderBottom: '1px solid #FFF',
      borderBottomColor: 'light2',
      alignItems: 'center'
    }}>
      {data.columns.map(({label, key, widthKey, flexGrow}, colDx) => {
        let content
        if (key == '@dx') content = index + 1
        else if (key == '@actions') content = data.actions?.map((act, dx) => act(item, dx))
        else content = getIn(item, key)
        return (
          <Box sx={{
            flex: `${flexGrow !== undefined ? flexGrow : 1} 1 ${widthMap[widthKey || 'DEFAULT']}px`,
            // fontWeight: 'bold'
          }} key={key}>
            {data.alignLast && colDx === data.columns.length - 1 ? (
              <Box sx={{
                float: 'right',
                display: 'inline-block'
              }}>
                {content}
              </Box>
            ) : (
              content
            )}
          </Box>
        )
      })}
    </Flex>
  )
}

export default SimpleTable