import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'
import Button, { ButtonIcon } from 'volcano/components/Button'

// Runic
import { Link, NavLink, Routes, Route } from 'runic-vera/router'

import useRunicListView from 'runic/hooks/useRunicListView'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import Entity from 'runic-vera/components/Entity'

import EntityOrderForm from 'runic-vera/components/EntityOrderForm'

const CourseCategoryMgmt = props => {
  return (
    <Routes>
      <Route path="detay/:tagId" element={<>u</>} />
      <Route path="" element={<CourseCategoryList />} />
    </Routes>
  )
}

const CourseCategoryList = props => {
  const source = useRunicListView('RxTag.RxTag.ListView', { filters: { 'RxTagKind.code_name': 'COURSES'}, opts: { 'sortBy': 'order', sortDirection: 'ASC'}})
  const [editOrder, setEditOrder] = React.useState(false)

  return (
    <Box>
      <Flex sx={{
        alignItems: 'cente'
      }}>
      <RfSingleAction upload header='Yeni Kategori' actionKey='RnxCourse.CreateCourseCategory' anchorText='Yeni Kategori' fields={[
        {
          label: 'İsim',
          name: 'name',
          kind: 'text',
          required: true,
        },
      ]} />
      <ButtonIcon iconName='sort-attributes' color='primary' onClick={() => setEditOrder(v => !v)}/>
      </Flex>

      <Flex sx={{
        mx: -2,
        flexWrap: 'wrap',
        mt: 2
      }}>
      {source?.list?.items?.map(id => {
        return (
          <Box sx={{
            mx: 2,
            border: '1px solid #FFF',
            borderColor: 'primary',
            p: 2,
          }} key={id}>
            <Entity id={id} modelName={'RxTag'}/>
          </Box>
        )
      })}
      </Flex>

      {editOrder && source?.list?.items && <EntityOrderForm elementName='RnxCourse' actionName='SetCategoryOrder' direction={'horizontal'} onSuccess={() => {
            source.refresh()
            setEditOrder(false)
          }} onCancel={() => {
            setEditOrder(false)
          }} items={source.list.items} renderer={(id, dx) => {
              return (
                <Flex sx={{
                  border: '2px solid #FFF',
                  borderColor: 'primary',
                  px: 3,
                  py: 2,
                  mb: 2,
                  mx: 2,
                  transition: 'all 0.2s ease',
                  alignItems: 'center',
                }}>
                  <Icon name='file' color='primary6' sx={{
                    mr: 2
                  }} size={24} />
                  <Box sx={{
                    flex: ' 1 1 0'
                  }}>
                    <Entity key={id} id={id} modelName={'RxTag'}/>
                  </Box>
                </Flex>
              )
            }}/>}
    </Box>
  )
}

export default CourseCategoryMgmt