import React from 'react'

// Vendor
import { useField } from 'react-final-form'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'
import useFileDialog from 'volcano/hooks/useFileDialog'


// Blockreactor
import RfTextField from './RfTextField'


const RfImageSelectField = ({
  name,
  initialValue,
  acceptedFileKinds
}) => {
  const { input, meta } = useField(name)

  const openFileDialog = useFileDialog({
    acceptedFileKinds,
    onAdd: (files) => input.onChange(files[0])
  })

  console.log('*', input)

  return (
    <Flex sx={{
      alignItems: 'center',
      pr: 3
    }}>
      <Box sx={{
        flex: '0 0 30px',
        ml: 2
      }}>
      <Box as='button' onClick={openFileDialog} sx={{
      backgroundColor: 'primary',
      px: 3,
      py: 2,
      border: 'none',
      color: '#FFF',
      cursor: 'pointer'
    }}>
      <Icon name='cloud-upload' />
    </Box>
      </Box>

    </Flex>
  )
}

const RfImageSelector = ({
  name,
  options,
  acceptedFileKinds
}) => {
  const { app: appState } = useSelector(state => state.br)
  const template = useSelector(state => state.br.template)

  let upload
  if (appState.mode === 'THEME') {
    upload = async (file) => {
      const data = new FormData()
      data.append('file', file)
      data.append('data', JSON.stringify({...file, projectCodeName: template.codeName,}))

      const result = await axios.post(`/@@br/_api/local/uploadImage`, data, {
        callbacks: {
          onUploadProgress: (e) => {
            const percentCompleted = Math.round((e.loaded * 100) / e.total);
            // action.callbacks.progress({
            //   id: file.id,
            //   progress: percentCompleted,
            //   modelName
            // })
            console.log('percentCompleted', file, percentCompleted)
          }
        }, headers: { 'Content-Type': 'multipart/form-data' }
      }).catch(err => ({ err }))
      if (result.err) {
        console.log('error', result.err)
      } else {
        console.log('success')
        console.log(result.data.path)
        input.onChange(result.data.path)
      }
    }
  } else {
    const headers = {
      'X-RUNIC-PLATFORM': template.runicPlatform,
      'X-RUNIC-RC-TENANT-CODE-NAME': template.tenantCodeName
    }
    upload = async (file) => {
      const data = new FormData()
      data.append('file', file)
      data.append('data', JSON.stringify({...file, projectCodeName: template.codeName,}))

      const result = await axios.post('/_api/runic/upload/RnxContent.UploadMedia', data, {
        callbacks: {
          onUploadProgress: (e) => {
            const percentCompleted = Math.round((e.loaded * 100) / e.total);
            // action.callbacks.progress({
            //   id: file.id,
            //   progress: percentCompleted,
            //   modelName
            // })
            console.log('percentCompleted', file, percentCompleted)
          }
        }, headers: { ...headers, 'Content-Type': 'multipart/form-data' }
      }).catch(err => ({ err }))
      if (result.err) {
        console.log('error', result.err)
      } else {
        console.log('success')
        input.onChange(result.data.path)
      }
    }
  }
  const { input, meta } = useField(name)

  const openFileDialog = useFileDialog({
    acceptedFileKinds,
    onAdd: (files) => upload(files[0])
  })

  return (
    <Box as='button' onClick={openFileDialog} sx={{
      backgroundColor: 'primary',
      px: 3,
      py: 2,
      border: 'none',
      color: '#FFF',
      cursor: 'pointer'
    }}>
      <Icon name='cloud-upload' />
    </Box>
  )




  const ready = state && state.files



  return (
    <Box>
      <Box as='button' onClick={openFileDialog} sx={{
        backgroundColor: 'transparent',
        px: 3,
        py: 2,
        border: 'none',
        color: '#FFF',
        cursor: 'pointer'
      }}>
        <Icon name='file-plus' />
      </Box>

      {ready && <Flex sx={{
        justifyContent: 'space-between'
      }}>
        <Box>
          {state.files.map((file, dx) => {
            return (
              <Box key={dx}>
                {file.displayName}
              </Box>
            )
          })}
        </Box>

        <Box as='button' onClick={upload} sx={{
          backgroundColor: 'primary',
          px: 3,
          py: 2,
          border: 'none',
          color: '#FFF',
          cursor: 'pointer'
        }}>
          <Icon name='cloud-upload' />
        </Box>
      </Flex>}
    </Box>
  )
}

// const RfImageSelector = ({
//   name,
//   options,
//   acceptedFileKinds
// }) => {
//   const [openFileDialog, state, reset, start, error, retry, success] = useFileSelector({ ...options, acceptedFileKinds })
//   console.log(state)
//   const {input, meta} = useField(name)


//   const ready = state && state.files

//   const headers = {
//     'X-RUNIC-PLATFORM': 'RUNIC_PUBLIC',
//     'X-RUNIC-RC-TENANT-CODE-NAME': 'Hypatia'
//   }

//   const upload = async (e) => {
//     e.preventDefault()
//     state.files.forEach(async (file, dx) => {
//       const data = new FormData()
//       data.append('file', file)
//       // data.append('data', JSON.stringify(file))

//       const result = await axios.post(`/@@br/_api/local/uploadImage`, data, {
//         callbacks: {
//           onUploadProgress: (e) => {
//             const percentCompleted = Math.round((e.loaded * 100) / e.total);
//             // action.callbacks.progress({
//             //   id: file.id,
//             //   progress: percentCompleted,
//             //   modelName
//             // })
//             console.log('percentCompleted', file, percentCompleted)
//           }
//         }, headers: { ...headers, 'Content-Type': 'multipart/form-data' }
//       }).catch(err => ({ err }))
//       if (result.err) {
//         console.log('error', result.err)
//       } else {
//         input.onChange(result.data.path)
//       }
//     })
//   }

//   return (
//     <Box>
//       <Box as='button' onClick={openFileDialog} sx={{
//         backgroundColor: 'transparent',
//         px: 3,
//         py: 2,
//         border: 'none',
//         color: '#FFF',
//         cursor: 'pointer'
//       }}>
//         <Icon name='file-plus' />
//       </Box>

//       {ready && <Flex sx={{
//         justifyContent: 'space-between'
//       }}>
//         <Box>
//           {state.files.map((file, dx) => {
//             return (
//               <Box key={dx}>
//                 {file.displayName}
//               </Box>
//             )
//           })}
//         </Box>

//         <Box as='button' onClick={upload} sx={{
//           backgroundColor: 'primary',
//           px: 3,
//           py: 2,
//           border: 'none',
//           color: '#FFF',
//           cursor: 'pointer'
//         }}>
//           <Icon name='cloud-upload' />
//         </Box>
//       </Flex>}
//     </Box>
//   )
// }

export default RfImageSelectField