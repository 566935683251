import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import { Link, NavLink, Routes, Route } from 'runic-vera/router'

import ForumIndex from './ForumIndex'
import ForumComposer from '../components/ForumComposer'


const ForumHome = props => {
  return (
    <Box>
      <Routes>
        <Route path="*" element={<ForumIndex/>} />
      </Routes>

      <ForumComposer/>
    </Box>
  )
}

export default ForumHome