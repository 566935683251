// Vendor
import stringfy from 'json-stable-stringify'
import * as changeCase from "change-case"
import { takeEvery, call, put, select, cancel, delay, fork } from 'redux-saga/effects'
import { runicSourceRequest, runicActionRequest, callCallbacks, runicMultiSourceRequest, runicUploadRequest, runicGraphQueryRequest } from 'runic/core/runicSagaHelpers'


const quickHash = (str) => {
  let result = 0
  if (str.length == 0) return result
  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i)
    result = ((result << 5) - result) + char
    result = result & result
  }
  return result
}

const batchSourceRequests = {}
const batchSourceCalls = {}

const sourceCalls = {}

const modelSourceCalls = {}

function* multiSourceCallHandler(action) {
  const { sourceName, queries } = action.payload

  try {
    const apiResponse = yield call(runicMultiSourceRequest, sourceName, queries)

    yield put({
      type: action.type,
      payload: apiResponse.data,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'success', payload)

  } catch (error) {
    if (error.response) {
      const meta = {
        ...action.meta,
        status: 'ERROR',
      }

      const callbackPayload = { ...error.response }

      // if (error.response.data && error.response.data.kind && error.response.data.kind == 'NOT_AUTHORIZED') {
      //   const _payload = error.response.data.payload || {}
      //   meta.permissionError = _payload
      //   callbackPayload.permissionError = _payload
      // }

      callCallbacks(action, 'error', callbackPayload)

      yield put({
        type: action.type,
        payload: error.response.data,
        meta
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }

  }
}

function* sourceCallHandler(action) {
  const sourceName = action.meta.name
  const payload = action.payload

  try {
    const apiResponse = yield call(runicSourceRequest, sourceName, payload, 'source', action)

    yield put({
      type: action.type,
      payload: apiResponse.data,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'success', payload)

  } catch (error) {
    if (error.response) {
      const meta = {
        ...action.meta,
        status: 'ERROR',
      }

      const callbackPayload = { ...error.response }

      // if (error.response.data && error.response.data.kind && error.response.data.kind == 'NOT_AUTHORIZED') {
      //   const _payload = error.response.data.payload || {}
      //   meta.permissionError = _payload
      //   callbackPayload.permissionError = _payload
      // }

      callCallbacks(action, 'error', callbackPayload)

      yield put({
        type: action.type,
        payload: error.response.data,
        meta
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }

  }
}

function* modelSourceCallHandler(action) {
  const sourceName = action.meta.name
  const payload = action.payload

  try {
    const apiResponse = yield call(runicSourceRequest, sourceName, payload, 'source', action)

    yield put({
      type: action.type,
      payload: apiResponse.data,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'success', payload)

  } catch (error) {
    if (error.response) {
      const meta = {
        ...action.meta,
        status: 'ERROR',
      }

      const callbackPayload = { ...error.response }

      // if (error.response.data && error.response.data.kind && error.response.data.kind == 'NOT_AUTHORIZED') {
      //   const _payload = error.response.data.payload || {}
      //   meta.permissionError = _payload
      //   callbackPayload.permissionError = _payload
      // }

      callCallbacks(action, 'error', callbackPayload)

      yield put({
        type: action.type,
        payload: error.response.data,
        meta
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }

  }
}

function* graphQueryHandler(action) {
  const payload = action.payload

  try {
    const apiResponse = yield call(runicGraphQueryRequest, payload)

    yield put({
      type: action.type,
      payload: apiResponse.data,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'success', payload)

  } catch (error) {
    if (error.response) {
      const meta = {
        ...action.meta,
        status: 'ERROR',
      }

      const callbackPayload = { ...error.response }

      // if (error.response.data && error.response.data.kind && error.response.data.kind == 'NOT_AUTHORIZED') {
      //   const _payload = error.response.data.payload || {}
      //   meta.permissionError = _payload
      //   callbackPayload.permissionError = _payload
      // }

      callCallbacks(action, 'error', callbackPayload)

      yield put({
        type: action.type,
        payload: error.response.data,
        meta
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }

  }
}

function* doBatchSourceCall(sourceName) {
  yield delay(50)

  const queries = Object.entries(batchSourceRequests[sourceName]).map(([fetchKey, params]) => ({
    fetchKey,
    params
  }))
  const payload = {
    sourceName,
    queries
  }

  yield put({
    type: `@@runic/multiSourceCall/${sourceName}`,
    payload,
    meta: {
      kind: '@@runic/multiSourceCall',
      name: sourceName,
      status: 'START'
    }
  })

  delete batchSourceRequests[sourceName]
  delete batchSourceCalls[sourceName]
}

function* doSourceCall(sourceName, params, fetchKey, dt) {
  yield delay(50)

  yield put({
    type: `@@runic/sourceCall/${sourceName}`,
    payload: params,
    meta: {
      kind: '@@runic/sourceCall',
      name: sourceName,
      fetchKey,
      dt,
      status: 'START'
    }
  })

  delete sourceCalls[fetchKey]
}

function* doModelSourceCall(sourceName, params, fetchKey, dt) {
  yield delay(50)

  yield put({
    type: `@@runic/modelSourceCall/${sourceName}`,
    payload: params,
    meta: {
      kind: '@@runic/modelSourceCall',
      name: sourceName,
      fetchKey,
      dt,
      status: 'START'
    }
  })

  delete modelSourceCalls[fetchKey]
}

function* sourceHandler(action) {
  const sourceName = action.meta.name
  const params = action.payload
  const fetchKey = action.meta.fetchKey || quickHash(stringfy({s: sourceName, p: params}))

  const existing = yield select((state) => state.runic.source?.[sourceName]?.[fetchKey])

  let fetchRequired = true
  if (existing && existing.status === 'READY') {
    const ageInMs = action.meta.dt - existing.dt
    if (ageInMs < 3000) fetchRequired = false
  }

  if (!fetchRequired) return

  const batch = false

  if (batch) {
    if (!batchSourceRequests[sourceName]) {
      batchSourceRequests[sourceName] = {}
    }

    batchSourceRequests[sourceName][fetchKey] = params

    if (batchSourceCalls[sourceName]) yield cancel(batchSourceCalls[sourceName])

    batchSourceCalls[sourceName] = yield fork(doBatchSourceCall, sourceName)
  } else {
    if (sourceCalls[fetchKey]) {
      let i = 0
      while(i < 3) {
        yield delay(700*i)
        const existing = yield select((state) => state.runic.source?.[sourceName]?.[fetchKey])
        if (existing) return
        if (!sourceCalls[fetchKey]) return
        i += 1
      }

      if (sourceCalls[fetchKey]) {
        yield cancel(sourceCalls[fetchKey])
        sourceCalls[fetchKey] = yield fork(doSourceCall, sourceName, params, fetchKey, action.meta.dt)
      }
    } else {
      sourceCalls[fetchKey] = yield fork(doSourceCall, sourceName, params, fetchKey, action.meta.dt)
    }
  }
}

function* modelSourceHandler(action) {
  const sourceName = action.meta.name
  const params = action.payload
  const fetchKey = action.meta.fetchKey

  const batch = false

  if (batch) {
    // if (!batchSourceRequests[sourceName]) {
    //   batchSourceRequests[sourceName] = {}
    // }

    // batchSourceRequests[sourceName][fetchKey] = params

    // if (batchSourceCalls[sourceName]) yield cancel(batchSourceCalls[sourceName])

    // batchSourceCalls[sourceName] = yield fork(doBatchSourceCall, sourceName)
  } else {
    if (modelSourceCalls[fetchKey]) {
      let i = 0
      while(i < 3) {
        yield delay(700*i)
        const existing = yield select((state) => state.runic.model?.[sourceName]?.[fetchKey])
        if (existing) return
        if (!modelSourceCalls[fetchKey]) return
        i += 1
      }

      if (modelSourceCalls[fetchKey]) {
        yield cancel(modelSourceCalls[fetchKey])
        modelSourceCalls[fetchKey] = yield fork(doModelSourceCall, sourceName, params, fetchKey, action.meta.dt)
      }
    } else {
      modelSourceCalls[fetchKey] = yield fork(doModelSourceCall, sourceName, params, fetchKey, action.meta.dt)
    }
  }
}

function* actionHandler(action) {
  let actionPayload = action.payload
  let actionPath
  let meta
  if (action.type == 'RCR_CREATE_ENTITY') {
    const { rcrModelName, rcrComponentName, rcrCreateSuffix, rcrActionName, ...restActionPayload } = actionPayload
    const actionName = rcrActionName ? rcrActionName : `Create${rcrModelName}${rcrCreateSuffix || ''}`
    actionPath = `${rcrComponentName}.${actionName}`
    actionPayload = restActionPayload
    meta = {
      rcrModelName,
      rcrComponentName
    }
  } else {
    const actionComponent = action.meta.component
    let actionCamelName = action.meta.search
    if (!actionCamelName) {
      if (action.type.includes('/')) {
        const actionTypeSplit = action.type.split('/')
        const actionName = actionTypeSplit[actionTypeSplit.length-1]
        actionCamelName = changeCase.pascalCase(actionName)
      } else {
        actionCamelName = changeCase.pascalCase(action.type)
      }
  }
    actionPath = `${actionComponent}.${actionCamelName}`
  }

  try {
    const apiResponse = yield call(runicActionRequest, actionPath, actionPayload, action.meta.actionKind || 'action', action)
    let payload = apiResponse.data, status = 'SUCCESS'

    const callbackResponse = callCallbacks(action, 'afterRequest', payload)
    if (callbackResponse === false) {
      return
    }

    // if (apiResponse.data && apiResponse.data._rc_entity) {
    //   const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)

    //   const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
    //   delete payload._rc_entity
    //   payload['rcEntity'] = entityData
    // }

    // if (apiResponse.data && apiResponse.data._rc_entity_deleted) {
    //   payload['deletedRcEntity'] = apiResponse.data._rc_entity_deleted
    // }

    // if (apiResponse.data && apiResponse.data._rcr_sub_actions) {
    //   payload = {
    //     rcrSubActions: apiResponse.data._rcr_sub_actions
    //   }
    //   status = 'ERROR'
    // }

    yield put({
      type: action.type,
      payload: payload,
      meta: {
        ...action.meta,
        ...meta,
        status: status
      }
    })

    callCallbacks(action, status, payload)
  } catch (error) {
    if (error.response) {
      callCallbacks(action, 'error', error.response.data)

      yield put({
        type: action.type,
        payload: {
          ...actionPayload,
          _error: error.response.data
        },
        meta: {
          ...action.meta,
          status: 'ERROR'
        }
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }
  }
}

function* uploadActionHandler(action) {
  let actionCamelName
  const actionComponent = action.meta.component
  if (action.type.includes('/')) {
    const actionTypeSplit = action.type.split('/')
    const actionName = actionTypeSplit[actionTypeSplit.length - 1]
    actionCamelName = changeCase.pascalCase(actionName)
  } else {
    actionCamelName = changeCase.pascalCase(action.type)
  }
  const actionPath = `${actionComponent}.${actionCamelName}`

  const { formData, modelName } = action.payload

  const data = formData
  console.log('***', data)

  const callbacks = {
    onUploadProgress: (e) => {
      const percentCompleted = Math.round( (e.loaded * 100) / e.total );
      action?.callbacks?.progress?.({
        // id: file.id,
        progress: percentCompleted,
        modelName
      })
    }
  }

  try {
    const apiResponse = yield call(runicUploadRequest, actionPath, data, callbacks)
    let payload = {
      ...apiResponse.data,
      modelName
    }

    // if (apiResponse.data && apiResponse.data._rc_entity) {
    //   const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)

    //   const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
    //   delete payload._rc_entity
    //   payload['rcEntity'] = entityData
    // }
    payload.progress = 0

    yield put({
      type: action.type,
      payload: payload,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'SUCCESS', payload)
  } catch (error) {
    if (error.response) {
      const payload = {
        ...action.payload,
        _error: error.response,
        // file,
      }
      yield put({
        type: action.type,
        payload,
        meta: {
          ...action.meta,
          status: 'ERROR'
        }
      })
      callCallbacks(action, 'ERROR', payload)
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }
  }
}

export function* watchSource() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@runic/source', sourceHandler)
}

export function* watchModelSource() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@runic/modelSource', modelSourceHandler)
}

export function* watchAction() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action.meta.kind == 'RCR_ACTION', actionHandler)
}

export function* watchUploadAction() {
  yield takeEvery((action) => action?.meta?.kind == 'RCR_UPLOAD' && action.meta.status == 'START', uploadActionHandler)
}

export function* watchMultiSourceCall() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@runic/multiSourceCall', multiSourceCallHandler)
}



export function* watchSourceCall() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@runic/sourceCall', sourceCallHandler)
}

export function* watchModelSourceCallHandler() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@runic/modelSourceCall', modelSourceCallHandler)
}

export function* watchGraphQuery() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@runic/graphQuery', graphQueryHandler)
}


export default [watchSource, watchModelSource, watchMultiSourceCall, watchSourceCall, watchAction, watchUploadAction, watchModelSourceCallHandler, watchGraphQuery]