import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import useRunicSource from 'runic/hooks/useRunicSource'

import Journal from '../components/Journal'


const UserHistory = props => {
  const source = useRunicSource('RnxJournal.Journal.history')

  if (!source.ready) {
    return (
      <Box>
        Yükleniyor
      </Box>
    )
  }

  console.log(source)

  return (
    <Box>
      <Box>
        <Journal/>
      </Box>

      <Box sx={{
        variant: 'text.pageHeader',
      }}>
        Geçmiş
      </Box>

      <Box>
        <Graph label='Son 15 gün ölçülerin' data={source.data.measurements} dataKeys={[
          {
            name: 'Gögüs',
            dataKey: 'BUST'
          },

          {
            name: 'Bel',
            dataKey: 'WAIST'
          },

          {
            name: 'Basen',
            dataKey: 'HIPS'
          },

          {
            name: 'Kilo',
            dataKey: 'WEIGHT'
          },
        ]} />
      </Box>
    </Box>
  )
}

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

const Graph = ({
  label,
  data,
  dataKeys
}) => {
  return (
    <Box sx={{
      bg: '#FFF',
      p: 3
    }}>
      <Box sx={{
        color: 'primary',
        fontWeight: 'bold'
      }}>
        {label}
      </Box>
      <ResponsiveContainer width={'100%'} height={300}>
        <LineChart width={600} height={300} data={data}
          margin={{ top: 30, right: 15, left: 15, bottom: 5 }}>
          <XAxis dataKey="date" />
          {/* <YAxis allowDecimals={false} /> */}
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          {dataKeys.map(dt => <Line type="monotone" dataKey={dt.dataKey} name={dt.name} activeDot={{ r: 8 }} />)}
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default UserHistory