import React from 'react'

import { useField } from 'react-final-form'

import BareTextField from './TextField'
import { Checkbox, Label } from './Checkbox'
import Dropdown from './Dropdown'
import { Flex, Box, Spacer } from 'volcano'

const ErrorText = props => (
  <Box {...props} sx={{
    ...props.sx,
    fontSize: 's',
    color: 'accent',
    mt: 1,
    ml: 13
  }}/>
)

export const FieldError = ({ error, errorMap }) => {
  if (!error) return null
  const kind = error.kind || error.type
  const mappedKind = errorMap[kind]

  const message = mappedKind || "Geçersiz"

  return (
    <ErrorText>
      {message}
    </ErrorText>
  )
}

export const FormError = ({ error, errorMap }) => {
  if (!error) return null
  const kind = error.kind || error.type
  const mappedKind = errorMap[kind]

  const message = mappedKind || "Bir hata oluştu."

  return (
    <ErrorText>
      {message}
    </ErrorText>
  )
}

export const TextField = React.forwardRef((props, ref) => {
  const { name, description, ...rest } = props
  const { input, meta } = useField(name)
  // console.log('- test', input.name, input.value)
  return (
    <Spacer mb={3}>
      <BareTextField {...input} {...rest} />
      <Flex>
        {meta.error && <Box flex='1 1 0'>
          <FieldError error={meta.error} />
        </Box>}

        {description && <Box flex='1 1 0' textAlign='right' fontSize='s' mt={2} color={'dark7'}>
          {description}
        </Box>}
      </Flex>
    </Spacer>
  )
})

export const DropdownField = React.forwardRef((props, ref) => {
  const { name, ...rest } = props
  const { input, meta } = useField(name)
  // console.log('- test', input.name, input.value)
  return (
    <Spacer mb={3}>
      <Dropdown inputProps={input} {...rest}/>
      <FieldError error={meta.error} />
    </Spacer>
  )
})

export const CheckboxField = React.forwardRef((props, ref) => {
  const { name, label, onChange, ...rest } = props
  const { input, meta } = useField(name, {type: 'checkbox'})

  return (
    <Spacer mb={3}>
      <Label>
        <Checkbox
          {...input} {...rest} onChange={e => {
            input.onChange(e)
            onChange?.(e.target.checked)
          }}
        />
        {label}
      </Label>
      <FieldError error={meta.error} />
    </Spacer>
  )
})

export const RadioField = React.forwardRef((props, ref) => {
  const { name, options, label, ...rest } = props
  return (
    <Spacer my={4}>
      <Box sx={{
        fontWeight: 'bold',
        fontSize: 's',
        mb: 2
      }}>
        {label}
      </Box>
      {options.map((option, dx) => {
        const { input, meta } = useField(name, {type: 'radio', value: option.value})

        return (
          <Box as='label' key={dx} sx={{
            mr: 3
          }}>
          <input
            {...input}
            type='radio'
            ref={ref}
          />
          {' '} {option.label}
        </Box>
        )
      })}
      {/* <FieldError error={meta.error} /> */}
    </Spacer>
  )
})
