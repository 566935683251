import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import { Link, NavLink, Routes, Route } from 'runic-vera/router'
import * as RV from 'runic-vera/components/RunicView'
import BoxLink from 'runic-vera/themes/v1/BoxLink'

import AuthRoleDetail from './AuthRoleDetail'


const AuthMgmt = props => {
  const result = RV.useRunicGraphQuery({
    rx_as_role_list: {
      'order_by': 'name|asc',
      fields: [
        'name'
      ]
    }
  })

  return (
    <Flex>
      <Box sx={{
        flex: '0 0 250px'
      }}>
      {result?.graph?.rx_as_role_list?.items?.map(obj => {
        return (
          <BoxLink to={`rol/${obj.id}`} key={obj.id} id={obj.id} icon='tag'>
            {obj.name}
          </BoxLink>
        )
      })}
      </Box>

      <Box sx={{
        flex: '1 1 auto',
        ml: 4
      }}>
        <Routes>
          <Route path='rol/:rxAsRoleId/*' element={<AuthRoleDetail/>}/>
        </Routes>
      </Box>
    </Flex>
  )
}

export default AuthMgmt