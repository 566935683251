import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

import RfSingleAction from 'runic-form/components/RfSingleAction'
import useRunicSource from 'runic/hooks/useRunicSource'

import Card from 'hayatinidegistir/components/Card'


const ProfileHome = props => {
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        fontSize: 'xl',
        mb: 3,
        variant: 'text.pageHeaderText',
      }} >
        Profilim
      </Box>

      <Card sx={{
        p: 3,
        width: '100%',
        mb: 3,
        mt: 3
        // mb: 3
      }}>
        <Box sx={{
        fontWeight: 'bold',
        // fontSize: 'xl',
        mb: 2,
        variant: 'text.headerText',
        color: 'primary2'
      }} >
        Bilgiler
      </Box>
        <OptionsItem label='Şifreni Güncelle'/>

        <Subscription/>
      </Card>
    </Box>
  )
}

const OptionsItem = ({
  label
}) => {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState(null)
  return (
    <Box>
      <Box as='a' sx={{
        color: 'primary'
      }} onClick={() => {
        setOpen(v => !v)
        setMessage(null)
      }}>
      Şifreni Güncelle
      </Box>

      {message && (
        <Box sx={{
          p: 3,
          color: '#FFF',
          bg: 'primary'
        }}>
          {message}
        </Box>
      )}

      {open && <RfSingleAction onSuccess={() => {
        setOpen(false)
        setMessage('Şifren değiştirildi.')
      }} header='Şifreni Güncelle' actionKey='RcApp.SetRcUserOwnPassword' fields={[
            {
              label: 'Yeni Şifren',
              name: 'password',
              kind: 'password',
              required: true,
            },

            {
              label: 'Yeni Şifren (tekrar)',
              name: 'passwordAgain',
              kind: 'password',
              required: true,
            },
          ]} formOnly />}
    </Box>
  )
}

import dayjs from 'dayjs'

const Subscription = props => {
  const source = useRunicSource('Hayatinidegistir.Subscription.user_subscription_detail', { params: {  } })
  const [message, setMessage] = React.useState(null)
  console.log('sub', source)
  if (!source.ready) return null

  const subscription = source.data?.subscription?.rnx_subscription

  let content
  if (!subscription) {
    content = (
      <Box>

        Aktif aboneliniğiniz bulunmamaktadır.
      </Box>
    )
  } else {
    let endDate
    if (subscription.kind == 'RECURRING_MONTH') {
      endDate = dayjs(subscription.datetime_first_started).add(3, 'hours').add(subscription.period_interval, 'month')
    }
    content = (
      <Box>
        <Box>
          <strong>Başlangıç Tarihi:</strong> {dayjs(subscription.datetime_first_started).add(3, 'hours').format('DD/MM/YYYY')} ({dayjs(subscription.datetime_first_started).add(3, 'hours').fromNow()})
        </Box>

        <Box sx={{
          mt: 2
        }}>
          <strong>Bitiş Tarihi:</strong> {endDate.format('DD/MM/YYYY')} ({endDate.fromNow()})
        </Box>

        <Box sx={{
          mt: 2
        }}>
          {subscription.status === 'ACTIVE' ? (
            <Box sx={{
              mt: 2
            }}>
              Aboneliğin aktif, {endDate.format('DD/MM/YYYY')} tarihinde otomatik olarak yenilenecek.

              <RfSingleAction onSuccess={() => {
                source.refresh()
                setMessage('Aboneliğin otomatik olarak yenilenmeyecek.')
              }}  buttonText='İptal et' iconName='circle-remove' anchorText='Abone otomatik yenilemeyi iptal et' header='Abone otomatik yenilemeyi iptal et' actionKey='Hayatinidegistir.CancelSubscription' fields={[
                    {
                      label: 'Şifren',
                      name: 'password',
                      kind: 'password',
                      required: true,
                    },
                  ]} />
            </Box>
          ) : (
            <Box sx={{
              mt: 2
            }}>
              Aboneliğin otomatik yenilemeye kapatıldı. {endDate.format('DD/MM/YYYY')} tarihinde iptal edilecek ve hesabın kapatılacak.

              <RfSingleAction onSuccess={() => {
                source.refresh()
                setMessage('Aboneliğin otomatik olarak yenilenecek.')
              }}  buttonText='Yenilemeyi aktifleştir' iconName='refresh' anchorText='Abone otomatik yenilemeyi aktifleştir' header='Abone otomatik yenilemeyi aktifleştir' actionKey='Hayatinidegistir.EnableSubscriptionRenewal' fields={[
                    {
                      label: 'Şifren',
                      name: 'password',
                      kind: 'password',
                      required: true,
                    },
                  ]} />
            </Box>
          )}
        </Box>

      </Box>
    )
  }
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        // fontSize: 'xl',
        mb: 2,
        mt: 3,
        variant: 'text.headerText',
        color: 'primary2'
      }} >
        Abonelik
      </Box>

      {content}

      {message && (
        <Box sx={{
          p: 3,
          color: '#FFF',
          bg: 'primary'
        }}>
          {message}
        </Box>
      )}
    </Box>
  )
}

export default ProfileHome