import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'
import { Form, useUrlForm } from 'volcano/components/Form'

import ForumEditor from './ForumEditor'

import rnxForumClientActions from 'hayatinidegistir/apps/client/forum/actions'

const ForumMessageComposer = props => {
  const composerState = useSelector(state => state.rnxForum.messageComposer)
  const dispatch = useDispatch()
  if (!composerState.isOpen) return null
  // const rcUser = useSelector(state => state.nexusApp.user)

  // if (rcUser?.forumVerified) return <ForumComposerForm {...props}/>

  // return <ForumVerification rcUser={rcUser} toggle={props.toggle}/>
  return <ForumMessageComposerForm rnxForumTopicId={composerState.topic} data={composerState.data} isOpenSet={() => dispatch(rnxForumClientActions.toggleMessageComposer())} refresh={props.refresh}/>
}

const ForumMessageComposerForm = ({
  rnxForumTopicId,
  refresh,
  data,
  isOpenSet
}) => {
  const rcTenantId = useSelector(state => state.runic.config.isMultiTenant ? state.runic.activeRcTenantId : 1)
  const [formProps, isSubmitting] = useUrlForm({
    // url: '/_api/runic/action/RcApp.Login?X-RUNIC-PLATFORM=RUNIC_MAIN',
    url: data ? `/_api/runic/action/RnxForum.UpdateOwnMessage?rcTenantId=${rcTenantId}` : `/_api/runic/action/RnxForum.CreateMessage?rcTenantId=${rcTenantId}`,
    parseData: (dt) => {
      return data ? ({
        ...dt,
        id: data.id,
      }) : ({
        ...dt,
        rnxForumTopicId
      })
    },
    headers: {
      'X-RUNIC-PLATFORM': 'RUNIC_MAIN',
    },
    onSuccess: (data) => {
      refresh?.()
    },
  })

  return (
    <Box sx={{
      height: [300 + 20, null, null, null, 295 + 20],
    }}>
      <Box sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: ['100%', null, null, null, '50%'],
        left: [0, null, null, null, '25%'],
        height: [300, null, null, null, 295],
        background: '#FFF',
        borderTop: '3px solid #FFF',
        borderTopColor: 'primary',
        boxShadow: '0 0px 30px rgba(0, 0, 0, 0.20)',
        pt: 2,
        zIndex: 999999
      }}>
        <Flex sx={{
            justifyContent: 'flex-end',
          }}>
            <ButtonIcon iconName='octagon-remove'  color={'primary'} onClick={() => isOpenSet(false)}/>
          </Flex>
        <Form {...formProps}>
          <ErrorBoundary>
            <ForumEditor data={data?.message} />
          </ErrorBoundary>
          <Flex sx={{

          }}>
            <Button working={isSubmitting} submit label='GÖNDER' sx={{
              px: 3,
              py: 2,
              borderRadius: 0,
              fontWeight: 'bold',
              flex: '1 1 auto',
              mx: 2,
              my: 2
            }} />
          </Flex>
        </Form>
      </Box>
    </Box>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
export default ForumMessageComposer