import React from 'react'

// Volcano
import { Flex, Box } from 'volcano'

import useRunicSource from 'runic/hooks/useRunicSource'

import Card from 'hayatinidegistir/components/Card'

import RfRichTextFieldView from 'runic-form/components/RfRichTextFieldView'
import RfLongFormTextFieldPreview from 'runic-form/components/RfLongFormTextFieldPreview'

import TopAnnouncement from '../components/TopAnnouncement'

import { Link } from 'runic-vera/router'

export const getInitials = (text, length) => {
  const _length = length || 2

  const splitText = text.split(' ')
  return splitText.length == 1 ? (
    text.substring(0, _length).toUpperCase()
  ) : (
      splitText.slice(0, length).map(word => (word[0].toUpperCase())).join('')
    )
}

import dayjs from 'dayjs'

const Feed = ({
  codeName
}) => {
  const source = useRunicSource('RxFeed.Feed.items', { params: { codeName: 'MAIN_FEED' } })
  console.log(source)
  if (!source.ready) return null

  return (
    <>
      <TopAnnouncement/>
      <Flex justifyContent='space-between'>
        <Box sx={{
          fontWeight: 'bold',
          fontSize: 'xl',
          mb: 3,
          variant: 'text.pageHeaderText',
        }} >
          Olan, Biten
      </Box>

      </Flex>
      <Flex sx={{ flexDirection: 'column' }}>

        {source.data.feedItems.map((item, dx) => {
          let header
          let content
          if (item.kind == 'FORUM_MESSAGE') {
            content = ''
            header = <Link to={`konusulanlar/b/${item.data.topicId}`}><Box fontWeight='bold' as='span'>{item.rc_user.name}</Box>, {item.data.topic} başlığına yeni bir mesaj yazdı.</Link>
          } else if (item.kind == 'FORUM_TOPIC') {
            content = item.data.name
            header = <Link to={`konusulanlar/b/${item.data.topicId}`}><Box fontWeight='bold' as='span'>{item.rc_user.name}</Box> yeni bir başlık açtı.</Link>
          } else if (item.kind == 'ANNOUNCEMENT') {
            content = <Announcement item={item}/>
            header = <><Box fontWeight='bold' as='span'>{item.rc_user.name}</Box> yeni bir duyuru oluşturdu.</>
          }
          return (
            <Card as='a' sx={{
              p: 3,
              width: '100%',
              mb: 3
              // mb: 3
            }} key={item.id}>
              <Box>
                <Flex sx={{
                  // ml: 3,
                  // mt: 3,
                }} >
                  <Box sx={{
                    flex: '0 0 auto'
                  }}>
                    <Box sx={{
                      background: '#F8F8F6',
                      border: '1px solid #E3E3E3',
                      borderRadius: '1.1px',
                      p: 2,
                    }}>
                      {getInitials(item.rc_user.name, 2)}
                    </Box>
                  </Box>

                  <Box sx={{ ml: 2 }}>
                    {header}
                    <Box color='dark8' mt={1} fontSize='s'>
                      {dayjs(item.datetime_created).add(3, 'hours').fromNow()}
                    </Box>
                  </Box>

                </Flex>

                {content && <Box mt={3}>
                  {content}
                </Box>}
              </Box>

            </Card>
          )
        })}
      </Flex>
    </>
  )
}

const Announcement = ({
  item
}) => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <>
      <Box sx={{
        variant: 'text.header'
      }}>
        {item.data.name}
      </Box>

      {item.data.image && (
        <Box as='img' src={`${__ASSET_URL__}${item.data.image}`} sx={{
          width: '40%',
          display: 'block'
        }} />
      )}

{item.data.video && (
  <Box as='video' src={`${__ASSET_URL__}${item.data.video}`} controls sx={{
    width: '40%',
          display: 'block'
  }}>
    <source src={`${__ASSET_URL__}${item.data.video}`}
      type="video/mp4"></source>
  </Box>
      )}

      <Box>
        <RfRichTextFieldView content={item.data.description} />
      </Box>

      {item.data.text && <Box as='a' color='#accent' fontWeight='bold' onClick={() => setExpanded(v => !v)}>
        Detaylar
      </Box>}

      {expanded && <Box sx={{
        mt: 2
      }}>
        <RfLongFormTextFieldPreview content={item.data.text} />

      </Box>}
    </>
  )
}

export default Feed