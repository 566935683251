import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'

// Runic
import useRunicSource from 'runic/hooks/useRunicSource'
import useRunicState from 'runic/hooks/useRunicState'
import { useParams, useNavigate } from 'runic-vera/router'
import RfSingleAction from 'runic-form/components/RfSingleAction'
import RfActionButton from 'runic-form/components/RfActionButton'
//
import Card from 'hayatinidegistir/components/Card'
import ForumSlate from '../components/ForumSlate'

import rnxForumClientActions from 'hayatinidegistir/apps/client/forum/actions'

import ForumMessageComposer from '../components/ForumMessageComposer'

export const getInitials = (text, length) => {
  const _length = length || 2

  const splitText = text.split(' ')
  return splitText.length == 1 ? (
    text.substring(0, _length).toUpperCase()
  ) : (
      splitText.slice(0, length).map(word => (word[0].toUpperCase())).join('')
    )
}

import dayjs from 'dayjs'
import * as RV from 'runic-vera/components/RunicView'

const ForumTopicDetail = props => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const result = RV.useRunicGraphQuery('RnxForum.RnxForumTopic.detail_view', { 'variables': { id: params.topicId}})
  const { user } = useRunicState()

  if (!result.ready) return null
  const topic = result.graph.rnx_forum_topic
  return (
    <Box>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 3,
        flexDirection: ['column', null, null, 'row']
      }}>
      <Box sx={{
        variant: 'text.pageHeader'
      }}>
        {topic.name}
      </Box>

      <Button label='Yeni Cevap' onClick={() => dispatch(rnxForumClientActions.toggleMessageComposer({topic: params.topicId}))} />
      </Flex>

      <Card sx={{
        p: 3,
        borderColor: 'accent'
      }}>
        <Flex justifyContent='space-between' sx={{
                alignItems: 'center'
              }}>
                <Flex sx={{
                  alignItems: 'center'
                }}>
                <Box sx={{
                  background: '#F8F8F6',
                  border: '1px solid #E3E3E3',
                  borderRadius: '1.1px',
                  p: 2,
                  mr: 2
                }}>
                  {getInitials(topic.created_by_rnx_forum_user.name, 2)}
                </Box>


                  <Box sx={{
                    fontWeight: 'bold'
                  }}>
                  {topic.created_by_rnx_forum_user.name}
                  </Box>

                </Flex>
                <Flex>
                  <Box>
                      {dayjs(topic.datetime_created).add(3, 'hours').fromNow()}
                  </Box>
                </Flex>
              </Flex>
              <ForumSlate content={topic.data.message} />
      <Flex sx={{
              flexDirection: 'row-reverse',
              mx: -2
            }}>
              {/* <ForumComplaint rcEntityId={message.rc_entity_id} />

              <ForumLikeButton rcEntityId={message.rc_entity_id} isLiked={message.liked} />

              <ForumBookmarkButton rcEntityId={message.rc_entity_id} isBookmarked={message.bookmarked} /> */}

              {user.rcActorId === topic.created_by_rnx_forum_user.rc_actor_id && (
                <ButtonIcon color='primary' iconName='pencil' onClick={() => dispatch(rnxForumClientActions.toggleComposer({category: {
                  id: source.data.rnx_forum_topic.rnx_forum_category_id,
                  name: source.data.rnx_forum_topic.rnx_forum_category.name,
                }, data: {
                  message: source.data.rnx_forum_topic.data.message,
                  topic: source.data.rnx_forum_topic.name,
                  id: source.data.rnx_forum_topic.id,
                }}))}/>
              )}

                <RfActionButton color='primary' iconName={topic.user_bookmarked ? 'heart' : 'heart-empty'} actionKey='RnxForum.BookmarkTopic' values={{
                  id: topic.id
                }} onSuccess={result.refresh}/>

              {topic.mod_actions && (
                <Box sx={{
                  borderRight: '1px solid #FFF',
                  borderRightColor: 'primary',
                  pr: 2,
                  mr: 2
                }}>
                {topic.mod_actions.map(act => {
                  if (act === 'DELETE') return (
                    <RfSingleAction key={act} initialValues={{id: topic.id, 'reason': ''}} buttonText='Sil' onSuccess={() => navigate('../../')} header='Sil' actionKey='RnxForum.ArchiveTopic' iconName='circle-remove' />
                  )
                })}
                </Box>
              )}

            </Flex>
      </Card>

      <Box sx={{
        mt: 3
      }}>
        {topic.rnx_forum_message_list.items.map(message => {
          return (
            <Card sx={{
              p: 3,
              mb: 3
            }} key={message.id}>
              <Flex justifyContent='space-between' sx={{
                alignItems: 'center'
              }}>
                <Flex sx={{
                  alignItems: 'center'
                }}>
                <Box sx={{
                  background: '#F8F8F6',
                  border: '1px solid #E3E3E3',
                  borderRadius: '1.1px',
                  p: 2,
                  mr: 2
                }}>
                  {getInitials(message.created_by_rnx_forum_user.name, 2)}
                </Box>

                <Box sx={{
                  fontWeight: 'bold'
                }}>
                {message.created_by_rnx_forum_user.name}
                </Box>
                </Flex>
                <Flex>
                  <Box>
                  {dayjs(message.datetime_created).add(3, 'hours').fromNow()}
                  </Box>
                </Flex>
              </Flex>
              <ForumSlate content={message.data.message} />
            <Flex sx={{
              flexDirection: 'row-reverse',
              mx: -2
            }}>
              {/* <ForumComplaint rcEntityId={message.rc_entity_id} />

              <ForumLikeButton rcEntityId={message.rc_entity_id} isLiked={message.liked} />

              <ForumBookmarkButton rcEntityId={message.rc_entity_id} isBookmarked={message.bookmarked} /> */}

              {message.created_by_rnx_forum_user.rc_actor_id == user.rcActorId && (
                <>
                <ButtonIcon color='primary' iconName='pencil' onClick={() => dispatch(rnxForumClientActions.toggleMessageComposer({topic: params.topicId, data: {
                  message: message.data.message,
                  id: message.id
                }}))}/>
                <RfSingleAction initialValues={{id: message.id}} buttonText='Sil' onSuccess={result.refresh} header='Sil' actionKey='RnxForum.ArchiveOwnMessage' iconName='circle-remove' />
                </>
              )}

              {message.mod_actions && (
                <Box sx={{
                  borderRight: '1px solid #FFF',
                  borderRightColor: 'primary',
                  pr: 2,
                  mr: 2
                }}>
                {message.mod_actions.map(act => {
                  if (act === 'DELETE') return (
                    <RfSingleAction key={act} initialValues={{id: message.id, 'reason': ''}} buttonText='Sil' onSuccess={result.refresh} header='Sil' actionKey='RnxForum.ArchiveMessage' iconName='circle-remove' />
                  )
                })}
                </Box>
              )}
            </Flex>
            </Card>

          )
        })}
      </Box>

      <ForumMessageComposer refresh={() => {
        result.refresh()
        dispatch(rnxForumClientActions.toggleMessageComposer())
      }}/>
    </Box>
  )
}

// const ForumTopicDetail = props => {
//   const dispatch = useDispatch()
//   const params = useParams()
//   const source = useRunicSource('RnxForum.Forum.topic_detail', { params: { id: params.topicId}})
//   const result = RV.useRunicGraphQuery('RnxForum.RnxForumTopic.detail_view', { 'variables': { id: params.topicId
//   }})
//   console.log('forum', result)
//   const { user } = useRunicState()

//   if (!source.ready) return null
//   return (
//     <Box>
//       <Flex sx={{
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         mb: 3,
//         flexDirection: ['column', null, null, 'row']
//       }}>
//       <Box sx={{
//         variant: 'text.pageHeader'
//       }}>
//       {source.data.rnx_forum_topic.name}
//       </Box>

//       <Button label='Yeni Cevap' onClick={() => dispatch(rnxForumClientActions.toggleMessageComposer({topic: params.topicId}))} />
//       </Flex>

//       <Card sx={{
//         p: 3,
//         borderColor: 'accent'
//       }}>
//         <Flex justifyContent='space-between' sx={{
//                 alignItems: 'center'
//               }}>
//                 <Flex sx={{
//                   alignItems: 'center'
//                 }}>
//                 <Box sx={{
//                   background: '#F8F8F6',
//                   border: '1px solid #E3E3E3',
//                   borderRadius: '1.1px',
//                   p: 2,
//                   mr: 2
//                 }}>
//                   {getInitials(source.data.rnx_forum_topic.created_by_rnx_forum_user.name, 2)}
//                 </Box>

//                   <Card.Title>
//                   {source.data.rnx_forum_topic.created_by_rnx_forum_user.name}
//                 </Card.Title>
//                 </Flex>
//                 <Flex>
//                   <Box>
//                     {dayjs(source.data.rnx_forum_topic.datetime_created).add(3, 'hours').fromNow()}
//                   </Box>
//                 </Flex>
//               </Flex>
//       <ForumSlate content={source.data.rnx_forum_topic.data.message} />
//       <Flex sx={{
//               flexDirection: 'row-reverse',
//               mx: -2
//             }}>
//               {/* <ForumComplaint rcEntityId={message.rc_entity_id} />

//               <ForumLikeButton rcEntityId={message.rc_entity_id} isLiked={message.liked} />

//               <ForumBookmarkButton rcEntityId={message.rc_entity_id} isBookmarked={message.bookmarked} /> */}

//               {user.rcActorId === source.data.rnx_forum_topic.created_by_rnx_forum_user.rc_actor_id && (
//                 <ButtonIcon color='primary' iconName='pencil' onClick={() => dispatch(rnxForumClientActions.toggleComposer({category: {
//                   id: source.data.rnx_forum_topic.rnx_forum_category_id,
//                   name: source.data.rnx_forum_topic.rnx_forum_category.name,
//                 }, data: {
//                   message: source.data.rnx_forum_topic.data.message,
//                   topic: source.data.rnx_forum_topic.name,
//                   id: source.data.rnx_forum_topic.id,
//                 }}))}/>
//                 // <ForumUpdateUserMessageButton rnxForumMessage={source.data.rnx_forum_topic} refresh={source.refresh} />
//               )}

//             </Flex>
//       </Card>

//       <Box sx={{
//         mt: 3
//       }}>
//         {source.data.rnx_forum_message_list.map((message) => {
//           return (
//             <Card sx={{
//               p: 3,
//               mb: 3
//             }} key={message.id}>
//               <Flex justifyContent='space-between' sx={{
//                 alignItems: 'center'
//               }}>
//                 <Flex sx={{
//                   alignItems: 'center'
//                 }}>
//                 <Box sx={{
//                   background: '#F8F8F6',
//                   border: '1px solid #E3E3E3',
//                   borderRadius: '1.1px',
//                   p: 2,
//                   mr: 2
//                 }}>
//                   {getInitials(message.created_by_rnx_forum_user.name, 2)}
//                 </Box>

//                   <Card.Title>
//                   {message.created_by_rnx_forum_user.name}
//                 </Card.Title>
//                 </Flex>
//                 <Flex>
//                   <Box>
//                     {dayjs(message.datetime_created).add(3, 'hours').fromNow()}
//                   </Box>
//                 </Flex>
//               </Flex>
//             <ForumSlate content={message.data.message} />
//             <Flex sx={{
//               flexDirection: 'row-reverse',
//               mx: -2
//             }}>
//               {/* <ForumComplaint rcEntityId={message.rc_entity_id} />

//               <ForumLikeButton rcEntityId={message.rc_entity_id} isLiked={message.liked} />

//               <ForumBookmarkButton rcEntityId={message.rc_entity_id} isBookmarked={message.bookmarked} /> */}

//               {user.rcActorId === message.created_by_rnx_forum_user.rc_actor_id && (
//                 <ButtonIcon color='primary' iconName='pencil' onClick={() => dispatch(rnxForumClientActions.toggleMessageComposer({topic: params.topicId, data: {
//                   message: message.data.message,
//                   id: message.id
//                 }}))}/>
//                 // <ForumUpdateUserMessageButton rnxForumMessage={message} refresh={source.refresh} />
//               )}

//               {user.rcActorId === message.created_by_rnx_forum_user.rc_actor_id && (
//                 <RfSingleAction initialValues={{id: message.id}} buttonText='Sil' onSuccess={source.refresh} header='Sil' actionKey='RnxForum.ArchiveOwnMessage' iconName='circle-remove' />
//                 // <ForumUpdateUserMessageButton rnxForumMessage={message} refresh={source.refresh} />
//               )}

//             </Flex>
//             </Card>
//           )
//         })}
//       </Box>

//       <ForumMessageComposer refresh={() => {
//         source.refresh()
//         dispatch(rnxForumClientActions.toggleMessageComposer())
//       }}/>
//     </Box>
//   )
// }

export default ForumTopicDetail