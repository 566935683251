import React from 'react'

// Vendor
import { Editor, Transforms, createEditor, Range } from 'slate'
import { Editable, withReact, useSlate, Slate, useSelected, useFocused } from 'slate-react'
import { useField } from 'react-final-form'
import { withHistory } from 'slate-history'

// Volcano
import { Box, Flex } from 'volcano'

const RfLongFormTextFieldPreview = ({
  content,
  sx
}) => {
  const renderElement = React.useCallback(props => <Element {...props} />, [])
  const renderLeaf = React.useCallback(props => <Leaf {...props} />, [])
  const editor = React.useMemo(() => withImages(withLinks(withHistory(withReact(createEditor())))), [])

  if (content === undefined || content === null) return null

  return (
    <Box sx={sx}>
    <Slate editor={editor} value={content}>
      <Editable
      readOnly
      renderElement={renderElement}
      renderLeaf={renderLeaf}
       />
    </Slate>
    </Box>
  )
}

const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <Box as='img'
          src={`${__ASSET_URL__}${element.url}`}
          sx={{
            display: 'block',
            maxWidth: '100%',
            width: '100%',
            boxShadow:  selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
          }}
        />
      </div>
      {children}
    </div>
  )
}



const LIST_TYPES = ['numbered-list', 'bulleted-list']


export const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'alignLeft':
      return <p style={{textAlign: 'left'}} {...attributes}>{children}</p>
    case 'alignRight':
      return <p style={{textAlign: 'right'}} {...attributes}>{children}</p>
    case 'alignCenter':
      return <p style={{textAlign: 'center'}} {...attributes}>{children}</p>
    case 'heading-two':
      return <h3 {...attributes}>{children}</h3>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
      case 'image':
        return <ImageElement {...props} />
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.color) {
    children = <span style={{color: leaf.color}}>{children}</span>
  }

  if (leaf.bgColor) {
    children = <span style={{backgroundColor: leaf.bgColor}}>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}


const withImages = editor => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  return editor
}

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

export default RfLongFormTextFieldPreview