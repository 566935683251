import React from 'react'

// Vendor
import dayjs from 'dayjs'

// Volcano
import { Flex, Box } from 'volcano'
import Icon from 'volcano/components/Icon'

// Runic
import useRunicSource from 'runic/hooks/useRunicSource'
import { Link, NavLink, Routes, Route, useParams, useNavigate } from 'runic-vera/router'

import Card from 'hayatinidegistir/components/Card'

// import rnxActorJournalActions from 'elements/rnx_actor_journal/rnx_actor_journal/actions'

const Today = props => {
  return (
    <Box>
      <Box sx={{
        fontWeight: 'bold',
        fontSize: 'xl',
        mb: 3
      }}>
        {dayjs(new Date()).format('D MMMM, YYYY')}
      </Box>
      <Card sx={{
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.17)',
      }}>
        <GalleryImage sx={{
          borderRadius: 1,
          width: '100%'
        }} />
        <Box p={3}>
        <Link to={`gunlugum/`} sx={{
            px: 0,
            color: 'primary',
            fontWeight: 'bold'
          }}>
            <Icon icon='circle-plus' width={24}
              height={24} /> Günlüğüm
          </Link>
        </Box>
      </Card>
    </Box>
  )
}

const GalleryImage = ({
  sx
}) => {
  const source = useRunicSource('RnxJournal.Gallery.image', { params: { codename: 'MOTIVATIONAL' }, maxAge: 60 })
  if (!source.ready) return null
  if (!source.data.image) return null
  return (
    <Box as='img' sx={sx} src={`${__ASSET_URL__}${source.data.image.src}`}/>
  )
}

export default Today