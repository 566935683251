// Riva - Function
import { createActions } from 'runic/core/redux'

const BASE_COMPONENT = 'RnxForum'

const ACTIONS = {
  RCR_ACTION: {
    createTopic: {},
    createMessage: {}
  },
}

export default createActions(ACTIONS, 'RnxForum', BASE_COMPONENT)
