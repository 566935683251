import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import createKey from 'runic/util/key'
import runicActions from 'runic/systems/runic/actions'

const defaultOps = {
  maxAge: 3,
  cache: true
}

const useRunicSource = (name, opts = defaultOps) => {
  const { params, maxAge = 3, cache = true } = opts
  const fetchKey = React.useMemo(() => createKey({s: name, p: params}), [name, params])
  const sourceData = useSelector(state => state.runic.source?.[name]?.[fetchKey])

  const dispatch = useDispatch()

  const refresh = React.useCallback(() => {
    dispatch(runicActions.source(name, params, fetchKey))
  }, [fetchKey])

  let fetchRequired = true
  if (cache && sourceData) {
    if (!maxAge) fetchRequired = false
    else {
      const ageInMs = new Date() - sourceData.dt
      if (ageInMs < maxAge*1000) fetchRequired = false
    }
  }

  if (fetchRequired) refresh()

  const result = React.useMemo(() => ({
    data: sourceData?.data,
    dt: sourceData?.dt,
    error: sourceData?.error,
    status: sourceData?.status,
    ready: sourceData?.status === 'READY',
    empty: !sourceData?.data,
    refresh,
  }), [fetchKey, sourceData?.data])

  return result
}

export default useRunicSource