import React from 'react'

// Vendor
import { Form as RFForm } from 'react-final-form'
export { Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import axios from 'axios'

// Volcano
import Alert from './Alert'


export const Form = ({
  validate,
  renderSuccess,
  initialValues,
  children,
  phase,
  handleSubmit,
  formRef,
  error
}) => {
  return (
    <RFForm
    onSubmit={handleSubmit}
    mutators={{
      ...arrayMutators,
      setFieldData
    }}
    initialValues={initialValues}
    keepDirtyOnReinitialize={true}
    validate={validate}>
      {({handleSubmit, form}) => {
        if (formRef) formRef.current = form
        return (
          <form onSubmit={handleSubmit}>
          {renderSuccess && phase === 'DONE' && renderSuccess()}
          {!(renderSuccess && phase === 'DONE') && children}
        </form>
        )
      }}
    </RFForm>
  )
}

export const useUrlForm = ({
  url,
  headers,
  data,
  onSuccess,
  onError,
  getData,
  parseData,
  ...props
}) => {
  const [phase, setPhase] = React.useState('FORM')
  const [error, setError] = React.useState(null)
  const [result, setResult] = React.useState(null)
  const [isSubmitting, setSubmitting] = React.useState(false)
  const formRef = React.useRef()
  const reset = () => {
    setSubmitting(false)
    setPhase('FORM')
    setResult(null)
    setError(null)
  }

  const handleSubmit = React.useCallback(async (values, form, callback) => {
    setSubmitting(true)
    setError(null)
    let formData = {...values, ...data}
    if (getData) formData = {...formData, ...getData()}
    if (parseData) formData = parseData(formData)
    const response = await axios.post(url, formData, {headers}).catch(e => {
      setSubmitting(false)
      setPhase('ERROR')
      setError(e.response ? e.response.data : e)
      onError?.(e)
    })
    if (response) {
      setSubmitting(false)
      setPhase('SUCCESS')
      setResult(response.data)
      onSuccess?.(response.data, {reset, formData})
    }
  }, [])

  return ([{
    ...props,
    reset,
    phase,
    error,
    result,
    handleSubmit,
    formRef,
    r: {
      isSuccessful: phase === 'SUCCESS',
      isError: phase === 'ERROR'
    }
  }, isSubmitting])
}

export const FormError = ({
  error,
  errorMap
}) => {
  if (!error) return null
  let message = "Bir hata oluştu."
  if (errorMap) {
    const kind = error.kind || error.type
    const mappedKind = errorMap[kind]
    if (mappedKind) message = mappedKind
  }
  return (
    <Alert sx={{
      mb: 2
    }}>
      {message}
    </Alert>
  )
}