import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

import Today from './Today'
import SidebarAnnouncement from './SidebarAnnouncement'


const UserSidebar = props => {
  return (
    <Box>
      <Today/>
      <SidebarAnnouncement/>
    </Box>
  )
}

export default UserSidebar