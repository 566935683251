import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'


const RunicTenantIndex = props => {
  return 'tenant'
}

export default RunicTenantIndex